import React, { useState, useEffect } from "react"
import { Col, Select } from "antd"
import Tooltip from "@material-ui/core/Tooltip"
import { Badge } from "reactstrap"
const { Option } = Select

const DropdownField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [dropdownValue, setDropdownValue] = useState(undefined)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      setDropdownValue(taskCustomField.value)
    } else {
      setDropdownValue(undefined)
    }
  }, [taskCustomField])

  const handleDropdownFieldChange = option => {
    let options = taskFieldObj.options

    setDropdownValue(option.value)
    let customText = `Updated ${taskFieldObj.fieldName}`
    if (taskCustomField.value !== "") {
      let oldValueInd = parseInt(taskCustomField.value)
      let newValueInd = parseInt(option.value)
      customText += ` from "${options[oldValueInd].name}" to "${options[newValueInd].name}"`
    } else {
      let newValueInd = parseInt(option.value)
      customText += ` to "${options[newValueInd].name}"`
    }
    let auditInput = {
      auditType: -1,
      customText,
    }
    let updateObj = {
      id: taskFieldObj.id,
      taskID,
      value: option.value,
      auditInput,
    }
    handleUpdateCustomField(updateObj)
  }

  return (
    <React.Fragment>
      <Col span={6}>
        {taskFieldObj.description ? (
          <Tooltip arrow placement="top" title={taskFieldObj.description}>
            <span>{taskFieldObj.fieldName}</span>
          </Tooltip>
        ) : (
          taskFieldObj.fieldName
        )}
      </Col>
      <Col span={12} className="ms-1">
        <Select
          className="statusHover"
          size="middle"
          bordered={false}
          placeholder={taskFieldObj.fieldName}
          value={dropdownValue ? dropdownValue : null}
          style={{
            minWidth: "150px",
          }}
          onChange={(e, option) => {
            handleDropdownFieldChange(option)
          }}
          dropdownRender={menu => (
            <div className="d-flex flex-column">{menu}</div>
          )}
        >
          {taskFieldObj.options.map((item, index) => {
            return (
              <Option value={String(index)} key={index}>
                <Badge
                  style={{
                    background: item.color,
                    padding: "6px 4px",
                    minWidth: "100px",
                  }}
                  className="font-size-12"
                >
                  {item.name}
                </Badge>
              </Option>
            )
          })}
        </Select>
      </Col>
    </React.Fragment>
  )
}

export default DropdownField
