import React, { useState, useEffect } from "react"
import { Input } from "antd"

const TableTextField = ({ customField, record, value, updateCustomField }) => {
  const [textValue, setTextValue] = useState(undefined)

  useEffect(() => {
    if (value && value !== "") {
      setTextValue(value)
    } else {
      setTextValue(undefined)
    }
  }, [value])

  const handleInlineTextChange = event => {
    setTextValue(event.target.value)
  }

  const handleInlineTextBlur = () => {
    if (textValue && textValue !== value) {
      let customText = `Updated ${customField.fieldName}`
      if (value !== "") {
        customText += ` from "${value}" to "${textValue}"`
      } else {
        customText += ` to "${textValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }
      updateCustomField({
        variables: {
          id: customField.id,
          taskID: record.id,
          value: textValue,
          auditInput,
        },
      })
    }
  }

  return (
    <Input
      style={{
        width: "150px",
      }}
      className="textareadHover"
      value={textValue ? textValue : undefined}
      placeholder={customField.fieldName}
      onMouseLeave={() => {
        handleInlineTextBlur()
      }}
      onChange={handleInlineTextChange}
      onBlur={handleInlineTextBlur}
    />
  )
}

export default TableTextField
