import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Container, Card, CardBody, Row, Col, Spinner } from "reactstrap"
import logo from "../../assets/images/logo.png"
import error from "../../assets/images/error-img.png"

import { GET_EXTERNAL_DASHBOARD } from "../../graphql/queries"
import { useLazyQuery } from "@apollo/client"

// import PieChart from "./components/allCharts/apex/pieChart"
// import DonutChart from "./components/allCharts/apex/donutChart"

import PieChart from "./components/allCharts/highcharts/pieChart"
import DonutChart from "./components/allCharts/highcharts/donutChart"
import ColumnChart from "./components/allCharts/highcharts/columnChart"
import BarChart from "./components/allCharts/highcharts/barChart"
import moment from "moment"
import TextWidget from "./components/textWidget"
import CounterChart from "./components/allCharts/highcharts/counterChart"
import TableChart from "./components/allCharts/highcharts/tableChart"

const ExternalDashboardPage = () => {
  const params = useParams()
  const dashboardID = params.dashboardID

  const [widgets, setWidgets] = useState(null)
  const [dashboard, setDashboard] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showErroPage, setShowErrorPage] = useState(false)

  useEffect(() => {
    if (dashboardID) {
      setLoading(true)
      getExternalDashboard({
        variables: {
          dashboardID: dashboardID,
        },
      })
    }
  }, [params])

  const [getExternalDashboard] = useLazyQuery(GET_EXTERNAL_DASHBOARD, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getExternalDashboard) {
        console.log("data", data)
        setDashboard(data.getExternalDashboard)
        const link = data.getExternalDashboard.link
        const expiryDate = data.getExternalDashboard.expiryDate
        if (link && link !== "") {
          if (expiryDate !== "") {
            let nowInUnix = moment().valueOf()
            let expiryDateInUnix = moment(expiryDate).valueOf()

            if (nowInUnix > expiryDateInUnix) {
              setWidgets(undefined)
              setLoading(false)
              setShowErrorPage(true)
              return
            }
          }
          console.log("PARSE", JSON.parse(data.getExternalDashboard.widgets))
          setWidgets(JSON.parse(data.getExternalDashboard.widgets))
          setLoading(false)
          setShowErrorPage(false)
        } else {
          setLoading(false)
          setShowErrorPage(true)
        }
      }
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
      setShowErrorPage(true)
    },
  })

  const displayChart = (chartName, filters, widgetDatas, text, tableData) => {
    let datas = []
    if (filters) {
      filters.forEach(f => {
        if (f.type === "0") {
          // Contains
          widgetDatas?.forEach(data => {
            if (data.fieldID === f.fieldID && f.values.includes(data.index)) {
              datas.push(data)
            }
          })
        } else {
          // Not contains
          widgetDatas?.forEach(data => {
            if (data.fieldID === f.fieldID && !f.values.includes(data.index)) {
              datas.push(data)
            }
          })
        }
      })
      widgetDatas?.forEach(data => {
        let index = filters.findIndex(f => f.fieldID === data.fieldID)
        if (index === -1) {
          datas.push(data)
        }
      })
    } else {
      datas = widgetDatas
    }
    console.log("datas", datas)
    switch (chartName) {
      case "Pie":
        return <PieChart datas={datas} width={10} isDashboard={true} />
      case "Donut":
        return <DonutChart datas={datas} width={10} isDashboard={true} />
      case "Column":
        return <ColumnChart datas={datas} width={10} isDashboard={true} />
      case "Bar":
        return <BarChart datas={datas} width={10} isDashboard={true} />
      case "Text":
        return <TextWidget text={text} editMode={false} textData={null} />
      case "Counter":
        return <CounterChart datas={datas} width={5} height={5} />
      case "Table":
        return <TableChart tableData={tableData} />
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="position-static">
        <div className="navbar-header py-2 px-5">
          <div className="d-flex align-items-center">
            <img src={logo} alt="" height="30" className="me-2" />
            <div className="font-size-20">
              {dashboard && widgets ? dashboard.name : null}
            </div>
          </div>
        </div>
      </header>

      <Card
        style={{
          position: "absolute",
          width: "100%",
          height: "90%",
          zIndex: "99",
          textAlign: "center",
        }}
        hidden={!loading}
      >
        <CardBody className="d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </CardBody>
      </Card>

      <Container fluid>
        {widgets
          ? widgets.map(widget => {
              return (
                <Card key={widget.id} className="my-4 pb-4 mx-4">
                  <div className="d-flex justify-content-between align-items-center border-bottom py-2 px-1">
                    <h5 className="card-header bg-transparent">
                      {widget.widgetName}
                    </h5>
                  </div>
                  <CardBody
                    className="p-2"
                    style={{
                      height: "100%",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    {displayChart(
                      widget.visualName,
                      widget.filters,
                      widget.datas,
                      widget.text,
                      widget.tableData
                    )}
                  </CardBody>
                </Card>
              )
            })
          : null}

        <div hidden={!showErroPage} className="mt-4">
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />4
                </h1>
                <h4>Sorry, the dashboard you have requested does not exist</h4>
                <p className="text-secondary">
                  Oops! This dashboard has expired or disabled.
                </p>
                <p className="text-secondary">
                  Please contact the owner or person who has shared this
                  dashboard with you.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} className="img-fluid" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default ExternalDashboardPage
