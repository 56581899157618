import React, { useState, useEffect } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/annotations")(Highcharts)

const BarChart = ({ datas, width, onDashboard, handleChangeSelectedData }) => {
  const defaultOptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: {
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
        align: "high",
      },
      labels: {
        overflow: "justify",
        enabled: true,
      },
    },
    xAxis: {
      categories: [""],
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">Task</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
        events: {
          legendItemClick: function (e) {
            if (onDashboard) {
              return true
            }
            e.preventDefault()
            console.log("handleChangeSelectedData", handleChangeSelectedData)
            handleChangeSelectedData(e.target.options)
            // setSelectedData(e.target.options)
            return false
          },
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "horizontal",
      alignColumns: false,
    },
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
              x: 0,
              alignColumns: true,
            },
            subtitle: {
              text: null,
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: false,
                },
              },
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
  }

  const [options, setOptions] = useState(defaultOptions)

  useEffect(() => {
    if (datas) {
      let seriesData = []
      datas.forEach(ele => {
        seriesData.push({
          fieldID: ele.fieldID,
          name: ele.name,
          data: [ele.count],
          color: ele.color,
        })
      })

      let newOptions = {
        ...options,
        chart: {
          ...options.chart,
          width: width * 100,
        },
        series: seriesData,
      }
      setOptions(newOptions)
    }
  }, [datas, width])

  return (
    <div
      style={{
        margin: "0 auto",
        overflow: "hidden",
      }}
      className="h-100 d-flex align-items-center"
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default BarChart
