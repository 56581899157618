import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap"
import { Form, DatePicker } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import toast from "react-hot-toast"
import moment from "moment"
import { GET_EXTERNAL_DASHBOARD } from "../../../graphql/queries"
import {
  CREATE_EXTERNAL_DASHBOARD,
  DELETE_EXTERNAL_DASHBOARD,
  UPDATE_EXTERNAL_DASHBOARD,
} from "../../../graphql/mutation"

import { useLazyQuery, useMutation } from "@apollo/client"

const ExternalLinkModal = ({ showModal, handleClose, currentDashboard }) => {
  const [loading, setLoading] = useState(false)
  const [externalDashboard, setExternalDashboard] = useState(null)
  const [expiryDate, setExpiryDate] = useState(null)
  const [syncLoading, setSyncLoading] = useState(false)

  const [getExternalDashboard] = useLazyQuery(GET_EXTERNAL_DASHBOARD, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getExternalDashboard) {
        setExternalDashboard(data.getExternalDashboard)
      } else {
        setExternalDashboard(null)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    if (showModal) {
      if (currentDashboard.id) {
        getExternalDashboard({
          variables: {
            dashboardID: currentDashboard.id,
          },
        })
      } else {
        setExternalDashboard(null)
      }
    }
  }, [showModal])

  const [createExternalDashboard] = useMutation(CREATE_EXTERNAL_DASHBOARD, {
    onCompleted: data => {
      if (data && data.createExternalDashboard) {
        setExternalDashboard(data.createExternalDashboard)
      }
      setLoading(false)
      toast.success("Successfully created external dashboard link")
    },
    onError: err => {
      console.log("err", err)

      toast.error(err.message)
      setLoading(false)
    },
  })

  const [deleteExternalDashboard] = useMutation(DELETE_EXTERNAL_DASHBOARD, {
    onCompleted: data => {
      if (data && data.deleteExternalDashboard) {
        setExternalDashboard(null)
      }
      setLoading(false)
      toast.success("Successfully deleted external dashboard link")
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
      toast.error(err.message)
    },
  })

  const [updateExternalDashboard] = useMutation(UPDATE_EXTERNAL_DASHBOARD, {
    onCompleted: data => {
      if (data && data.updateExternalDashboard) {
        setExternalDashboard(data.updateExternalDashboard)
      } else {
        setExternalDashboard(null)
      }
      setSyncLoading(false)
      toast.success("Successfully synced external dashboard")
    },
    onError: err => {
      setSyncLoading(false)
      toast.error(err.message)
      console.log("err", err)
    },
  })

  const handleSubmit = () => {
    if (currentDashboard) {
      createExternalDashboard({
        variables: {
          input: {
            dashboardID: currentDashboard.id,
            link: `external/dashboard/${currentDashboard.id}`,
            expiryDate: expiryDate ? expiryDate : "",
          },
        },
      })
    }
  }

  const showToast = () => {
    toast.success("The dashboard link was copied to your clipboard.", {
      position: "bottom-left",
    })
  }

  const handleRemove = () => {
    deleteExternalDashboard({
      variables: {
        dashboardID: currentDashboard.id,
      },
    })
  }

  const handleSync = () => {
    setSyncLoading(true)
    updateExternalDashboard({
      variables: {
        dashboardID: currentDashboard.id,
      },
    })
  }

  const handleExpiryDate = date => {
    setExpiryDate(date)
  }

  return (
    <Modal isOpen={showModal} backdrop="static">
      <Form onFinish={handleSubmit}>
        <ModalHeader tag="h4" toggle={handleClose}>
          External Sharing
        </ModalHeader>
        <ModalBody>
          <label className="text-secondary">Expiry Date</label>
          <DatePicker
            allowClear
            placeholder={
              externalDashboard
                ? "There's no expiry date for this external dashboard"
                : "Expiry date"
            }
            showTime={{ format: "hh:mm A" }}
            disabledDate={current => {
              return current && current <= moment().endOf("yesterday")
            }}
            disabled={externalDashboard !== null}
            value={
              externalDashboard
                ? externalDashboard.expiryDate !== ""
                  ? moment(externalDashboard.expiryDate)
                  : null
                : expiryDate
            }
            className="rounded-3 mb-2 w-100"
            format="MMM Do YYYY hh:mm a"
            onChange={date => {
              handleExpiryDate(date)
            }}
          />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex justify-content-start align-items-center w-75">
              <div>
                {externalDashboard?.link ? (
                  <i className="bx bx-link bg-success bg-soft rounded-3 text-success p-1 font-size-20 me-2"></i>
                ) : (
                  <i className="bx bx-link bg-light rounded-3 text-info p-1 font-size-20 me-2"></i>
                )}
              </div>
              <div>
                <div className="text-dark">
                  External Share Link for Dashboard
                </div>

                <div className="text-secondary font-size-12">
                  {externalDashboard?.link
                    ? "Copy link to access dashboard"
                    : "Generate link to share it with people outside Concize"}
                </div>
              </div>
            </div>

            {externalDashboard && externalDashboard.link !== "" ? (
              <div className="d-flex">
                <CopyToClipboard
                  text={`${window.location.host}/${externalDashboard.link}`}
                >
                  <div
                    onClick={showToast}
                    className="btn btn-sm btn-light rounded-3 me-2"
                  >
                    Copy
                  </div>
                </CopyToClipboard>

                <div
                  onClick={handleRemove}
                  className="btn btn-sm btn-danger rounded-3"
                >
                  <i
                    hidden={!loading}
                    className="bx bx-loader bx-spin font-size-16 align-middle me-2"
                  ></i>
                  Remove
                </div>
                {/* <Switch onChange={handleSwitch} checked={true} size="small" /> */}
              </div>
            ) : (
              <button type="submit" className="btn rounded-3 btn-primary">
                <i
                  hidden={!loading}
                  className="bx bx-loader bx-spin font-size-16 align-middle me-2"
                ></i>
                Generate
              </button>
            )}
          </div>
          <div
            className="w-100"
            style={{
              display: externalDashboard ? "block" : "none",
            }}
          >
            <Alert
              color="info"
              className="d-flex justify-content-between mt-2 mb-0"
              role="alert"
            >
              <div>
                <i className="mdi mdi-information-outline me-2"></i>
                <small>To get updated data in external link</small>
              </div>
              <div
                onClick={handleSync}
                className="btn btn-sm btn-info rounded-3"
              >
                <i
                  hidden={!syncLoading}
                  className="bx bx-loader bx-spin font-size-16 align-middle me-2"
                ></i>
                Sync Now
              </div>
            </Alert>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ExternalLinkModal
