import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Row, Col, Form, Typography, Input } from "antd"

const { Text } = Typography

const WorkspaceModal = ({
  showModal,
  handleClose,
  editMode,
  onSave,
  onUpdate,
  data,
}) => {
  const handleValidSubmit = values => {
    if (editMode) {
      onUpdate(data.id, values.name)
    } else {
      onSave(values)
    }
  }
  return (
    <Modal isOpen={showModal} centered={true} backdrop="static">
      <Form
        onFinish={handleValidSubmit}
        initialValues={{
          ["name"]: data ? data.name : "",
        }}
      >
        <ModalHeader tag="h4" toggle={handleClose}>
          {editMode ? "Update" : "New"} Workspace
        </ModalHeader>
        <ModalBody>
          <Row className="mb-1">
            <Col span={24}>
              <Text className="font-size-12 text-black-50" strong>
                Name <span className="text-danger">*</span>
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input
                  className="rounded-3"
                  size="middle"
                  placeholder="Enter workspace name"
                />
              </Form.Item>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div
            className="btn btn-outline-secondary rounded-3"
            onClick={handleClose}
          >
            Cancel
          </div>
          <button type="submit" className="btn btn-success rounded-3">
            {editMode ? "Update" : "Save"}
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default WorkspaceModal
