import React, { useState, useEffect } from "react"
import { Input } from "antd"
const { TextArea } = Input

const DrawerTitleField = ({ data, handleUpdateTask }) => {
  const [title, setTitle] = useState(undefined)

  useEffect(() => {
    if (data && data.title) {
      setTitle(data.title)
    } else {
      setTitle("")
    }
  }, [data])

  const handleTitleChange = event => {
    setTitle(event.target.value)
  }

  const handleUpdateTitle = () => {
    if (data && data.title !== title) {
      let updatedTask = { ...data }
      updatedTask["title"] = title

      const auditInput = {
        auditType: 2,
        from: data.title,
        to: title,
      }
      handleUpdateTask(updatedTask, auditInput)
    }
  }

  return (
    <TextArea
      autoSize
      value={title ? title : ""}
      placeholder="Untitled Task"
      onChange={handleTitleChange}
      onBlur={handleUpdateTitle}
      onMouseLeave={handleUpdateTitle}
      className="inputTitleHover font-weight-semibold font-size-22 me-3"
    />
  )
}

export default DrawerTitleField
