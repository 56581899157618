import React, { useState, useEffect } from "react"

// Drag and drop
import { DragDropContext, Droppable } from "react-beautiful-dnd"

import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_TASK } from "../../../graphql/queries"
import {
  UPDATE_SUB_TASK,
  DELETE_SUB_TASK,
  CHANGE_SUBTASKS_ORDER,
} from "../../../graphql/mutation"
import { useApolloClient } from "@apollo/client"
import SingleSubTask from "./singleSubTask"

const SubTaskList = ({
  subTasksProp,
  handleChangeDrawerDatas,
  addToStack,
  updateMainTaskList,
  parentTaskID,
}) => {
  const client = useApolloClient()

  const [subTasks, setSubTasks] = useState(undefined)

  useEffect(() => {
    if (subTasksProp) {
      setSubTasks(subTasksProp)
    } else {
      setSubTasks(undefined)
    }
  }, [subTasksProp])

  const [changeSubTasksOrder] = useMutation(CHANGE_SUBTASKS_ORDER, {
    onCompleted: data => {
      if (data && data.changeSubTasksOrder) {
        let updatedTask = data.changeSubTasksOrder
        if (updatedTask.isSubtask) {
          handleChangeDrawerDatas(updatedTask)
        } else {
          updateMainTaskList(updatedTask)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  // SECTION APIS
  const [updateSubTask] = useMutation(UPDATE_SUB_TASK, {
    onCompleted: data => {
      if (data && data.updateSubTask) {
        let updatedTask = data.updateSubTask
        if (updatedTask.isSubtask) {
          handleChangeDrawerDatas(updatedTask)
        } else {
          updateMainTaskList(updatedTask)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [deleteSubTask] = useMutation(DELETE_SUB_TASK, {
    onCompleted: data => {
      if (data && data.deleteSubTask) {
        let updatedTask = data.deleteSubTask
        if (updatedTask.isSubtask) {
          handleChangeDrawerDatas(updatedTask)
        } else {
          updateMainTaskList(updatedTask)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const handleUpdateSubTask = (subtask, id, auditInput) => {
    updateSubTask({
      variables: {
        input: subtask,
        taskID: id,
        auditInput,
      },
    })
  }

  const [getTask] = useLazyQuery(GET_TASK, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getTask) {
        handleChangeDrawerDatas(data.getTask)
        addToStack()
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const handleDeleteSubTask = (subTaskID, parentID) => {
    deleteSubTask({
      variables: {
        subTaskID,
        parentID,
      },
    })
  }

  const handleSubTaskDetail = subTaskID => {
    getTask({
      variables: {
        id: subTaskID,
      },
    })

    /* const {} = client
      .query({
        fetchPolicy: "cache-only",
        query: GET_TASK,
        variables: {
          id: subTaskID,
        },
      })
      .then(res => {
        if (res && res.data) {
          let data = res.data
          if (data && data.getSubTask) {
            let taskData = data.getSubTask
            handleChangeDrawerDatas(taskData)
            addToStack()
          }
        }
      }) */
  }

  // TODO
  const handleSubTasksOrderChange = items => {
    console.log("items", items)
    let subTaskIDs = items.map(item => item.id)
    let taskID = parentTaskID

    if (taskID && taskID !== "") {
      changeSubTasksOrder({
        variables: {
          subTaskIDs,
          taskID,
        },
      })
    }
  }

  return (
    <DragDropContext
      onDragEnd={result => {
        if (!result.destination) return
        const items = Array.from(subTasks)
        const [reordered] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reordered)
        setSubTasks(items)
        handleSubTasksOrderChange(items)
      }}
    >
      <Droppable droppableId="subtasks">
        {mainProvided => (
          <div
            className="subtasks"
            {...mainProvided.droppableProps}
            ref={mainProvided.innerRef}
          >
            {subTasks
              ? subTasks.map((subTaskEle, i) => {
                  return (
                    <SingleSubTask
                      subTaskEle={subTaskEle}
                      index={i}
                      handleUpdateSubTask={handleUpdateSubTask}
                      handleSubTaskDetail={handleSubTaskDetail}
                      handleDeleteSubTask={handleDeleteSubTask}
                    />
                  )
                })
              : null}
            {mainProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SubTaskList
