import React, { useState, useEffect } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import SettingModal from "./settingModal"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/annotations")(Highcharts)

const PieChart = ({
  datas,
  width,
  height,
  onDashboard,
  handleChangeSelectedData,
}) => {
  const defaultOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "horizontal",
      alignColumns: false,
      labelFormat: "<div className='font-size-20'>{name}</div>",
    },
    tooltip: {
      pointFormat: "<b>{point.y}</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y}",
        },
        point: {
          events: {
            legendItemClick: function (e) {
              if (onDashboard) {
                return true
              }
              e.preventDefault()
              handleChangeSelectedData(e.target.options)
              // setSelectedData(e.target.options)
              return false
            },
          },
        },
        showInLegend: true,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
              x: 0,
              alignColumns: true,
              useHTML: true,
              labelFormat: "<span>{name}</span>({y})",
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
            plotOptions: {
              pie: {
                allowPointSelect: false,
                cursor: "pointer",
                dataLabels: {
                  enabled: false,
                },
                showInLegend: true,
              },
            },
          },
        },
      ],
    },
  }

  const [options, setOptions] = useState(defaultOptions)

  useEffect(() => {
    if (datas) {
      let seriesData = []
      datas.forEach(ele => {
        seriesData.push({
          fieldID: ele.fieldID,
          name: ele.name,
          y: ele.count,
          color: ele.color,
        })
      })
      let series = [
        {
          colorByPoint: true,
          data: seriesData,
        },
      ]
      let newOptions = {
        ...options,
        chart: {
          ...options.chart,
          width: width * 90,
          height: height * 100,
        },
        series,
      }
      setOptions(newOptions)
    }
  }, [datas, width, height])

  return (
    <div
      style={{
        margin: "0 auto",
        // overflow: "hidden",
      }}
      className="h-100 d-flex align-items-center"
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
      {/* <SettingModal
        handleChangeDatas={handleChangeDatas}
        defaultColors={defaultColors}
        datas={datas}
        handleChangeSelectedData={handleChangeSelectedData}
        selectedData={selectedData}
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
      /> */}
    </div>
  )
}

export default PieChart
