import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import { Input, Tabs, Divider, Empty, Radio, Checkbox } from "antd"
const { TabPane } = Tabs

import { useLazyQuery } from "@apollo/client"
import { GET_CUSTOM_FIELDS_BY_WORKSPACE_ID } from "../../../graphql/queries"

import uuid from "react-uuid"

const defaultStatusDropdowns = [
  {
    id: "1",
    fieldName: "All",
    description: "The total number of all tasks",
  },
  {
    id: "2",
    fieldName: "Completed",
    description: "The total number of completed tasks",
  },
  {
    id: "3",
    fieldName: "Pending",
    description: "The total number of pending tasks",
  },
  {
    id: "4",
    fieldName: "Delay",
    description: "The total number of tasks which are delay",
  },
]

let defaultStatusField = {
  id: "default_status_id",
  fieldName: "Status",
  fieldType: "Dropdown",
  description: "This is a default status dropdown field.",
}

const NewMetricModal = ({
  showModal,
  handleClose,
  workspaceID,
  handleAddMetric,
  selectedColumns,
  visualName,
}) => {
  const [customFields, setCustomFields] = useState(null)
  const [selectedIDs, setSelectedIDs] = useState([])
  const [searchText, setSearchText] = useState("")
  const [getCustomFields] = useLazyQuery(GET_CUSTOM_FIELDS_BY_WORKSPACE_ID, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getCustomFieldsByWorkspaceID) {
        if (visualName !== "Table") {
          let tempCustomFields = []
          data.getCustomFieldsByWorkspaceID.forEach(field => {
            if (field.fieldType.includes("Dropdown")) {
              tempCustomFields.push(field)
            }
          })
          setCustomFields(tempCustomFields)
        } else {
          setCustomFields([...data.getCustomFieldsByWorkspaceID])
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    if (selectedColumns) {
      let newIDs = []
      selectedColumns.forEach(col => newIDs.push(col.id))
      setSelectedIDs(newIDs)
    }
  }, [selectedColumns])

  useEffect(() => {
    if (workspaceID && showModal) {
      getCustomFields({
        variables: {
          workspaceID,
        },
      })
    }
  }, [showModal])

  useEffect(() => {
    if (visualName && visualName === "Table") {
    }
  }, [visualName])

  const handleClickRadio = field => {
    handleAddMetric(field)
  }

  const handleSearch = event => {
    setSearchText(event.target.value.toLowerCase())
  }

  let dimensions = [
    {
      id: "account",
      fieldName: "Account",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ]

  const diplayMetricsForCorrespondingVisualName = visualName => {
    if (visualName === "Line") {
      return (
        <TabPane
          key="3"
          tab={
            <div className="d-flex justify-content-start align-items-center">
              <span className="px-2 my-1 rounded-1 bg-soft bg-primary text-primary font-size-14 me-2">
                D
              </span>
              Dimensions
            </div>
          }
        >
          <div className="mt-1">
            <Radio.Group className="d-flex flex-column">
              {dimensions
                ? dimensions.map(dimension => {
                    return (
                      <Radio
                        key={uuid()}
                        onClick={() => {
                          handleClickRadio(dimension)
                        }}
                        value={dimension.id}
                        className="radio-hover mb-2 pb-2"
                      >
                        <div className="text-capitalize text-dark font-size-14">
                          {dimension.fieldName}
                        </div>
                        <span
                          className="font-size-13"
                          style={{
                            fontWeight: "400",
                          }}
                        >
                          {dimension.description}
                        </span>
                      </Radio>
                    )
                  })
                : null}
            </Radio.Group>
          </div>
        </TabPane>
      )
    }

    return (
      <TabPane
        key="3"
        tab={
          <div className="d-flex justify-content-start align-items-center">
            <span className="px-2 my-1 rounded-1 bg-soft bg-primary text-primary font-size-14 me-2">
              F
            </span>
            Fields
          </div>
        }
      >
        <div className="mt-1">
          <Radio.Group className="d-flex flex-column">
            {customFields
              ? customFields
                  .filter(customField =>
                    (customField.fieldName + customField.spaceName)
                      .toLowerCase()
                      .includes(searchText)
                  )
                  .map(customField => {
                    return (
                      <Radio
                        key={uuid()}
                        onClick={() => {
                          handleClickRadio(customField)
                        }}
                        value={customField.id}
                        className="radio-hover mb-2 pb-2"
                      >
                        <div className="font-size-14">
                          {customField.fieldName}{" "}
                          <div className="lead font-size-13 d-inline-block">
                            ({customField.spaceName})
                          </div>
                        </div>
                        <span
                          className="font-size-13"
                          style={{
                            fontWeight: "400",
                          }}
                        >
                          {customField.description}
                        </span>
                      </Radio>
                    )
                  })
              : null}
          </Radio.Group>
        </div>
      </TabPane>
    )
  }

  return (
    <Modal
      scrollable={true}
      size="lg"
      backdrop="static"
      isOpen={showModal}
      toggle={handleClose}
    >
      <ModalHeader tag="h5" toggle={handleClose}>
        Add a Metric or Dimension
      </ModalHeader>
      <ModalBody
        style={{
          overflowX: "hidden",
        }}
      >
        {/* NOTE Search filter */}
        <div>
          <Input
            size="middle"
            bordered={false}
            placeholder="Type here to search a Metric or Dimension"
            style={{
              borderBottom: "1px solid lightgrey",
            }}
            onChange={handleSearch}
            prefix={<i className="fal text-secondary fa-search me-2"></i>}
          />
        </div>

        {/* NOTE Selected metrics */}
        <div className="d-flex justify-content-start w-100 mt-3 mb-2 mx-2 ">
          {selectedColumns
            ? selectedColumns.map((col, index) => {
                if (col.fieldName !== "") {
                  return (
                    <div key={index} className="me-2">
                      <div className="bg-light w-100 d-flex justify-content-center align-items-center px-2 rounded-1">
                        <span className="px-2 my-1 rounded-1 bg-soft bg-primary text-primary font-size-13 me-2">
                          {col.fieldName.charAt(0)}
                        </span>
                        {col.fieldName} ({col.spaceName})
                      </div>
                    </div>
                  )
                }
              })
            : null}
        </div>

        <Divider className="my-2" />

        {/* NOTE Tabs */}
        <Tabs
          defaultActiveKey="3"
          tabPosition="left"
          style={{
            height: "80% !important",
          }}
        >
          {/* Favourites Tab */}
          {/*  <TabPane
            key="1"
            tab={
              <div className="d-flex justify-content-start align-items-center">
                <span className="px-2 my-1 rounded-1 bg-soft bg-warning text-warning font-size-14 me-2">
                  F
                </span>
                Favourites
              </div>
            }
          >
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <Empty
                description={"There are no Favourites"}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          </TabPane> */}

          {/* Recents Tab */}
          {/* <TabPane
            key="2"
            tab={
              <div className="d-flex justify-content-start align-items-center mt-0">
                <span className="px-2 my-1 rounded-1 bg-soft bg-success text-success font-size-14 me-2">
                  R
                </span>
                Recents
              </div>
            }
          >
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <Empty
                description={"There are no Recents"}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          </TabPane> */}

          {/* Fields Tab */}
          {diplayMetricsForCorrespondingVisualName(visualName)}
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <div
          onClick={handleClose}
          className="btn btn-outline-secondary rounded-3"
        >
          Cancel
        </div>
        <button className="btn btn-primary rounded-3" type="submit">
          Add
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default NewMetricModal
