import React, { useState, useEffect } from "react"
import { DatePicker } from "antd"
import moment from "moment"

const TableDateField = ({ customField, record, value, updateCustomField }) => {
  const [dateValue, setDateValue] = useState(undefined)

  useEffect(() => {
    if (value && value !== "") {
      setDateValue(value)
    } else {
      setDateValue(undefined)
    }
  }, [value])

  const handleInlineDateChange = dateString => {
    setDateValue(dateString)

    let customText = `Updated ${customField.fieldName}`
    if (value !== "") {
      customText += ` from "${value}" to "${dateString}"`
    } else {
      customText += ` to "${dateString}"`
    }

    let auditInput = {
      auditType: -1,
      customText,
    }

    updateCustomField({
      variables: {
        id: customField.id,
        taskID: record.id,
        value: dateString,
        auditInput,
      },
    })
  }

  return (
    <div className="dueDateHover" style={{ width: "140px" }}>
      <DatePicker
        style={{ cursor: "pointer" }}
        onChange={(_, dateString) => {
          handleInlineDateChange(dateString)
        }}
        value={
          dateValue && dateValue !== "" ? moment(dateValue, "DD-MM-YYYY") : ""
        }
        placeholder={customField.fieldName}
        bordered={false}
        size="middle"
        allowClear={true}
        format="DD-MM-YYYY"
      />
    </div>
  )
}

export default TableDateField
