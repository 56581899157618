import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import TrackTimeModal from "./components/trackTimeModal"
import TrackTimeTable from "./components/trackTimeTable"

import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { DatePicker, Row, Col, Form, notification } from "antd"
import moment from "moment"

import { GET_ALL_TIME_TRACK, WORKSPACES } from "../../graphql/queries.js"
import {
  CREATE_NEW_TIME_TRACK,
  DELETE_TIME_TRACK,
  UPDATE_TIME_TRACK,
  SUBMIT_TIME_TRACK,
} from "../../graphql/mutation"

const TrackTime = () => {
  const [workspaces, setWorkspaces] = useState(undefined)
  // const [showModal, setShowModal] = useState(false)
  const [weekData, setWeekData] = useState(undefined)
  const [weekRange, setWeekRange] = useState(undefined)
  const [timeTrackData, setTimeTrackData] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      style: {
        borderRadius: "6px",
      },
      message: "Error",
      description: msg,
    })
  }

  const {} = useQuery(WORKSPACES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.workspaces !== null) {
        setWorkspaces(data.workspaces)
      }
    },
    onError: err => {
      console.log("err", err)
      openNotificationWithIcon("error", JSON.stringify(err))
      setLoading(false)
    },
  })

  const [submitTimeTrack] = useMutation(SUBMIT_TIME_TRACK, {
    onCompleted: data => {
      console.log("submitTimeTrack", data)
      setLoading(false)
    },
    onError: err => {
      setLoading(false)
      openNotificationWithIcon("error", JSON.stringify(err))
    },
  })

  const [getAllTimeTrack] = useLazyQuery(GET_ALL_TIME_TRACK, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data && data.getAllTimeTrack !== null) {
        setTimeTrackData([...data.getAllTimeTrack])
      } else {
        setTimeTrackData([])
      }
      setLoading(false)
    },
    onError: err => {
      openNotificationWithIcon("error", JSON.stringify(err))
      setLoading(false)
    },
  })

  const [createNewTimeTrack] = useMutation(CREATE_NEW_TIME_TRACK, {
    update: (store, { data }) => {
      if (data && data.createNewTimeTrack !== null) {
        let local
        if (timeTrackData && timeTrackData.length) {
          local = [...timeTrackData, data.createNewTimeTrack]
        } else {
          local = [data.createNewTimeTrack]
        }
        store.writeQuery({
          query: GET_ALL_TIME_TRACK,
          data: {
            getAllTimeTrack: local,
          },
        })
        setTimeTrackData(local)
      }
      setLoading(false)
    },
    onError: err => {
      openNotificationWithIcon("error", JSON.stringify(err))
      setLoading(false)
    },
  })

  const [updateTimeTrack] = useMutation(UPDATE_TIME_TRACK, {
    update: (store, { data }) => {
      if (data && data.updateTimeTrack !== null) {
        let index = timeTrackData.findIndex(timeTrack => {
          return timeTrack.id === data.updateTimeTrack.id
        })

        let newData = [...timeTrackData]
        if (index !== -1) {
          newData[index] = data.updateTimeTrack
        }

        store.writeQuery({
          query: GET_ALL_TIME_TRACK,
          data: {
            getAllTimeTrack: newData,
          },
        })
        setTimeTrackData(newData)
      }
      setLoading(false)
    },
    onError: err => {
      openNotificationWithIcon("error", JSON.stringify(err))
      setLoading(false)
    },
  })

  const [deleteTimeTrack] = useMutation(DELETE_TIME_TRACK, {
    update: (store, { data }) => {
      if (data && data.deleteTimeTrack !== null) {
        let index = timeTrackData.findIndex(timeTrack => {
          return timeTrack.id === data.deleteTimeTrack.id
        })

        let newData = [...timeTrackData]
        newData.splice(index, 1)

        store.writeQuery({
          query: GET_ALL_TIME_TRACK,
          data: {
            getAllTimeTrack: newData,
          },
        })
        setTimeTrackData(newData)
      }
      setLoading(false)
    },
    onError: err => {
      openNotificationWithIcon("error", JSON.stringify(err))
      setLoading(false)
    },
  })

  const weekFormat = "YYYY-MM-DD"
  const customWeekStartEndFormat = value =>
    `${moment(value).startOf("week").format(weekFormat)} - ${moment(value)
      .endOf("week")
      .format(weekFormat)}`

  const handleWeekChange = value => {
    setLoading(true)
    setWeekRange(value)
    let count = 5
    let datesArr = []
    const dates = value.split(" - ")
    var a = moment(dates[0]).format("YYYY-MM-DD")
    var b = moment(dates[1]).format("YYYY-MM-DD")

    for (var m = moment(a); m.diff(b, "days") <= 0; m.add(1, "days")) {
      datesArr.push({
        key: count,
        date: m.format("ddd DD/MM"),
        value: "",
      })
      count++
    }
    setWeekData(datesArr)

    getAllTimeTrack({
      variables: {
        weekRange: value,
      },
    })
  }

  const handleAddNew = value => {
    setLoading(true)
    // Add a new empty row
    let newData = {
      workspace: "",
      type: "",
      billable: true,
      weekRange: weekRange,
      notes: "",
    }

    const weekDates = Array(7).fill("")

    newData["weekDates"] = weekDates

    createNewTimeTrack({
      variables: {
        input: newData,
      },
    })
  }

  const handleFieldChange = (id, input) => {
    if (id && input) {
      updateTimeTrack({
        variables: {
          id,
          input,
        },
      })
    }
  }

  // const handleCloseModal = () => {
  //   setShowModal(false)
  // }

  const handleDelete = id => {
    deleteTimeTrack({
      variables: {
        id,
      },
    })
  }

  const handleSubmit = () => {
    if (
      timeTrackData === undefined ||
      (timeTrackData && timeTrackData.length < 1)
    ) {
      openNotificationWithIcon(
        "error",
        "No time track to submit. Please add a time track"
      )
      return
    }
    setLoading(true)
    let allTimeTrackDatas = []
    timeTrackData.forEach(timeTrack => {
      let newObj = { ...timeTrack }
      delete newObj.id
      delete newObj.__typename
      allTimeTrackDatas.push(newObj)
    })

    submitTimeTrack({
      variables: {
        input: allTimeTrackDatas,
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Track Time | Concize</title>
        </MetaTags>
        <Container fluid>
          <h4>Time Track</h4>
          <Form onFinish={handleAddNew}>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ height: "50px" }}>
                <Form.Item
                  name="weekdate"
                  rules={[{ required: true, message: "Please pick a week" }]}
                >
                  <DatePicker
                    allowClear={false}
                    style={{
                      borderRadius: "6px",
                      width: "100%",
                      height: "33px",
                    }}
                    size="small"
                    format={customWeekStartEndFormat}
                    picker="week"
                    onChange={(_, date) => handleWeekChange(date)}
                  />
                </Form.Item>
              </div>
              <div className="mb-2">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm rounded-3"
                >
                  Add new
                </button>
                <div
                  onClick={handleSubmit}
                  className="btn btn-info btn-sm rounded-3 ms-2 d-none"
                >
                  Submit
                </div>
              </div>
            </div>
          </Form>

          {/* Track Time Table */}

          <TrackTimeTable
            weekData={weekData}
            data={timeTrackData}
            workspaces={workspaces}
            handleDelete={handleDelete}
            loading={loading}
            handleFieldChange={handleFieldChange}
          />

          {/* Track time modal */}
          {/* <TrackTimeModal
            data={timeTrackData}
            weekData={weekData}
            weekRange={weekRange}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            workspaces={workspaces}
          /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TrackTime
