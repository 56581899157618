import React, { useState, useEffect } from "react"
import { Col, DatePicker } from "antd"
import moment from "moment"
import Tooltip from "@material-ui/core/Tooltip"

const DateField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [dateValue, setDateValue] = useState(undefined)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      setDateValue(taskCustomField.value)
    } else {
      setDateValue(undefined)
    }
  }, [taskCustomField])

  const handleDateFieldChange = dateString => {
    setDateValue(dateString)

    let customText = `Updated ${taskFieldObj.fieldName}`
    if (taskCustomField.value !== "") {
      customText += ` from "${taskCustomField.value}" to "${dateString}"`
    } else {
      customText += ` to "${dateString}"`
    }

    let auditInput = {
      auditType: -1,
      customText,
    }

    let updateObj = {
      id: taskFieldObj.id,
      taskID,
      value: dateString,
      auditInput,
    }
    handleUpdateCustomField(updateObj)
  }

  return (
    <React.Fragment>
      <Col span={6}>
        {taskFieldObj.description ? (
          <Tooltip arrow placement="top" title={taskFieldObj.description}>
            <span>{taskFieldObj.fieldName}</span>
          </Tooltip>
        ) : (
          taskFieldObj.fieldName
        )}
      </Col>
      <Col>
        <div className="dueDateHover">
          <DatePicker
            style={{ cursor: "pointer" }}
            placeholder={taskFieldObj.fieldName}
            bordered={false}
            value={
              dateValue && dateValue !== ""
                ? moment(dateValue, "DD-MM-YYYY")
                : ""
            }
            onChange={(_, dateString) => {
              handleDateFieldChange(dateString)
              // handleDateChange(taskFieldObj, dateString, taskCustomField)
            }}
            size="middle"
            allowClear={true}
            format="DD-MM-YYYY"
          />
        </div>
      </Col>
    </React.Fragment>
  )
}

export default DateField
