import React, { useState, useEffect } from "react"
import { Input, Col } from "antd"
import Tooltip from "@material-ui/core/Tooltip"

const NumberField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [numberValue, setNumberValue] = useState(undefined)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      setNumberValue(taskCustomField.value)
    } else {
      setNumberValue(undefined)
    }
  }, [taskCustomField])

  const handleNumberFieldChange = event => {
    setNumberValue(event.target.value)
  }

  const handleUpdateField = () => {
    if (numberValue && numberValue !== taskCustomField.value) {
      let customText = `Updated ${taskFieldObj.fieldName}`
      if (taskCustomField.value !== "") {
        customText += ` from "${taskCustomField.value}" to "${numberValue}"`
      } else {
        customText += ` to "${numberValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      let updateObj = {
        id: taskFieldObj.id,
        taskID,
        value: numberValue,
        auditInput,
      }
      handleUpdateCustomField(updateObj)
    }
  }

  return (
    <React.Fragment>
      <Col span={6}>
        {taskFieldObj.description ? (
          <Tooltip arrow placement="top" title={taskFieldObj.description}>
            <span>{taskFieldObj.fieldName}</span>
          </Tooltip>
        ) : (
          taskFieldObj.fieldName
        )}
      </Col>
      <Col span={12} className="ms-1">
        <Input
          type="number"
          value={numberValue ? numberValue : undefined}
          placeholder={taskFieldObj.fieldName}
          className="textareadHover"
          onChange={handleNumberFieldChange}
          onBlur={handleUpdateField}
          onMouseLeave={handleUpdateField}
        />
      </Col>
    </React.Fragment>
  )
}

export default NumberField
