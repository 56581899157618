import React, { useState, useEffect } from "react"
import { Col } from "antd"
import Tooltip from "@material-ui/core/Tooltip"

const CheckboxField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [checkboxValue, setCheckboxValue] = useState(undefined)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      setCheckboxValue(taskCustomField.value)
    } else {
      setCheckboxValue(undefined)
    }
  }, [taskCustomField])

  const handleCheckboxFieldChange = value => {
    setCheckboxValue(value)

    let customText = `Updated ${taskFieldObj.fieldName}`
    if (taskCustomField.value !== "") {
      customText += ` from "${taskCustomField.value}" to "${value}"`
    } else {
      customText += ` to "${value}"`
    }

    let auditInput = {
      auditType: -1,
      customText,
    }

    let updateObj = {
      id: taskFieldObj.id,
      taskID,
      value,
      auditInput,
    }

    handleUpdateCustomField(updateObj)
  }

  return (
    <React.Fragment>
      <Col span={6}>
        {taskFieldObj.description ? (
          <Tooltip arrow placement="top" title={taskFieldObj.description}>
            <span>{taskFieldObj.fieldName}</span>
          </Tooltip>
        ) : (
          taskFieldObj.fieldName
        )}
      </Col>
      <Col>
        <input
          type="checkbox"
          style={{ cursor: "pointer" }}
          className="form-check-input ms-2"
          id={taskFieldObj.fieldName}
          checked={checkboxValue === "on" ? true : false}
          onChange={() => {
            let value = checkboxValue === "on" ? "off" : "on"
            handleCheckboxFieldChange(value)
          }}
        />
        <label
          htmlFor={taskFieldObj.fieldName}
          className="form-check-label text-secondary font-size-12 ms-1"
        >
          {taskFieldObj.fieldName}
        </label>
      </Col>
    </React.Fragment>
  )
}

export default CheckboxField
