import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import ErrorDialog from "../../components/Common/ErrorDialog"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import AllocationProfileModal from "./components/allocationProfileModal"

import { WORKSPACES, SPACES, ALLOCATION_PROFILES } from "../../graphql/queries"
import {
  CREATE_ALLOCATION_PROFILE,
  UPDATE_ALLOCATION_PROFILE,
  DELETE_ALLOCATION_PROFILE,
} from "../../graphql/mutation"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import ConfirmDeleteModal from "../../components/Dialog/confirmDeleteModal"
import NotAuthorizedPage from "components/Common/NotAuthorizedPage"
import { ROLE } from "components/helpers/enums"

const AllocationProfile = () => {
  const obj = JSON.parse(localStorage.getItem("concizeUser"))
  let role = obj.role

  const [allocationProfiles, setAllocationProfiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentAllocationProfile, setCurrentAllocationProfile] =
    useState(undefined)
  const [editMode, setEditMode] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [showError, setShowError] = useState(false)

  const [workspaces, setWorkspaces] = useState(undefined)
  const [spaces, setSpaces] = useState(undefined)

  const {} = useQuery(WORKSPACES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data) {
        setWorkspaces(data.workspaces)
      }
    },
  })

  const [getSpaceByWorkspaceID] = useLazyQuery(SPACES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data) {
        setSpaces(data.spaces)
      }
    },
  })

  const {} = useQuery(ALLOCATION_PROFILES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.allocationProfiles) {
        setAllocationProfiles(data.allocationProfiles)
      }
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [createAllocationProfile] = useMutation(CREATE_ALLOCATION_PROFILE, {
    onCompleted: data => {
      if (data && data.createAllocationProfile) {
        if (allocationProfiles && allocationProfiles.length) {
          setAllocationProfiles([
            ...allocationProfiles,
            data.createAllocationProfile,
          ])
        } else {
          setAllocationProfiles([data.createAllocationProfile])
        }
        setLoading(false)
        setShowModal(false)
      }
    },
    onError(err) {
      setErrorMessage(err.message)
      setShowError(true)
    },
  })

  const [updateAllocationProfile] = useMutation(UPDATE_ALLOCATION_PROFILE, {
    onCompleted: data => {
      if (data && data.updateAllocationProfile) {
        let index = allocationProfiles.findIndex(allocationProfile => {
          return allocationProfile.id === currentAllocationProfile.id
        })
        setAllocationProfiles([
          ...allocationProfiles.slice(0, index),
          data.updateAllocationProfile,
          ...allocationProfiles.slice(index + 1),
        ])
      }
      setLoading(false)
      setShowModal(false)
    },
    onError(err) {
      setErrorMessage(err.message)
      setShowError(true)
    },
  })

  const [deleteAllocationProfile] = useMutation(DELETE_ALLOCATION_PROFILE, {
    onCompleted: data => {
      if (data && data.deleteAllocationProfile) {
        let index = allocationProfiles.findIndex(allocationProfile => {
          return allocationProfile.id === currentAllocationProfile.id
        })
        let newAllocationProfiles = [...allocationProfiles]
        newAllocationProfiles.splice(index, 1)
        setAllocationProfiles(newAllocationProfiles)
        setLoading(false)
      }
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const allocationProfileColumnList = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
      formatter: (cellContent, allocationProfile) => {
        return <span>{allocationProfile.name}</span>
      },
    },
    {
      text: "Workspace",
      dataField: "workspace",
      sort: true,
      formatter: (cellContent, allocationProfile) => (
        <span>{allocationProfile.workspace.name}</span>
      ),
    },
    {
      text: "Spaces",
      dataField: "spaces",
      sort: true,
      formatter: (cellContent, allocationProfile) => {
        let spaceNames = []
        allocationProfile.spaces.forEach(space => {
          spaceNames.push(
            <span
              key={space.id}
              className="badge badge-soft-primary font-size-12 me-1"
            >
              {space.name}
            </span>
          )
        })
        return <span>{spaceNames}</span>
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, allocationProfile) => (
        <div className="d-flex gap-3">
          <div
            className="text-success"
            onClick={() => handleUpdateAllocationProfile(allocationProfile)}
            style={{ cursor: "pointer" }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </div>
          <div
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteAllocationProfile(allocationProfile)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </div>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  const pageOptions = {
    sizePerPage: 10,
    totalSize: allocationProfiles.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
    style: { backgroundColor: "#fafafa" },
  }

  const handleUpdateAllocationProfile = profile => {
    setCurrentAllocationProfile(profile)
    setEditMode(true)
    setShowModal(true)
  }

  const handleDeleteAllocationProfile = profile => {
    setCurrentAllocationProfile(profile)
    setShowConfirmDeleteModal(true)
  }

  const handleCreateAllocationProfile = () => {
    setSpaces(undefined)
    setCurrentAllocationProfile(undefined)
    setEditMode(false)
    setShowModal(true)
  }

  const handleModalClose = () => {
    setSpaces(undefined)
    setCurrentAllocationProfile(undefined)
    setShowModal(false)
  }

  const handleDeletModalClose = () => {
    setShowConfirmDeleteModal(false)
  }

  const handleErrorDialogClose = () => {
    setShowError(false)
  }

  function onSave(allocationProfileInput) {
    createAllocationProfile({
      variables: {
        input: allocationProfileInput,
      },
    })
  }

  function onUpdate(id, allocationProfileInput) {
    updateAllocationProfile({
      variables: {
        id,
        input: allocationProfileInput,
      },
    })
  }

  function onDelete() {
    deleteAllocationProfile({
      variables: {
        id: currentAllocationProfile.id,
      },
    })
    setShowConfirmDeleteModal(false)
  }

  function onWorkspaceChange(id) {
    // TODO
    if (editMode) {
      setSpaces(undefined)
    }
    getSpaceByWorkspaceID({
      variables: {
        workspaceID: id,
      },
    })
  }

  return role === ROLE.spaceAdmin || role === ROLE.spaceUser ? (
    <NotAuthorizedPage />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Allocation Profile | Concize</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            left="Allocation Profile"
            url="/settings"
            urlTitle="Settings"
            right="Allocation Profile"
          />

          {/* Modals */}
          <AllocationProfileModal
            showModal={showModal}
            handleClose={handleModalClose}
            editMode={editMode}
            workspaces={workspaces}
            spaces={spaces}
            onSave={onSave}
            onUpdate={onUpdate}
            data={currentAllocationProfile}
            onWorkspaceChange={onWorkspaceChange}
          />

          <ConfirmDeleteModal
            showDialog={showConfirmDeleteModal}
            handleClose={handleDeletModalClose}
            onConfirm={onDelete}
            title={`Delete Allocation Profile`}
            subText={`Are you sure you want to delete the allocation profile "${currentAllocationProfile?.name}"?`}
          />

          <ErrorDialog
            showError={showError}
            handleErrorDialogClose={handleErrorDialogClose}
            type="warning"
            title="409 Conflict"
            errorMessage={errorMessage}
          />

          <Row>
            <Col lg="12">
              <Card style={{ position: "relative" }}>
                <CardBody>
                  <Card
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      left: `calc(50% - 100px)`,
                      top: "30%",
                      width: "150px",
                    }}
                    className="shadow-lg text-center"
                  >
                    <CardBody hidden={!loading}>
                      <p>Please wait</p>
                      <Spinner color="primary" hidden={!loading} />
                    </CardBody>
                  </Card>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={allocationProfiles}
                    columns={allocationProfileColumnList}
                    keyField="name"
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="name"
                        data={allocationProfiles}
                        columns={allocationProfileColumnList}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end d-flex justify-content-end">
                                  <button
                                    className="font-16 btn-block btn btn-primary rounded-3 me-2"
                                    onClick={handleCreateAllocationProfile}
                                  >
                                    <i className="fas fa-plus-circle me-2" />
                                    Create Allocation Profile
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    keyField="name"
                                    // selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllocationProfile
