import React, { useState, useEffect } from "react"
import { Input, Row, Col, Dropdown, Menu } from "antd"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"

import { Draggable } from "react-beautiful-dnd"

const SingleSubTask = ({
  subTaskEle,
  index,
  handleUpdateSubTask,
  handleSubTaskDetail,
  handleDeleteSubTask,
}) => {
  const [subTask, setSubTask] = useState(undefined)
  const [title, setTitle] = useState(undefined)

  useEffect(() => {
    if (subTaskEle) {
      setSubTask(subTaskEle)
      setTitle(subTaskEle.title)
    } else {
      setSubTask(undefined)
      setTitle(undefined)
    }
  }, [subTaskEle])

  const handleSubTaskTitleChange = event => {
    setTitle(event.target.value)
  }

  const handleUpdate = () => {
    let taskID = subTask.id
    let updateSubTask = { ...subTask }
    delete updateSubTask.__typename
    delete updateSubTask.id
    delete updateSubTask.comments
    updateSubTask.title = title
    let auditInput = {
      auditType: 2,
      from: subTaskEle.title,
      to: title,
    }
    if (subTaskEle.title !== title) {
      handleUpdateSubTask(updateSubTask, taskID, auditInput)
    }
  }

  const handleCompleteSubTask = status => {
    let taskID = subTask.id
    let updateSubTask = { ...subTask }
    delete updateSubTask.__typename
    delete updateSubTask.id
    delete updateSubTask.comments
    updateSubTask.status = status
    let auditInput = {
      auditType: 5,
      from: subTaskEle.status,
      to: status,
    }

    handleUpdateSubTask(updateSubTask, taskID, auditInput)
  }

  // subtask right click menu function
  const menuFunc = (subTaskID, parentID, status) => {
    return (
      <Menu
        onClick={e => {
          handleOptionsClick(e.key, subTaskID, parentID, status)
        }}
        style={{ borderRadius: "4px", fontFamily: "sans-serif" }}
      >
        <Menu.Item key={1} className="font-size-14 d-flex">
          <i className="fal fa-check-circle me-2"></i>
          {status === "Completed" ? "Mark incomplete" : "Mark complete"}
        </Menu.Item>
        <Menu.Item key={2} className="font-size-14">
          <i className="fal fa-eye me-2"></i>View Details
        </Menu.Item>
        {/* <Menu.Item key={3} className="font-size-13">
          <i className="fal fa-external-link me-2"></i>Open in new tab
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key={4} className="font-size-13">
          <i className="fal fa-link me-2"></i>Copy task link
        </Menu.Item> */}
        <Menu.Divider />
        <Menu.Item key={5} className="font-size-14 text-danger">
          <i className="fad fa-trash-alt me-2"></i>Delete task
        </Menu.Item>
      </Menu>
    )
  }

  const handleOptionsClick = (key, subTaskID, parentID, status) => {
    if (key === "5") {
      // Delete Subtask
      handleDeleteSubTask(subTaskID, parentID)
    } else if (key === "1") {
      if (status && status === "Completed") {
        handleCompleteSubTask("")
      } else {
        handleCompleteSubTask("Completed")
      }
    } else if (key === "2") {
      handleSubTaskDetail(subTaskID)
    }
  }

  return (
    <React.Fragment>
      {subTask ? (
        <Draggable key={subTask.id} draggableId={subTask.id} index={index}>
          {provided => (
            <div
              {...provided.draggableProps}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
            >
              <Dropdown
                overlay={() =>
                  menuFunc(subTask.id, subTask.parentID, subTask.status)
                }
                trigger={["contextMenu"]}
                placement="topLeft"
              >
                <div
                  className={
                    subTask.status === "Completed"
                      ? "site-dropdown-context-menu mx-4 bg-light"
                      : "site-dropdown-context-menu mx-4"
                  }
                >
                  <div className="subtask-hover">
                    <Row className="customMargin">
                      <Col span={24} className="mb-1">
                        <Input
                          className="me-2 py-1"
                          prefix={
                            <div>
                              <i
                                style={{
                                  color: "rgb(21, 7, 38, 0.2)",
                                }}
                                className="fas fa-grip-vertical mx-2 font-size-14"
                              ></i>
                              <Tooltip
                                arrow
                                enterDelay={500}
                                placement="bottom"
                                title="Mark task complete"
                                aria-label="tick"
                              >
                                <IconButton
                                  onClick={() => {
                                    let status
                                    if (subTask.status === "Completed") {
                                      status = ""
                                    } else {
                                      status = "Completed"
                                    }
                                    handleCompleteSubTask(status)
                                  }}
                                  aria-label="complete"
                                  size="small"
                                >
                                  <i
                                    className={
                                      subTask.status === "Completed"
                                        ? "fal fa-check-circle font-size-16 complete-icon"
                                        : "fal fa-check-circle font-size-16 complete-icon-hover"
                                    }
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            </div>
                          }
                          suffix={
                            <Row>
                              {/* <Col className="me-1">
                                <Tooltip
                                  arrow
                                  placement="bottom-end"
                                  title="Add a due date to this sub task"
                                  aria-label="caledar"
                                >
                                  <IconButton
                                    className="icon-hover"
                                    aria-label="calendar"
                                    size="small"
                                  >
                                    <i className="fal fa-calendar font-size-14"></i>
                                  </IconButton>
                                </Tooltip>
                              </Col>
                              <Col className="me-1">
                                <Tooltip
                                  placement="bottom-end"
                                  arrow
                                  title="Assign a person this task"
                                  aria-label="person"
                                >
                                  <IconButton
                                    className="icon-hover"
                                    aria-label="person"
                                    size="small"
                                  >
                                    <i className="fal fa-user font-size-14"></i>
                                  </IconButton>
                                </Tooltip>
                              </Col> */}
                              <Col className="me-1">
                                <Tooltip
                                  placement="bottom-end"
                                  arrow
                                  title="Subtask notes and comments"
                                  aria-label="link"
                                >
                                  <div
                                    onClick={() => {
                                      handleSubTaskDetail(subTask.id)
                                    }}
                                  >
                                    <IconButton
                                      className="icon-hover"
                                      aria-label="link"
                                      size="small"
                                    >
                                      <i className="fal fa-comment font-size-14"></i>
                                    </IconButton>
                                  </div>
                                </Tooltip>
                              </Col>
                            </Row>
                          }
                          key={index}
                          size="middle"
                          bordered={false}
                          value={title ? title : undefined}
                          name="title"
                          placeholder="Enter subtask name"
                          onChange={handleSubTaskTitleChange}
                          onBlur={handleUpdate}
                          onMouseLeave={handleUpdate}
                        />
                      </Col>
                    </Row>
                    <div className="custom-border"></div>
                  </div>
                </div>
              </Dropdown>
            </div>
          )}
        </Draggable>
      ) : null}
    </React.Fragment>
  )
}

export default SingleSubTask
