import React, { useState, useEffect } from "react"
import { Input } from "antd"

const DefaultTitleField = ({ data, handleInlineTitleChange }) => {
  const [title, setTitle] = useState(undefined)

  useEffect(() => {
    if (data) {
      setTitle(data.title)
    }
  }, [data])

  const handleTitleChange = event => {
    setTitle(event.target.value)
  }

  const handleTitleBlur = () => {
    if (data.title !== title) {
      handleInlineTitleChange(data, title)
    }
  }
  const handleMouseLeave = () => {
    if (data.title !== title) {
      handleInlineTitleChange(data, title)
    }
  }

  return (
    <div>
      <Input
        placeholder="Untitled Task"
        onMouseLeave={handleMouseLeave}
        value={title ? title : ""}
        onChange={handleTitleChange}
        onBlur={handleTitleBlur}
        className="textareadHover mb-2"
        size="small"
      />
      {/* <Input
        bordered={false}
        style={{
          fontSize: "12px",
        }}
        readOnly
        placeholder="Untitled Description"
        value={data.description}
      /> */}
      <p className="text-muted font-size-12 mb-0 ps-2 text-truncate">
        {data.description === null || data.description === ""
          ? "Untitled Description"
          : data.description}
      </p>
    </div>
  )
}

export default DefaultTitleField
