import React, { useState, useEffect } from "react"
import { Input } from "antd"
const { TextArea } = Input
import { useDebounce } from "use-debounce/lib"
import Tooltip from "@material-ui/core/Tooltip"

const DrawerTextField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [textValue, setTextValue] = useState(undefined)
  const [updatedTextValue] = useDebounce(textValue, 1000)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      setTextValue(taskCustomField.value)
    } else {
      setTextValue(undefined)
    }
  }, [taskCustomField])

  useEffect(() => {
    if (updatedTextValue && updatedTextValue != taskCustomField.value) {
      let customText = `Updated ${taskFieldObj.fieldName}`
      if (taskCustomField.value !== "") {
        customText += ` from "${taskCustomField.value}" to "${updatedTextValue}"`
      } else {
        customText += ` to "${updatedTextValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      let updateObj = {
        id: taskFieldObj.id,
        taskID,
        value: updatedTextValue,
        auditInput,
      }

      handleUpdateCustomField(updateObj)
    }
  }, [updatedTextValue])

  const handleTextFieldChange = event => {
    setTextValue(event.target.value)
  }

  const handleTextFieldBlur = () => {
    if (textValue && textValue !== taskCustomField.value) {
      let customText = `Updated ${taskFieldObj.fieldName}`
      if (taskCustomField.value !== "") {
        customText += ` from "${taskCustomField.value}" to "${textValue}"`
      } else {
        customText += ` to "${textValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      let updateObj = {
        id: taskFieldObj.id,
        taskID,
        value: textValue,
        auditInput,
      }

      // handleUpdateCustomField(updateObj)
    }
  }

  return (
    <TextArea
      style={{
        width: "100%",
      }}
      autoSize
      value={textValue ? textValue : undefined}
      placeholder="Untitled Task"
      onChange={handleTextFieldChange}
      onBlur={handleTextFieldBlur}
      onMouseLeave={handleTextFieldChange}
      className="inputTitleHover font-weight-semibold font-size-22 me-3 mb-2"
    />
  )
}

export default DrawerTextField
