import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { useParams } from "react-router-dom"
import { Container } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"

// import { DateRangePicker } from "react-date-range"
// import { addDays } from "date-fns"
// import "react-date-range/dist/styles.css"
// import "react-date-range/dist/theme/default.css"

import {
  Card,
  Spinner,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap"
import { DatePicker, Menu, Dropdown as AntDropdown, Spin } from "antd"
import moment from "moment"

import NewWidgetModal from "./components/newWidgetModal"
import ExternalLinkModal from "./components/externalLinkModal"

import PieChart from "./components/allCharts/highcharts/pieChart"
import DonutChart from "./components/allCharts/highcharts/donutChart"
import ColumnChart from "./components/allCharts/highcharts/columnChart"
import BarChart from "./components/allCharts/highcharts/barChart"
import CounterChart from "./components/allCharts/highcharts/counterChart"

import {
  GET_DASHBOARD,
  GET_LAYOUT,
  FILTER_WIDGET_DATAS_BY_DATE,
} from "../../graphql/queries"
import {
  CREATE_WIDGET,
  UPDATE_WIDGET,
  UPDATE_LAYOUT,
  DELETE_WIDGET,
  UPDATE_DASHBOARD,
  UPDATE_WIDGET_UI,
} from "../../graphql/mutation"
import reactGridLayout from "react-grid-layout"
import GridLayout from "react-grid-layout"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import "./grid.css"
import TextWidget from "./components/textWidget"
import TextChart from "./components/allCharts/highcharts/textChart"
import LineChart from "./components/allCharts/highcharts/lineChart"
import TableChart from "./components/allCharts/highcharts/tableChart"

const { Responsive, WidthProvider } = reactGridLayout
const ResponsiveReactGridLayout = WidthProvider(Responsive)
const { RangePicker } = DatePicker

const DashboardPage = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [showWidgetModal, setShowWidgetModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [currentDashboard, setCurrentDashboard] = useState(undefined)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)

  const [layouts, setLayouts] = useState([])
  const [widgets, setWidgets] = useState(undefined)
  const [currentWidget, setCurrentWidget] = useState(undefined)

  const [showExternalLinkModal, setShowExternalLinkModal] = useState(false)

  const defaultLayout = {
    w: 4,
    h: 3,
    minW: 3,
    minH: 1,
    maxH: 4,
  }

  const editWidgetMenu = widget => (
    <Menu
      className="rounded-3"
      onClick={e => {
        handleEditWidgetMenuClick(e.key, widget)
      }}
    >
      <Menu.ItemGroup title={<span className="mx-2">Edit Widget</span>}>
        <Menu.Item key="0">
          <div>
            <i className="fal fa-cog me-2"></i>
            Edit Widget
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item className="mx-2" key="3">
        <div className="text-danger">
          <i className="fal fa-trash-alt me-2"></i>
          Delete Widget
        </div>
      </Menu.Item>
    </Menu>
  )

  const chartChangeMenu = widget => (
    <Menu
      className="rounded-3"
      onClick={e => {
        handleChartChangeMenuClick(e.key, widget)
      }}
    >
      <Menu.ItemGroup title={<span className="mx-2">Change chart</span>}>
        <Menu.Item key="Pie">
          <div>
            <i className="mdi mdi-chart-pie font-size-18 align-middle me-2"></i>
            <span>Pie Chart</span>
          </div>
        </Menu.Item>
        <Menu.Item key="Donut">
          <div>
            <i className="mdi mdi-chart-donut font-size-16 align-middle me-2"></i>
            <span>Donut Chart</span>
          </div>
        </Menu.Item>
        <Menu.Item key="Column">
          <div>
            <i className="mdi mdi-chart-bar font-size-16 align-middle me-2"></i>
            <span>Column Chart</span>
          </div>
        </Menu.Item>
        <Menu.Item key="Bar">
          <div>
            <i className="mdi mdi-chart-bar mdi-rotate-90 font-size-18 align-middle me-2"></i>

            <span>Bar Chart</span>
          </div>
        </Menu.Item>
        <Menu.Item key="Counter">
          <div>
            <i className="mdi mdi-chart-pie font-size-18 align-middle me-2"></i>
            <span>Counter Chart</span>
          </div>
        </Menu.Item>
        {/* <Menu.Item key="Table">
          <div>
            <i className="mdi mdi-table font-size-18 align-middle me-2"></i>
            <span>Table Chart</span>
          </div>
        </Menu.Item> */}
      </Menu.ItemGroup>
    </Menu>
  )

  const handleEditWidgetMenuClick = (key, widget) => {
    switch (key) {
      case "0":
        handleEditWidget(widget)
        break
      case "3":
        handleDeleteWidget(widget.id)
      default:
        break
    }
  }

  const handleChartChangeMenuClick = (key, widget) => {
    handleChartChange(key, widget)
  }

  const selectStyles = {
    control: styles => ({
      ...styles,
      borderRadius: "6px",
    }),
  }

  const [filterWidgets] = useLazyQuery(FILTER_WIDGET_DATAS_BY_DATE, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.filterWidgetsDataByDate) {
        setWidgets([...data.filterWidgetsDataByDate])
        getLayout()
      }
      setLoading(false)
    },
    onError: err => {
      setLoading(false)
      console.log("err", err)
    },
  })

  // API
  const [getDashboard] = useLazyQuery(GET_DASHBOARD, {
    onCompleted: data => {
      if (data && data.getDashboard) {
        setCurrentDashboard(data.getDashboard)
        const input = {
          dashboardID: params.dashboardID,
          workspaceID: params.workspaceID,
          startDate: data.getDashboard.startDate,
          endDate: data.getDashboard.endDate,
        }
        setStartDate(data.getDashboard.startDate)
        setEndDate(data.getDashboard.endDate)
        filterWidgets({
          variables: {
            input,
          },
        })
      }
      // setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  useEffect(() => {
    if (params && params.dashboardID) {
      getDashboard({
        variables: {
          id: params.dashboardID,
        },
      })
    }
  }, [params])

  const [updateLayout] = useMutation(UPDATE_LAYOUT, {
    onCompleted: data => {
      if (data !== undefined && data.updateLayout !== null) {
        let layouts = JSON.parse(data.updateLayout.layout)
        setLayouts([...layouts])
        // setWidgets([...widgets])
      }
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [getLayout] = useLazyQuery(GET_LAYOUT, {
    fetchPolicy: "network-only",
    variables: {
      id: params.dashboardID,
    },
    onCompleted: data => {
      if (data.getLayout && data.getLayout.layout) {
        let newLayouts = JSON.parse(data.getLayout.layout)
        setLayouts([...newLayouts])
      }
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [createWidget] = useMutation(CREATE_WIDGET, {
    onCompleted: data => {
      if (data && data.createWidget !== null) {
        let newLayouts = []
        if (widgets !== undefined && widgets.length) {
          let newWidgets = [...widgets, data.createWidget]

          let x = 0
          if (layouts && layouts.length) {
            x = layouts[layouts.length - 1].x + layouts[layouts.length - 1].w
          }
          newLayouts = [
            ...layouts,
            {
              // x: (layouts.length * 3) % 12,
              x: x,
              y: 0,
              i: data.createWidget.id,
              ...defaultLayout,
            },
          ]
          // setLayouts(newLayouts)
          setWidgets(newWidgets)
        } else {
          newLayouts = [
            {
              x: 0,
              y: 0,
              i: data.createWidget.id,
              ...defaultLayout,
            },
          ]
          // setLayouts([...newLayouts])
          setWidgets([data.createWidget])
        }
        updateLayout({
          variables: {
            id: params.dashboardID,
            layout: JSON.stringify(newLayouts),
          },
        })
      }
      setLoading(false)
    },
    onerror: err => {
      console.log("Error", err)
      setLoading(false)
    },
  })

  const [updateWidget] = useMutation(UPDATE_WIDGET, {
    onCompleted: data => {
      if (data && data.updateWidget !== null) {
        let index = widgets.findIndex(widget => {
          return widget.id === data.updateWidget.id
        })
        setWidgets([
          ...widgets.slice(0, index),
          { ...data.updateWidget },
          ...widgets.slice(index + 1),
        ])
      }
      setLoading(false)
    },
    onerror: err => {
      console.log("Error", err)
      setLoading(false)
    },
  })
  const [updateWidgetUI] = useMutation(UPDATE_WIDGET_UI, {
    onCompleted: data => {
      if (data && data.updateWidgetUI !== null) {
        let index = widgets.findIndex(widget => {
          return widget.id === data.updateWidgetUI.id
        })
        setWidgets([
          ...widgets.slice(0, index),
          { ...data.updateWidgetUI },
          ...widgets.slice(index + 1),
        ])
      }
      setShowWidgetModal(false)
      setLoading(false)
    },
    onerror: err => {
      console.log("Error", err)
      setLoading(false)
    },
  })

  const [deleteWidget] = useMutation(DELETE_WIDGET, {
    update: (store, { data }) => {
      if (data && data.deleteWidget !== null) {
        const deletedWidget = data.deleteWidget
        let index = widgets.findIndex(widget => {
          return widget.id === deletedWidget.id
        })
        let newWidgets = [...widgets]
        newWidgets.splice(index, 1)

        setWidgets(newWidgets)

        // Remove the layout for the deleted widget
        let newLayouts = [...layouts]
        newLayouts.splice(index, 1)

        setLayouts(newLayouts)
        updateLayout({
          variables: {
            id: params.dashboardID,
            layout: JSON.stringify(newLayouts),
          },
        })

        setLoading(false)
      }
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [updateDashboard] = useMutation(UPDATE_DASHBOARD, {
    onCompleted: data => {
      if (data && data.updateDashboard) {
        const input = {
          dashboardID: params.dashboardID,
          workspaceID: params.workspaceID,
          startDate: data.updateDashboard.startDate,
          endDate: data.updateDashboard.endDate,
        }

        filterWidgets({
          variables: {
            input,
          },
        })
      }
    },
  })

  const handleEditWidget = widget => {
    setCurrentWidget(widget)
    setEditMode(true)
    setShowWidgetModal(true)
  }

  const handleDeleteWidget = id => {
    deleteWidget({
      variables: {
        id,
      },
    })
  }

  const handleChartChange = (chartName, widget) => {
    setLoading(true)
    updateWidgetUI({
      variables: {
        id: widget.id,
        workspaceID: params.workspaceID,
        visualName: chartName,
      },
    })
  }

  const handleAddWidget = () => {
    setEditMode(false)
    setCurrentWidget(undefined)
    setShowWidgetModal(true)
  }

  const handleCloseWidget = () => {
    setShowWidgetModal(false)
  }

  const handleLayoutChange = layoutData => {
    console.log("layoutData", layoutData)

    updateLayout({
      variables: {
        id: params.dashboardID,
        layout: JSON.stringify(layoutData),
      },
    })
  }

  const displayChart = (
    chartName,
    widgetDatas,
    filters,
    layout,
    text,
    tableData
  ) => {
    let datas = []
    if (filters) {
      filters.forEach(f => {
        if (f.type === "0") {
          // Contains
          widgetDatas?.forEach(data => {
            if (data.fieldID === f.fieldID && f.values.includes(data.index)) {
              datas.push(data)
            }
          })
        } else {
          // Not contains
          widgetDatas?.forEach(data => {
            if (data.fieldID === f.fieldID && !f.values.includes(data.index)) {
              datas.push(data)
            }
          })
        }
      })
      widgetDatas?.forEach(data => {
        let index = filters.findIndex(f => f.fieldID === data.fieldID)
        if (index === -1) {
          datas.push(data)
        }
      })
    } else {
      datas = widgetDatas
    }

    switch (chartName) {
      case "Pie":
        return (
          <PieChart
            datas={datas}
            width={layout ? layout.w : 3}
            height={layout ? layout.h : 3}
            onDashboard={true}
          />
        )
      case "Donut":
        return (
          <DonutChart
            datas={datas}
            width={layout ? layout.w : 3}
            height={layout ? layout.h : 3}
            onDashboard={true}
          />
        )
      case "Column":
        return (
          <ColumnChart
            datas={datas}
            width={layout ? layout.w : 3}
            onDashboard={true}
          />
        )
      case "Bar":
        return (
          <BarChart
            datas={datas}
            width={layout ? layout.w : 3}
            onDashboard={true}
          />
        )
      case "Counter":
        return (
          <CounterChart
            datas={datas}
            height={layout ? layout.h : 3}
            width={layout ? layout.w : 3}
            onDashboard={true}
          />
        )
      case "Line":
        return <LineChart datas={datas} height={layout ? layout.h : 3} />
      case "Text":
        return <TextWidget text={text} editMode={false} textData={null} />
      case "Table":
        return <TableChart tableData={tableData} />

      default:
        return null
    }
  }

  function onSaveWidget(input) {
    setLoading(true)
    setShowWidgetModal(false)
    createWidget({
      variables: {
        workspaceID: params.workspaceID,
        input,
      },
    })
  }

  function onUpdateWidget(id, input) {
    setLoading(true)
    setShowWidgetModal(false)
    updateWidget({
      variables: {
        workspaceID: params.workspaceID,
        id,
        input,
      },
    })
  }

  // External Link Modal functions
  const handleExternalLink = () => {
    setShowExternalLinkModal(true)
  }

  const handleExternalLinkModalClose = () => {
    setShowExternalLinkModal(false)
  }

  const handleDateChange = (dates, dateString) => {
    const startDateStr =
      dates && dates[0] !== "" ? moment.utc(dates[0]).format("YYYY-MM-DD") : ""
    const endDateStr =
      dates && dates[1] !== "" ? moment.utc(dates[1]).format("YYYY-MM-DD") : ""

    setStartDate(startDateStr)
    setEndDate(endDateStr)

    updateDashboard({
      variables: {
        id: params.dashboardID,
        input: {
          startDate: startDateStr,
          endDate: endDateStr,
        },
      },
    })
  }

  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: "selection",
  //   },
  // ])

  const ref = React.createRef()

  const handleDashboardOptions = () => {
    return (
      <Menu
        className="rounded-3"
        onClick={e => {
          handleDashboardOptionsClick(e.key)
        }}
      >
        <Menu.ItemGroup title={<span className="mx-2">Dashboard Options</span>}>
          <Menu.Item key="0">
            <div>
              <i className="fa fa-link me-2"></i>
              Get External Link
            </div>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    )
  }

  const handleDashboardOptionsClick = key => {
    if (key === "0") {
      handleExternalLink()
    }
  }

  return (
    <React.Fragment>
      <div className="page-content h-100 overflow-scroll">
        <MetaTags>
          <title>Dashboard | Concize</title>
        </MetaTags>
        <Container fluid>
          {/* {loading ? (
            <div className="absolute-spinner">
              <Spinner color="primary" />
            </div>
          ) : null} */}
          <Breadcrumb
            left={currentDashboard ? currentDashboard.name : ""}
            url={`/${params?.workspaceID}/dashboards`}
            urlTitle="Dashboards"
            right={currentDashboard ? currentDashboard.name : ""}
          />

          <div className="d-flex justify-content-between align-items-center">
            <div>
              {/* <DateRangePicker
                onChange={item => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
              /> */}
              <RangePicker
                style={{
                  borderRadius: "6px",
                }}
                ranges={{
                  "Last Month": [
                    moment().subtract(1, "months").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last Week": [
                    moment().subtract(1, "weeks").startOf("week"),
                    moment().subtract(1, "weeks").endOf("week"),
                  ],
                  "This Week": [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "This Year": [
                    moment().startOf("year"),
                    moment().endOf("year"),
                  ],
                }}
                format="MMM D, YYYY"
                // defaultValue={[moment().subtract(30, "days"), moment()]}
                value={[
                  startDate ? moment(startDate, "YYYY-MM-DD") : "",
                  endDate ? moment(endDate, "YYYY-MM-DD") : "",
                ]}
                onChange={handleDateChange}
              />
            </div>
            <div>
              <div className="d-flex justify-content-end align-items-center">
                <div
                  className="btn btn-sm btn-light rounded-3 me-2"
                  onClick={() => {
                    setLoading(true)
                    getDashboard({
                      variables: {
                        id: params.dashboardID,
                      },
                    })
                  }}
                >
                  <i className="fas fa-redo"></i>
                </div>

                <AntDropdown
                  overlay={() => handleDashboardOptions()}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="btn btn-light rounded-3 task-ellipsis me-2"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  >
                    <i className="fa fa-ellipsis-h font-size-14"></i>
                  </div>
                </AntDropdown>

                {/* <Dropdown
                  className="me-2"
                  direction="left"
                  isOpen={showOptions}
                  toggle={() => setShowOptions(!showOptions)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-light rounded-3"
                  >
                    <i className="fas fa-ellipsis-h"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={handleExternalLink} className="py-2">
                      <i className="fa fa-link me-2"></i>
                      <span>Get External Link</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}

                <div
                  onClick={handleAddWidget}
                  className="btn btn-primary rounded-3"
                >
                  Add Widget
                </div>
              </div>
            </div>
          </div>

          <Card hidden={true} className="py-3 px-4 mt-3 rounded-3">
            <Row justify="space-between">
              <Col span={6}>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select a workspace"
                  classNamePrefix="select2-selection"
                  className="me-2"
                  value="Test"
                  styles={selectStyles}
                />
              </Col>
              <Col span={6} align="left">
                <Select
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select a account"
                  classNamePrefix="select2-selection"
                  value="Test"
                  styles={selectStyles}
                />
              </Col>
              <Col span={12} align="right">
                <div className="btn btn-info rounded-3">Filter</div>
              </Col>
            </Row>
          </Card>

          {/* React Grid layout */}
          <div>
            <Spin spinning={loading}>
              <ResponsiveReactGridLayout
                measureBeforeMount={false}
                className="layout"
                isDroppable={true}
                isDragable={true}
                isResizable={true}
                useCSSTransforms={false}
                preventCollision={false}
                layouts={{ lg: layouts }}
                margin={[20, 20]}
                onDragStop={handleLayoutChange}
                onResizeStop={handleLayoutChange}
                // onLayoutChange={handleLayoutChange}
                // draggableHandle="drag-handle"
                minH={3}
                minW={3}
              >
                {widgets && widgets.length
                  ? widgets.map((widget, index) => {
                      return (
                        <div key={widget.id}>
                          {/* <i className="fas fa-grip-vertical mx-2 font-size-14 drag-handle"></i> */}
                          <Card
                            style={{
                              height: "100%",
                              width: "100%",
                              overflow: "auto",
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center border-bottom py-2 px-1">
                              <h5
                                style={{
                                  cursor: "move",
                                }}
                                className="card-header bg-transparent"
                              >
                                {widget.widgetName}
                              </h5>
                              <div
                                className="me-2 d-flex"
                                style={{
                                  zIndex: 99,
                                }}
                              >
                                {widget.visualName !== "Text" &&
                                widget.visualName !== "Table" ? (
                                  <AntDropdown
                                    overlay={() => chartChangeMenu(widget)}
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    className="d-flex justify-content-center align-items-center"
                                  >
                                    <div
                                      className="subtask-hover rounded-3 task-ellipsis ms-2 border border-2"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    >
                                      <i className="fas fa-chart-area font-size-16"></i>
                                    </div>
                                  </AntDropdown>
                                ) : null}
                                <AntDropdown
                                  overlay={() => editWidgetMenu(widget)}
                                  trigger={["click"]}
                                  placement="bottomRight"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <div
                                    className="subtask-hover rounded-3 task-ellipsis ms-2 border border-2"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    <i className="fa fa-ellipsis-h font-size-16"></i>
                                  </div>
                                </AntDropdown>
                              </div>
                            </div>
                            {displayChart(
                              widget.visualName,
                              widget.datas,
                              widget.filters,
                              layouts[index],
                              widget.text,
                              widget.tableData
                            )}
                          </Card>
                        </div>
                      )
                    })
                  : null}
              </ResponsiveReactGridLayout>
            </Spin>
          </div>

          {/* Modals */}
          <NewWidgetModal
            showModal={showWidgetModal}
            handleCloseModal={handleCloseWidget}
            dashboardID={params.dashboardID}
            editMode={editMode}
            onSave={onSaveWidget}
            data={currentWidget}
            onUpdate={onUpdateWidget}
          />
          <ExternalLinkModal
            showModal={showExternalLinkModal}
            handleClose={handleExternalLinkModalClose}
            currentDashboard={currentDashboard}
            startDate={startDate}
            endDate={endDate}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardPage
