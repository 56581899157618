import React, { useState, useEffect } from "react"
import { Col, Select } from "antd"
import { Badge } from "reactstrap"

import Tooltip from "@material-ui/core/Tooltip"
const { Option } = Select

const DropdownMultiField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [dropdownMultiValue, setDropdownMultiValue] = useState(undefined)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      let value = taskCustomField.value.split(", ")
      setDropdownMultiValue(value)
    } else {
      setDropdownMultiValue(undefined)
    }
  }, [taskCustomField])

  const handleDropdownMultiFieldChange = options => {
    let newValuesStr = ""
    let newValuesArr = []

    if (options && options.length) {
      options.forEach((ele, index) => {
        newValuesArr.push(ele.value)
        if (index === options.length - 1) {
          newValuesStr = newValuesStr.concat(ele.value)
        } else {
          newValuesStr = newValuesStr.concat(ele.value, ", ")
        }
      })
    }
    setDropdownMultiValue(newValuesArr)
    let auditInput = {
      auditType: -1,
      customText: `Updated "${taskFieldObj.fieldName}" custom field`,
    }
    let updateObj = {
      id: taskFieldObj.id,
      taskID,
      value: newValuesStr,
      auditInput,
    }
    handleUpdateCustomField(updateObj)
  }

  return (
    <React.Fragment>
      <Col span={6}>
        {taskFieldObj.description ? (
          <Tooltip arrow placement="top" title={taskFieldObj.description}>
            <span>{taskFieldObj.fieldName}</span>
          </Tooltip>
        ) : (
          taskFieldObj.fieldName
        )}
      </Col>
      <Col span={12} className="ms-1">
        <Select
          mode="multiple"
          className="statusHover"
          size="middle"
          bordered={false}
          placeholder={taskFieldObj.fieldName}
          style={{ minWidth: "150px" }}
          value={dropdownMultiValue ? dropdownMultiValue : undefined}
          onChange={(_, options) => {
            handleDropdownMultiFieldChange(options)
          }}
          dropdownRender={menu => (
            <div className="d-flex flex-column ">{menu}</div>
          )}
        >
          {taskFieldObj.options.map((item, index) => {
            return (
              <Option value={String(index)} key={index}>
                <Badge
                  style={{
                    background: item.color,
                    padding: "6px 4px",
                    minWidth: "100px",
                  }}
                  className="font-size-12"
                >
                  {item.name}
                </Badge>
              </Option>
            )
          })}
        </Select>
      </Col>
    </React.Fragment>
  )
}

export default DropdownMultiField
