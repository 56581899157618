import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Select,
  Checkbox,
  Radio,
  Collapse,
  DatePicker,
} from "antd"
import moment from "moment"

const { Panel } = Collapse
const { Text } = Typography
const { TextArea } = Input

const CampaignModal = ({
  showModal,
  handleCloseModal,
  editMode,
  onSave,
  onUpdate,
  data,
  workspaces,
}) => {
  const [isEvergreen, setIsEvergreen] = useState(false)
  const [isAllWorkspaces, setIsAllWorkspaces] = useState(false)

  useEffect(() => {
    if (editMode) {
      console.log("data", data)
      setIsEvergreen(data.isEvergreen)
      setIsAllWorkspaces(data.isAllWorkspace)
    } else {
      setIsEvergreen(false)
    }
  }, [showModal])

  const handleEvergreenCheckbox = event => {
    setIsEvergreen(event.target.checked)
  }
  const handleWorkspaceCheckbox = event => {
    setIsAllWorkspaces(event.target.checked)
  }

  const handleValidSubmit = values => {
    let workspaceIDs = []
    if (isAllWorkspaces) {
      workspaces.forEach(workspace => {
        workspaceIDs.push(workspace.id)
      })
    } else {
      values.workspaces.forEach(workspaceID => {
        workspaceIDs.push(workspaceID)
      })
    }
    let campaignInput = {
      name: values.name,
      description: values.description ? values.description : "",
      startDate: moment(values.startDate).utc().format(),
      endDate: isEvergreen ? "" : moment(values.endDate).utc().format(),
      isEvergreen,
      status: values.status,
      isAllWorkspace: isAllWorkspaces,
      workspaceIDs,
    }
    if (editMode) {
      onUpdate(data.id, campaignInput)
    } else {
      onSave(campaignInput)
    }
  }

  return (
    <Modal
      scrollable={true}
      size="lg"
      isOpen={showModal}
      backdrop="static"
      scrollable={true}
    >
      <ModalHeader toggle={handleCloseModal}>
        <h4> {editMode ? "Update" : "Add"} Campaign</h4>
        <small className="font-size-12 text-secondary">
          Enter the basic details of campaigns to get started
        </small>
      </ModalHeader>
      <ModalBody>
        <Form
          id="campaign-form"
          initialValues={{
            ["name"]: data ? data.name : "",
            ["description"]: data ? data.description : "",
            ["startDate"]:
              data && data.startDate !== "" ? moment(data.startDate) : null,
            ["endDate"]:
              data && data.endDate !== "" ? moment(data.endDate) : null,
            ["status"]: data ? data.status : null,
            ["tags"]: data && data.tags ? data.tags : [],
            ["workspaces"]:
              data && !isAllWorkspaces
                ? data.workspaces.map(workspace => workspace.id)
                : [],
          }}
          onFinish={handleValidSubmit}
        >
          <Row>
            <Col span={24}>
              <Collapse
                defaultActiveKey={["1", "2"]}
                ghost
                expandIcon={({ isActive }) => (
                  <i
                    className={`fas fa-caret-right font-size-14 ${
                      isActive ? "bx-rotate-90" : ""
                    }`}
                  />
                )}
              >
                <Panel
                  header={
                    <div className="font-size-15 font-weight-semibold">
                      Basic Properties
                    </div>
                  }
                  key="1"
                >
                  <Row>
                    <Col span={24}>
                      <Text className="font-size-13 text-dark" strong>
                        Campaign Name <span className="text-danger">*</span>
                      </Text>
                      <Form.Item
                        name="name"
                        rules={[
                          { required: true, message: "Please enter a name" },
                        ]}
                      >
                        <Input
                          className="rounded-3 mt-1 py-1"
                          size="middle"
                          placeholder="Add campaign name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Text className="font-size-13 text-dark" strong>
                        Description
                      </Text>
                      <Form.Item name="description">
                        <TextArea
                          className="mt-1"
                          style={{
                            borderRadius: "8px",
                          }}
                          placeholder="Add description for a campaign"
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Text className="font-size-13 text-dark" strong>
                        Start Date <span className="text-danger">*</span>
                      </Text>
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please select a start date",
                          },
                        ]}
                      >
                        <DatePicker
                          format="MMMM Do YYYY, h:mm:ss a"
                          showTime
                          placeholder="Select Start Date"
                          style={{
                            width: "100%",
                          }}
                          className="rounded-3 mt-1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div className="font-size-13 d-flex justify-content-between mb-1">
                        <Text className="text-dark" strong>
                          End Date <span className="text-danger">*</span>
                        </Text>
                        <div>
                          <Checkbox
                            checked={isEvergreen}
                            className="text-dark"
                            onChange={handleEvergreenCheckbox}
                          >
                            Evergreen Campaign{" "}
                            <span className="text-info d-inline-block">
                              &infin;
                            </span>
                          </Checkbox>
                        </div>
                      </div>
                      <Form.Item
                        name="endDate"
                        rules={[
                          {
                            required: isEvergreen ? false : true,
                            message: "Please select a end date",
                          },
                        ]}
                      >
                        <DatePicker
                          disabled={isEvergreen}
                          format="MMMM Do YYYY, h:mm:ss a"
                          showTime
                          placeholder={
                            isEvergreen
                              ? "Not applicable for Evergreen Campaign"
                              : "Select End Date"
                          }
                          style={{
                            width: "100%",
                          }}
                          className="rounded-3"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Text className="text-dark" strong>
                        Status <span className="text-danger">*</span>
                      </Text>
                      <Form.Item
                        name="status"
                        rules={[
                          { required: true, message: "Please select a status" },
                        ]}
                      >
                        <Select
                          allowClear
                          suffixIcon={<i className="fas fa-chevron-down"></i>}
                          className="subtask-hover rounded-3 mt-1"
                          style={{
                            height: "32px",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            width: "100%",
                          }}
                          placeholder="Add Status"
                          bordered={false}
                          dropdownStyle={{ borderRadius: "6px" }}
                        >
                          <Select.Option value="one">Approved</Select.Option>
                          <Select.Option value="two">Draft</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="d-none">
                      <Text className="text-dark" strong>
                        Tags
                      </Text>
                      <Form.Item name="tags">
                        <Select
                          allowClear
                          mode="multiple"
                          suffixIcon={<i className="fas fa-chevron-down"></i>}
                          className="subtask-hover rounded-3 mt-1"
                          style={{
                            height: "32px",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            width: "100%",
                          }}
                          placeholder="Add Tags"
                          bordered={false}
                          dropdownStyle={{ borderRadius: "6px" }}
                        >
                          <Select.Option value="one">2020</Select.Option>
                          <Select.Option value="two">
                            Championship
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        className="text-dark"
                        onChange={handleWorkspaceCheckbox}
                        checked={isAllWorkspaces}
                      >
                        Visible in all workspaces
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row className="mt-2" gutter={24}>
                    <Col span={12}>
                      <Text className="text-dark" strong>
                        Workspaces <span className="text-danger">*</span>
                      </Text>
                      <Form.Item
                        name="workspaces"
                        rules={[
                          {
                            required: isAllWorkspaces ? false : true,
                            message: "Please select one or more workspaces",
                          },
                        ]}
                      >
                        <Select
                          disabled={isAllWorkspaces}
                          allowClear
                          mode="multiple"
                          suffixIcon={<i className="fas fa-chevron-down"></i>}
                          className={`subtask-hover rounded-3 mt-1 ${
                            isAllWorkspaces ? "bg-light" : ""
                          }`}
                          style={{
                            height: "32px",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            width: "100%",
                          }}
                          placeholder={
                            isAllWorkspaces
                              ? "All workspaces are selected"
                              : "Select one or more workspaces"
                          }
                          bordered={false}
                          dropdownStyle={{ borderRadius: "6px" }}
                        >
                          {workspaces && !isAllWorkspaces
                            ? workspaces.map(workspace => {
                                return (
                                  <Select.Option value={workspace.id}>
                                    {workspace.name}
                                  </Select.Option>
                                )
                              })
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="d-none">
                      <Text className="text-dark" strong>
                        Users/User Groups
                      </Text>
                      <Form.Item name="workspaces">
                        <Select
                          allowClear
                          mode="multiple"
                          suffixIcon={<i className="fas fa-chevron-down"></i>}
                          className={`subtask-hover rounded-3 mt-1 ${
                            isAllWorkspaces ? "bg-light" : ""
                          }`}
                          style={{
                            height: "32px",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            width: "100%",
                          }}
                          placeholder="Select users/user groups"
                          bordered={false}
                          dropdownStyle={{ borderRadius: "6px" }}
                        >
                          <Select.Option value="one">
                            User group 1
                          </Select.Option>
                          <Select.Option value="two">
                            User group 2
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="d-none">
                    <Col>
                      <Checkbox className="text-dark">
                        Is Campaign confidential?{" "}
                        <i className="fal fa-lock-alt"></i>
                      </Checkbox>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div
          onClick={handleCloseModal}
          className="btn btn-outline-secondary rounded-3"
        >
          Cancel
        </div>
        <button
          className="btn btn-success rounded-3"
          type="submit"
          form="campaign-form"
        >
          {editMode ? "Update" : "Create"}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default CampaignModal
