import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Menu, Dropdown } from "antd"

import { Tooltip } from "antd"
import { ROLE } from "components/helpers/enums"

const LeftFolderStructure = ({
  props,
  mainSpace,
  spaceID,
  workspaceID,
  handleSetSpace,
  handleShowSpaceModal,
  handleSetDeleteSpace,
  handleShowDeleteModal,
}) => {
  const [showFolderDotIcon, setShowFolderDotIcon] = useState(false)
  const [showFileDotIcon, setShowFileDotIcon] = useState(false)
  const userObj = JSON.parse(localStorage.getItem("concizeUser"))
  const menu = (id, name, isFolder, tenantID) => (
    <Menu
      style={{
        fontSize: "12px",
        borderRadius: "6px",
        width: "150px",
      }}
      onClick={e => {
        handleMenuClick(e.key, id, name, isFolder, tenantID, workspaceID)
      }}
    >
      <Menu.ItemGroup title="Space options">
        {isFolder ? (
          <>
            <Menu.Item key={1}>
              <i className="fal fa-cube me-2"></i>
              <span>New Project</span>
            </Menu.Item>
            <Menu.Item key={2}>
              <i className="fal fa-list-alt me-2"></i>
              <span>New List</span>
            </Menu.Item>
          </>
        ) : (
          ""
        )}

        <Menu.Item key={3}>
          <i className="fal fa-edit me-2"></i>
          <span>Edit</span>
        </Menu.Item>
        <Menu.Item key={4} className="text-danger">
          <i className="fal fa-trash-alt me-2"></i>
          <span> Delete</span>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const handleMenuClick = (key, id, name, isFolder, tenantID, workspaceID) => {
    if (key === "1") {
      // Create NEW SPACE
      let space = {
        id,
        name,
        isFolder: true,
        workspaceID,
      }
      handleSetSpace(space)
      handleShowSpaceModal()
    } else if (key === "2") {
      // Create new LIST under id
      let space = {
        id,
        name,
        isFolder: false,
      }
      handleSetSpace(space)
      handleShowSpaceModal()
    } else if (key === "3") {
      // Update space/task
      let space = {
        id,
        name,
        isFolder: true,
        update: true,
      }
      handleSetSpace(space)
      handleShowSpaceModal()
    } else if (key === "4") {
      // Delete space/task
      let space = {
        id,
        name,
        isFolder,
        tenantID,
        parentID: mainSpace.id,
      }
      handleSetDeleteSpace(space)
      handleShowDeleteModal()
    }
  }

  const tree = mainSpace.spaces ? (
    mainSpace.spaces.map(space => {
      if (space.isFolder === true) {
        return (
          <LeftFolderStructure
            key={space.id}
            props={props}
            mainSpace={space}
            workspaceID={workspaceID}
            spaceID={spaceID}
            handleSetSpace={handleSetSpace}
            handleShowSpaceModal={handleShowSpaceModal}
            handleSetDeleteSpace={handleSetDeleteSpace}
            handleShowDeleteModal={handleShowDeleteModal}
          />
        )
      }

      return (
        <li
          key={space.id}
          onMouseEnter={() => setShowFileDotIcon(true)}
          onMouseLeave={() => setShowFileDotIcon(false)}
        >
          <Link
            className={spaceID === space.id ? "mm-active" : ""}
            to={`/${space.workspace.id}/space-list/${space.id}`}
          >
            <span className="d-flex align-items-center justify-content-start">
              <i
                className={
                  spaceID === space.id ? "bx bx-file mm-active" : "bx bx-file"
                }
              ></i>

              <Tooltip
                zIndex={space.name.length > 9 ? 9999 : -1}
                title={space.name}
                placement="topLeft"
              >
                <span
                  style={{
                    alignItems: "center",
                    width: "80px",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {props.t(space.name)}
                </span>
              </Tooltip>
              <span
                className="d-inline-block"
                style={{
                  visibility: showFileDotIcon ? "visible" : "hidden",
                }}
              >
                <Dropdown
                  className={userObj?.role === ROLE.spaceUser ? "d-none" : ""}
                  trigger={["click"]}
                  overlay={() =>
                    menu(space.id, space.name, space.isFolder, "", workspaceID)
                  }
                  placement="bottomCenter"
                >
                  <i
                    className={
                      spaceID === space.id
                        ? "bx bx-dots-vertical-rounded d-inline-block ms-2  mm-active"
                        : "bx bx-dots-vertical-rounded d-inline-block ms-2 "
                    }
                  ></i>
                </Dropdown>
              </span>
            </span>
          </Link>
        </li>
      )
    })
  ) : (
    <li>
      <Link to="#">
        <span>{props.t("")}</span>
      </Link>
    </li>
  )

  return (
    <li
      key={mainSpace.id}
      onMouseEnter={() => setShowFolderDotIcon(true)}
      onMouseLeave={() => setShowFolderDotIcon(false)}
    >
      <Link to="#">
        <span className="d-flex align-items-center justify-content-start">
          <i className="bx bx-cube"></i>
          <Tooltip
            title={mainSpace.name}
            placement="topLeft"
            zIndex={mainSpace.name.length > 9 ? 9999 : -1}
          >
            <span
              style={{
                alignItems: "center",
                width: "80px",
                textOverflow: "ellipsis",
                display: "inline-block",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {props.t(mainSpace.name)}
            </span>
          </Tooltip>
          <span
            className="d-inline-block"
            style={{
              visibility: showFolderDotIcon ? "visible" : "hidden",
            }}
          >
            <Dropdown
              className={userObj?.role === ROLE.spaceUser ? "d-none" : ""}
              trigger={["click"]}
              overlay={() =>
                menu(
                  mainSpace.id,
                  mainSpace.name,
                  mainSpace.isFolder,
                  mainSpace?.tenant ? mainSpace.tenant.id : "",
                  workspaceID
                )
              }
              placement="bottomCenter"
            >
              <i className={"bx bx-dots-vertical-rounded ms-3"}></i>
            </Dropdown>
          </span>
        </span>
      </Link>
      <ul className="sub-menu">{tree}</ul>
    </li>
  )
}

export default LeftFolderStructure
