import React, { useState } from "react"
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap"

import { useMutation } from "@apollo/client"
import { DELETE_SPACE } from "../../graphql/mutation"

const DeleteModal = ({
  showDeleteModal,
  handleCloseDeleteModal,
  space,
  workspaceID,
  handleUpdateSpaces,
}) => {
  const [deleteSpace] = useMutation(DELETE_SPACE, {
    onCompleted: data => {
      if (data && data.deleteSpace) {
        handleUpdateSpaces(data.deleteSpace)
        handleCloseDeleteModal()
      }
    },
    onError: error => {
      console.log("error", error)
    },
  })
  const [isDisable, setIsDisable] = useState(true)

  const handleChange = name => {
    if (name === space.name) {
      setIsDisable(false)
    } else {
      setIsDisable(true)
    }
  }

  const handleDeleteSpace = () => {
    if (space === undefined) return
    deleteSpace({
      variables: {
        id: space.id,
        parentID: space.parentID,
        workspaceID,
      },
    })
  }
  return (
    <Modal isOpen={showDeleteModal} backdrop={"static"}>
      <ModalHeader tag="h4" toggle={handleCloseDeleteModal}>
        Delete{" "}
        {space?.tenantID !== ""
          ? "Space"
          : space?.isFolder
          ? "Project"
          : "List"}
      </ModalHeader>
      <ModalBody>
        <div className="">
          <h5>
            <span className="text-danger">Delete: </span>
            {space?.name}
          </h5>
          <p className="font-size-14 my-3">
            <span className="font-weight-semibold">Warning: </span>
            All tasks and templates within this Space will be deleted. If you’re
            sure you want to delete all tasks, templates and this Space, type
            space name to confirm.
          </p>

          <p className="font-weight-semibold text-center">Type {space?.name}</p>
          <input
            className="w-50 m-auto text-secondary form-control font-size-18 text-center"
            type="text"
            placeholder={space?.name}
            onFocus={e => (e.target.placeholder = "")}
            onBlur={e => (e.target.placeholder = space?.name)}
            onChange={e => handleChange(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={handleCloseDeleteModal} className="ms-2 btn btn-light">
          Cancel
        </button>
        <Button
          disabled={isDisable}
          onClick={handleDeleteSpace}
          className="mx-2"
          color="danger"
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal
