import React, { useState } from "react"
import { Input } from "reactstrap"
import { Link } from "react-router-dom"

const settings = [
  {
    name: "Workspace",
    link: "/workspace",
    icon: "fal fa-briefcase",
  },
  {
    name: "Users",
    link: "/users",
    icon: "fal fa-user",
  },
  {
    name: "Allocation Profile",
    link: "/allocation-profile",
    icon: "fal fa-id-card",
  },
  {
    name: "Accounts",
    link: "/accounts",
    icon: "fal fa-hashtag",
  },
  {
    name: "Custom Fields",
    link: "/custom-fields",
    icon: "fal fa-edit",
  },
  {
    name: "Campaigns",
    link: "/campaigns",
    icon: "fal fa-megaphone",
  },
]

const GlobalSettings = () => {
  const [searchText, setSearchText] = useState("")

  const handleSearch = event => {
    setSearchText(event.target.value)
  }

  return (
    <span>
      <div className="search-box chat-search-box">
        <div className="position-relative">
          <Input
            type="text"
            className="form-control"
            placeholder="Search..."
            onChange={handleSearch}
          />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>

      <div className="d-flex justify-content-start my-4 flex-wrap">
        {/* <div className="custom-card">
          <div className="custom-card-box shadow-sm">
            <i className="fal fa-pen-square"></i>
          </div>
          <p>Custom Fields</p>
        </div>

        <div className="custom-card">
          <div className="custom-card-box shadow-sm">
            <i className="fal fa-credit-card"></i>
          </div>
          <p>Accounts</p>
        </div>
        <div className="custom-card">
          <div className="custom-card-box shadow-sm">
            <i className="bx bx-card"></i>
          </div>
          <p>Account Groups</p>
        </div> */}
        {settings &&
          settings
            .filter(setting => setting.name.includes(searchText))
            .map(setting => {
              return (
                <Link to={setting.link} className="custom-card">
                  <div className="custom-card-box shadow-sm">
                    <i className={setting.icon}></i>
                  </div>
                  <p>{setting.name}</p>
                </Link>
              )
            })}
      </div>
    </span>
  )
}

export default GlobalSettings
