export const ROLE = {
  systemAdmin: "SYSTEM_ADMIN",
  globalAdmin: "GLOBAL_ADMIN",
  spaceAdmin: "SPACE_ADMIN",
  spaceUser: "SPACE_USER",
}

export const ROLES_ARRAY = [
  ROLE.systemAdmin,
  ROLE.globalAdmin,
  ROLE.spaceAdmin,
  ROLE.spaceUser,
]
