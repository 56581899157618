import React, { useState, useEffect } from "react"
import { Table, Input, Select } from "antd"
import { IconButton } from "@material-ui/core"
import NoteModal from "./noteModal"

const { Option } = Select

const TrackTimeTable = ({
  weekData,
  data,
  workspaces,
  handleDelete,
  loading,
  handleFieldChange,
}) => {
  const handleWorkspaceChange = (value, record) => {
    let newObj = { ...record }
    let id = newObj.id
    newObj.workspace = value
    delete newObj.__typename
    delete newObj.id

    handleFieldChange(id, newObj)
  }

  const handleTypeChange = (value, record) => {
    let newObj = { ...record }
    let id = newObj.id
    newObj.type = value
    delete newObj.__typename
    delete newObj.id

    handleFieldChange(id, newObj)
  }

  let columnsData = [
    {
      key: "1",
      title: "Workspace",
      dataIndex: "workspace",
      render: (workspace, record, index) => {
        return (
          <div
          // style={{ width: "200px" }}
          >
            <Select
              showSearch
              bordered={false}
              className="rounded-3 task-hover"
              suffixIcon={<i className="fas fa-chevron-down"></i>}
              style={{
                height: "33px",
                borderRadius: "4px",
                width: "100%",
              }}
              onChange={val => handleWorkspaceChange(val, record)}
              placeholder="Select a workspace"
              bordered={false}
              value={workspace && workspace !== "" ? workspace : null}
              dropdownStyle={{ borderRadius: "6px" }}
            >
              {workspaces
                ? workspaces.map(ele => {
                    return (
                      <Option key={ele.id} value={ele.name}>
                        {ele.name}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </div>
        )
      },
    },
    {
      key: "2",
      title: "Type",
      dataIndex: "type",
      render: (type, record, index) => {
        return (
          <div
          // style={{ width: "150px" }}
          >
            <Select
              showSearch
              bordered={false}
              className="rounded-3 task-hover"
              suffixIcon={<i className="fas fa-chevron-down"></i>}
              style={{
                height: "33px",
                borderRadius: "4px",
                width: "100%",
              }}
              placeholder="Select a type"
              bordered={false}
              dropdownStyle={{ borderRadius: "6px" }}
              onChange={val => handleTypeChange(val, record)}
              defaultValue={type && type !== "" ? type : null}
            >
              <Option key="1" value="Training">
                Training
              </Option>
              <Option key="1" value="Support">
                Support
              </Option>
              <Option key="1" value="Configuration & Optimization">
                Configuration & Optimization
              </Option>
              <Option key="1" value="IT">
                IT
              </Option>
            </Select>
          </div>
        )
      },
    },
    {
      key: "3",
      title: "Billable",
      dataIndex: "billable",
      render: billable => {
        return (
          <div className="text-center">
            <input
              disabled
              checked={billable}
              type="checkbox"
              style={{ cursor: "pointer" }}
              className="form-check-input"
              id="one"
            />
          </div>
        )
      },
    },
    {
      key: "4",
      title: "Notes",
      dataIndex: "notes",
      render: (_, record) => {
        return (
          <div>
            <IconButton onClick={() => handleOpen(record)}>
              <i className="fal fa-sticky-note font-size-14"></i>
            </IconButton>
          </div>
        )
      },
    },
    {
      key: "delete",
      title: "Action",
      render: record => (
        <IconButton onClick={() => handleDelete(record.id)}>
          <i className="fas fa-trash font-size-14 text-danger"></i>
        </IconButton>
      ),
    },
  ]

  const [columns, setColumns] = useState(columnsData)
  const [showNoteModal, setShowNoteModal] = useState(false)
  const [currentData, setCurrentData] = useState(undefined)

  const handleWeekChange = (val, index, i, allData) => {
    // const reg = /^\d+(\.\d{1,2})?$/
    const reg = /^[0-9]{1,2}(?:\.[0-9]{1})?$/

    if (
      val === undefined ||
      allData === undefined ||
      allData[index] === undefined ||
      !reg.test(val)
    ) {
      return
    }

    let newObj = { ...allData[index] }
    const id = newObj.id
    const value = val
    delete newObj.id
    delete newObj.__typename

    let newWeekDates = [...newObj.weekDates]
    newWeekDates[i] = value
    newObj.weekDates = newWeekDates

    handleFieldChange(id, newObj)
  }

  const handleOpen = record => {
    setCurrentData(record)
    setShowNoteModal(true)
  }

  const handleClose = () => {
    setShowNoteModal(false)
  }

  const onNotesSave = (id, newObj) => {
    if (id && newObj) {
      delete newObj.id
      delete newObj.__typename

      handleFieldChange(id, newObj)
    }
    setShowNoteModal(false)
  }

  useEffect(() => {
    let newCols = [...columns]
    setColumns(newCols)
  }, [workspaces])

  useEffect(() => {
    if (weekData && data) {
      let newColumns = []
      weekData.forEach((ele, i) => {
        let obj = {
          key: ele.key,
          title: () => {
            return (
              <div style={{ whiteSpace: "pre", textAlign: "center" }}>
                {ele.date.replace(" ", "\n")}
              </div>
            )
          },
          dataIndex: ele.date,
          render: (_, record, index) => {
            return (
              <div style={{ width: "60px" }}>
                <Input
                  onChange={e => {
                    let val = e.target.value
                    handleWeekChange(val, index, i, data)
                  }}
                  className="rounded-3 task-hover px-2"
                  type="number"
                  value={record && record.weekDates ? record.weekDates[i] : ""}
                />
              </div>
            )
          },
        }
        newColumns.push(obj)
      })

      let newCols = [...columnsData]
      let index = newCols.length - 2
      newCols.splice(index, 0, ...newColumns)

      setColumns(newCols)
    }
  }, [weekData, data])
  return (
    <div>
      <Table
        scroll={{
          x: 400,
        }}
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={data}
      />
      <NoteModal
        currentData={currentData}
        showNoteModal={showNoteModal}
        handleClose={handleClose}
        onNotesSave={onNotesSave}
      />
    </div>
  )
}

export default TrackTimeTable
