import React, { useState, useEffect } from "react"
import { Card, CardTitle, CardBody } from "reactstrap"

import { Input, Empty, Spin } from "antd"

import PieChart from "./allCharts/highcharts/pieChart"
import DountChart from "./allCharts/highcharts/donutChart"
import ColumnChart from "./allCharts/highcharts/columnChart"
import BarChart from "./allCharts/highcharts/barChart"
import CounterChart from "./allCharts/highcharts/counterChart"
import TextWidget from "./textWidget"
import LineChart from "./allCharts/highcharts/lineChart"
import TableChart from "./allCharts/highcharts/tableChart"

const Preview = ({
  previewLoading,
  widgetName,
  visualName,
  visualData,
  handleWidgetNameChange,
  handleTextChange,
  editMode,
  textData,
  handleChangeSelectedData,
  tableData,
}) => {
  return (
    <div
      className="mt-4"
      style={{
        height: "80vh",
        overflowY: "scroll",
      }}
    >
      <Spin spinning={previewLoading}>
        <Card className="mx-4">
          <CardBody>
            <CardTitle className="font-size-18 border-bottom">
              {widgetName}
              <Input
                className="font-size-18 font-weight-semibold d-none"
                bordered={false}
                value={widgetName}
                placeholder="Name of the widget"
                onChange={event => handleWidgetNameChange(event.target.value)}
              />
            </CardTitle>

            {visualName === undefined ||
            visualName === "" ||
            visualName === null ? (
              <div>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : null}

            <div className="d-flex align-items-center justify-content-around h-100 w-100">
              {visualName === "Pie" ? (
                <PieChart
                  datas={visualData}
                  width={4}
                  height={4}
                  handleChangeSelectedData={handleChangeSelectedData}
                />
              ) : null}
              {visualName === "Column" ? (
                <ColumnChart
                  datas={visualData}
                  width={4}
                  height={4}
                  handleChangeSelectedData={handleChangeSelectedData}
                />
              ) : null}
              {visualName === "Donut" ? (
                <DountChart
                  datas={visualData}
                  width={4}
                  height={4}
                  handleChangeSelectedData={handleChangeSelectedData}
                />
              ) : null}
              {visualName === "Bar" ? (
                <BarChart
                  datas={visualData}
                  width={4}
                  height={4}
                  handleChangeSelectedData={handleChangeSelectedData}
                />
              ) : null}
              {visualName === "Line" ? (
                <LineChart
                  datas={visualData}
                  width={4}
                  handleChangeSelectedData={handleChangeSelectedData}
                />
              ) : null}
              {visualName === "Counter" ? (
                <CounterChart
                  datas={visualData}
                  width={4}
                  height={4}
                  handleChangeSelectedData={handleChangeSelectedData}
                />
              ) : null}
              {visualName === "Text" ? (
                <TextWidget
                  editMode={editMode}
                  textData={textData}
                  text={null}
                  handleTextChange={handleTextChange}
                />
              ) : null}
              {visualName === "Table" ? (
                <TableChart tableData={tableData} />
              ) : null}
            </div>
          </CardBody>
        </Card>
      </Spin>
    </div>
  )
}

export default Preview
