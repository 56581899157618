import React, { useState, useEffect } from "react"
import { useHistory, useParams, Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"

import {
  Row,
  Col,
  Input,
  Card,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

import DashboardModal from "./components/dashboardModal"

import {
  CREATE_DASHBOARD,
  UPDATE_DASHBOARD,
  DELETE_DASHBOARD,
} from "../../graphql/mutation"
import { GET_DASHBOARDS } from "../../graphql/queries"
import { useMutation, useQuery } from "@apollo/client"

const Dashboard = () => {
  const history = useHistory()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [showDashboardModal, setShowDashboardModal] = useState(false)
  const [editDashboard, setEditDashboard] = useState(false)
  const [dashboards, setDashboards] = useState(undefined)
  const [searchText, setSearchText] = useState(undefined)
  const [currentDashboard, setCurrentDashboard] = useState(undefined)

  useEffect(() => {
    if (params.workspaceID) {
      refetch()
    }
  }, [params.workspaceID])

  // APIS
  const { refetch } = useQuery(GET_DASHBOARDS, {
    fetchPolicy: "network-only",
    variables: {
      workspaceID: params.workspaceID,
    },
    onCompleted: data => {
      if (data && data.getDashboards !== null) {
        setDashboards(data.getDashboards)
      } else {
        setDashboards(undefined)
      }
      setLoading(false)
    },
    onError: err => {
      console.log("ERR", err)
      setLoading(false)
    },
  })

  const [createDashboard] = useMutation(CREATE_DASHBOARD, {
    onCompleted: data => {
      if (data && data.createDashboard !== null) {
        if (dashboards) {
          setDashboards([...dashboards, data.createDashboard])
        } else {
          setDashboards([data.createDashboard])
        }
        setShowDashboardModal(false)
        // history.push(
        //   `/${params.workspaceID}/dashboards/${data.createDashboard.id}`
        // )
      }
      setLoading(false)
    },
    onError: err => {
      console.log("ERR", err)
      setLoading(false)
    },
  })

  const [updateDashboard] = useMutation(UPDATE_DASHBOARD, {
    onCompleted: data => {
      if (data && data.updateDashboard) {
        let index = dashboards.findIndex(dashboard => {
          return dashboard.id === data.updateDashboard.id
        })
        setDashboards([
          ...dashboards.slice(0, index),
          data.updateDashboard,
          ...dashboards.slice(index + 1),
        ])
      }
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [deleteDashboard] = useMutation(DELETE_DASHBOARD, {
    onCompleted: data => {
      if (data && data.deleteDashboard) {
        let index = dashboards.findIndex(dashboard => {
          return dashboard.id === data.deleteDashboard.id
        })
        let newDashboards = [...dashboards]
        newDashboards.splice(index, 1)
        setDashboards(newDashboards)
      }
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const handleCloseDashboard = () => {
    setShowDashboardModal(false)
  }

  const handleCreateDashboard = () => {
    setCurrentDashboard(undefined)
    setEditDashboard(false)
    setShowDashboardModal(true)
  }

  function onSaveDashboard(value) {
    setLoading(true)
    const input = {
      name: value.name,
      workspaceID: params.workspaceID,
      startDate: "",
      endDate: "",
    }
    createDashboard({
      variables: {
        input: input,
      },
    })
  }

  function onUpdateDashboard(value) {
    setLoading(true)
    let input = {
      name: value.name,
      workspaceID: params.workspaceID,
    }
    updateDashboard({
      variables: {
        id: currentDashboard.id,
        input,
      },
    })
    setShowDashboardModal(false)
  }

  const handleEditDashboard = dashboard => {
    setCurrentDashboard(dashboard)
    setEditDashboard(true)
    setShowDashboardModal(true)
  }

  const handleDeleteDashboard = id => {
    deleteDashboard({
      variables: {
        id,
      },
    })
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          overflow: "scroll",
        }}
      >
        <MetaTags>
          <title>Dashboard | Concize</title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div className="absolute-spinner">
              <Spinner color="primary" />
            </div>
          ) : null}
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div>
              <h4 className="mb-sm-0 font-size-18">Dashboards</h4>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end mb-2">
            {/* <Col lg={3} sm={4}> */}
            <div className="me-2">
              <div className="search-box chat-search-box py-4">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={handleSearch}
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </div>
            {/* </Col> */}
            {/* <Col lg={2} sm={4} className="d-flex justify-content-end"> */}
            <div
              onClick={handleCreateDashboard}
              className="btn btn-primary rounded-3"
            >
              Create Dashboard
            </div>
            {/* </Col> */}
          </div>

          <Row>
            {dashboards
              ? dashboards
                  .filter(dashboard =>
                    dashboard.name.includes(searchText ? searchText : "")
                  )
                  .map((dashboard, index) => {
                    return (
                      <Col lg={4} md={5} sm={6} key={dashboard.id}>
                        <Card key={dashboard.id}>
                          <div className="py-2 px-1">
                            <Link
                              to={`/${params.workspaceID}/dashboards/${dashboard.id}`}
                            >
                              <h5
                                id={`dashboard${index}`}
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  width: "80%",
                                  whiteSpace: "nowrap",
                                }}
                                className="card-header bg-transparent d-flex"
                              >
                                <i className="mdi mdi-grid-large me-2 d-block"></i>
                                {dashboard.name}
                              </h5>
                              <UncontrolledTooltip
                                placement="top"
                                target={`dashboard${index}`}
                              >
                                {dashboard.name}
                              </UncontrolledTooltip>
                            </Link>
                            <UncontrolledDropdown
                              style={{
                                position: "absolute",
                                right: 0,
                                top: "15px",
                              }}
                            >
                              <DropdownToggle
                                className="btn btn-sm btn-light me-2 rounded-3"
                                tag="i"
                              >
                                <i
                                  className="bx bx-dots-vertical-rounded"
                                  style={{
                                    zIndex: -1,
                                  }}
                                />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end mt-1">
                                <DropdownItem
                                  onClick={() => handleEditDashboard(dashboard)}
                                >
                                  <i className="bx bx-edit me-2"></i>
                                  <span>Edit Dashboard</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem
                                  className="text-danger"
                                  onClick={() =>
                                    handleDeleteDashboard(dashboard.id)
                                  }
                                >
                                  <i className="bx bx-trash me-2"></i>
                                  <span>Delete Dashboard</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </Card>
                      </Col>
                    )
                  })
              : null}
          </Row>

          {/* All Modals */}
          <DashboardModal
            showModal={showDashboardModal}
            editMode={editDashboard}
            handleClose={handleCloseDashboard}
            onSave={onSaveDashboard}
            data={currentDashboard}
            onUpdate={onUpdateDashboard}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
