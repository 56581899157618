import React from "react"

import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import "./style.css"

import GlobalSettings from "./components/globalSettings"
import NotAuthorizedPage from "components/Common/NotAuthorizedPage"
import { ROLE } from "components/helpers/enums"

const Settings = () => {
  const obj = JSON.parse(localStorage.getItem("concizeUser"))
  let role = obj.role

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Concize </title>
        </MetaTags>
        {role === ROLE.spaceAdmin || role === ROLE.spaceUser ? (
          <NotAuthorizedPage />
        ) : (
          <Container fluid className="px-5">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <h4 className="mb-sm-0 font-size-18">Settings</h4>
              </div>
            </div>

            <GlobalSettings />
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default Settings
