import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"

const ErrorDialog = ({
  showError,
  handleErrorDialogClose,
  type,
  title,
  errorMessage,
}) => {
  return (
    <SweetAlert
      show={showError}
      customButtons={
        <button onClick={handleErrorDialogClose} className="btn btn-primary">
          Ok
        </button>
      }
      onConfirm={handleErrorDialogClose}
      title={title}
      type={type}
    >
      {errorMessage}
    </SweetAlert>
  )
}

export default ErrorDialog
