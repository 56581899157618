import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Select,
  Checkbox,
  Radio,
  Collapse,
  Switch,
} from "antd"
import IconButton from "@material-ui/core/IconButton"
import { ColorPicker } from "material-ui-color"
import toast from "react-hot-toast"

const { Panel } = Collapse

const { Text } = Typography
const { TextArea } = Input

const palette = {
  red: "#EF6D6D",
  blue: "#398AB9",
  green: "#8BDB81",
  yellow: "#F1D00A",
  pink: "#FF7BA9",
  grey: "#789395",
  greenSheen: "#70C1B3",
  ming: "#3C6E71",
}

const defaultCustomFields = [
  { fieldType: "Date", icon: "fal fa-calendar" },
  { fieldType: "Dropdown", icon: "fal fa-chevron-circle-down" },
  { fieldType: "Dropdown Multi", icon: "fal fa-chevron-circle-down" },
  { fieldType: "Text", icon: "fal fa-text" },
  { fieldType: "Text Area", icon: "fal fa-paragraph" },
  { fieldType: "Number", icon: "fal fa-sort-numeric-up-alt" },
  { fieldType: "Checkbox", icon: "fal fa-check-square" },
]

const FieldModal = ({
  showModal,
  data,
  editMode,
  handleClose,
  workspaces,
  spaces,
  onSave,
  onUpdate,
}) => {
  const [selectedFieldType, setSelectedFieldType] = useState("Date")
  const [dropdown, setDropdown] = useState(undefined)
  const [description, setDescription] = useState(null)
  const [visibility, setVisibility] = useState(1)
  const [isAllWorkspaces, setIsAllWorkspaces] = useState(false)
  const [isAllSpaces, setIsAllSpaces] = useState(false)

  const [selectedWorkspaceIDs, setSelectedWorkspaceIDs] = useState(null)
  const [selectedSpaceIDs, setSelectedSpaceIDs] = useState(null)

  useEffect(() => {
    if (showModal) {
      if (!editMode) {
        setDropdown({
          show: true,
          options: [{ name: "", placeholder: "Option 1", color: "#6FB2D2" }],
        })
        setSelectedFieldType("Date")
        setDescription(null)
      } else {
        if (data.fromSettings !== true) {
          if (data.space && data.space.id) {
            console.log("data.space.id", data.space.id)
            console.log("spaces", spaces)
            setSelectedSpaceIDs([data.space.id])
            setVisibility(2)
          } else if (data.workspace && data.workspace.id) {
            setSelectedWorkspaceIDs([data.workspace.id])
            setVisibility(1)
          }
        } else {
          setIsAllSpaces(data.isAllSpaces)
          setIsAllWorkspaces(data.isAllWorkspaces)
          setSelectedWorkspaceIDs(data?.workspaceIDs)
          setSelectedSpaceIDs(data?.spaceIDs)
          setVisibility(data.visibility)
        }

        setSelectedFieldType(data.fieldType)
        setDescription(data.description)
        setDropdown({
          show: data.fieldType.includes("Dropdown"),
          options: data.options,
        })
      }
    }
  }, [showModal])

  const handleSelectCustomField = name => {
    setSelectedFieldType(name)
  }

  const handleVisibilityRadio = e => {
    setVisibility(e.target.value)
  }

  const handleSubmit = values => {
    console.log("values", values)

    let input = {
      fieldName: values.fieldName,
      fieldType: selectedFieldType,
      description: values.description,
      helperText: values.helperText,
      fromSettings: true,
      visibility,
      isAllSpaces,
      isAllWorkspaces,
      workspaceIDs: values.workspaceIDs,
      spaceIDs: values.spaceIDs,
      active: values.active,
    }

    if (selectedFieldType.includes("Dropdown")) {
      let options = []
      if (dropdown && dropdown.options) {
        for (let i = 0; i < dropdown.options.length; i++) {
          let option = dropdown.options[i]
          if (option.name === "") {
            toast.error("All options must have a name.", {
              position: "top-right",
            })
            return
          } else {
            options.push({
              name: option.name,
              color: option.color,
            })
          }
        }
      }
      input["options"] = options
    }

    if (editMode) {
      input.fromSettings =
        data.fromSettings !== null || data.fromSettings !== undefined
          ? data.fromSettings
          : false
      onUpdate(data.id, input)
    } else {
      onSave(input)
    }
  }

  const handleAddDropdownOption = () => {
    let l = dropdown.options.length
    if (dropdown.options[l - 1].name === "") {
      return toast.error("Please enter option name to add more options", {
        position: "top-right",
      })
    }
    const newOptions = [
      ...dropdown.options,
      {
        name: "",
        placeholder: `Option ${dropdown.options.length + 1}`,
        color: "#6FB2D2",
      },
    ]
    setDropdown({
      show: true,
      options: newOptions,
    })
  }

  const handleDropdownOptionChange = (e, index) => {
    let value = e.target.value
    let newOptions = [...dropdown.options]
    newOptions[index] = {
      name: value,
      color: newOptions[index].color,
    }

    setDropdown({
      show: dropdown.show,
      options: newOptions,
    })
  }

  const handleDropdownOptionRemove = index => {
    if (dropdown.options.length > 1) {
      let newOptions = dropdown.options.filter((_, i) => i !== index)

      let newState = {
        show: true,
        options: newOptions,
      }

      setDropdown(newState)
    } else {
      toast.error("A dropdown must have atlease one option.", {
        position: "top-right",
      })
    }
  }

  const handleColorPicker = (e, index) => {
    let newOptions = []

    dropdown.options.forEach(ele => {
      newOptions.push({
        name: ele.name,
        color: ele.color,
      })
    })

    let obj = {
      ...newOptions[index],
      color: `#${e.hex}`,
    }
    newOptions[index] = obj

    setDropdown({
      show: dropdown.show,
      options: newOptions,
    })
  }

  return (
    <Modal
      size="lg"
      isOpen={showModal}
      backdrop="static"
      //centered={true}
      scrollable={true}
    >
      <ModalHeader toggle={handleClose} backdrop="static">
        <div>New Custom Field</div>
        <small className="font-size-12 text-secondary">
          Select Fields type, enter details and configure the custom fields.
        </small>
      </ModalHeader>
      <ModalBody>
        <Form
          id="field-form"
          initialValues={{
            ["fieldName"]: data ? data.fieldName : "",
            ["fieldType"]: selectedFieldType,
            ["description"]: data ? data.description : "",
            ["helperText"]: data ? data.helperText : "",
            ["spaceIDs"]: data && selectedSpaceIDs ? selectedSpaceIDs : [],
            ["workspaceIDs"]:
              data && selectedWorkspaceIDs ? selectedWorkspaceIDs : [],
            ["active"]: data ? data.active : true,
          }}
          onFinish={handleSubmit}
        >
          <div className="d-flex justify-content-around mx-3 mb-4">
            {defaultCustomFields.map((field, index) => {
              return (
                <button
                  disabled={editMode}
                  style={{
                    opacity:
                      data && data.fieldType !== field.fieldType ? 0.2 : 1,
                    background: "none",
                    border: "none",
                  }}
                  key={index}
                  onClick={() => handleSelectCustomField(field.fieldType)}
                  className="custom-field m-1"
                >
                  <div
                    className={`custom-field-icon ${
                      field.fieldType === selectedFieldType
                        ? "bg-primary text-white"
                        : ""
                    }`}
                  >
                    <div>
                      <i className={field.icon}></i>
                    </div>
                  </div>
                  <div className="mt-2 font-size-12">{field.fieldType}</div>
                </button>
              )
            })}
          </div>
          <div className="mx-2 mt-5">
            <Row gutter={26}>
              <Col span={12}>
                <Text className="font-size-13 text-black-50" strong>
                  Field Name <span className="text-danger">*</span>
                </Text>
                <Form.Item
                  name="fieldName"
                  rules={[
                    { required: true, message: "Please enter field name" },
                  ]}
                >
                  <Input
                    className="rounded-3 mt-1 py-1"
                    size="middle"
                    placeholder="Enter field name"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="font-size-13 text-black-50" strong>
                  Field Type <span className="text-danger">*</span>
                  <small className="me-2">
                    (Once field type is created, it cannot be changed.)
                  </small>
                </Text>
                <Select
                  disabled={editMode}
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  className="subtask-hover rounded-3 mt-1"
                  style={{
                    height: "33px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select a field type"
                  bordered={false}
                  value={selectedFieldType ? selectedFieldType : null}
                  onChange={handleSelectCustomField}
                  dropdownStyle={{ borderRadius: "6px" }}
                >
                  {defaultCustomFields.map((data, index) => (
                    <Select.Option value={data.fieldType} key={index}>
                      {data.fieldType}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text className="font-size-13 text-black-50" strong>
                  Description
                </Text>
                <Form.Item name="description">
                  <TextArea
                    className="mb-2"
                    style={{
                      borderRadius: "8px",
                    }}
                    placeholder="Add description to this custom field"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={20}>
                <Text className="font-size-13 text-black-50" strong>
                  Helper Text
                </Text>
                <Form.Item name="helperText">
                  <Input
                    className="rounded-3 mt-1 py-1"
                    size="middle"
                    placeholder="Enter helper text"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Text className="font-size-13 text-black-50" strong>
                  Active
                </Text>
                <Form.Item name="active" valuePropName="checked">
                  <Switch size="small" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Collapse
                  defaultActiveKey={["1", "2"]}
                  ghost
                  expandIcon={({ isActive }) => (
                    <i
                      className={`fas fa-caret-right font-size-14 ${
                        isActive ? "bx-rotate-90" : ""
                      }`}
                    />
                  )}
                >
                  {selectedFieldType &&
                  selectedFieldType.includes("Dropdown") ? (
                    <Panel
                      header={
                        <div className="font-size-15 font-weight-semibold">
                          Set Values
                        </div>
                      }
                      key="1"
                    >
                      <div>
                        {dropdown && dropdown.show
                          ? dropdown.options.map((option, index) => {
                              return (
                                <div className="d-flex" key={index}>
                                  <div
                                    className="subtask-hover"
                                    style={{
                                      width: "90%",
                                    }}
                                  >
                                    <Row className="customMargin py-1 ps-1">
                                      <Col span={24}>
                                        <Input
                                          value={option.name}
                                          bordered={false}
                                          onChange={e =>
                                            handleDropdownOptionChange(e, index)
                                          }
                                          size="middle"
                                          placeholder={option.placeholder}
                                          suffix={
                                            <IconButton
                                              disabled={
                                                editMode &&
                                                index < data?.options?.length
                                              }
                                              onClick={() =>
                                                handleDropdownOptionRemove(
                                                  index
                                                )
                                              }
                                              size="small"
                                            >
                                              <i className="fal fa-times font-size-14"></i>
                                            </IconButton>
                                          }
                                        ></Input>
                                      </Col>
                                    </Row>
                                    <div className="custom-border"></div>
                                  </div>
                                  <ColorPicker
                                    onChange={e => {
                                      handleColorPicker(e, index)
                                    }}
                                    palette={palette}
                                    value={option.color}
                                    hideTextfield
                                    key={index}
                                  />
                                </div>
                              )
                            })
                          : ""}
                      </div>
                      {dropdown && dropdown.show ? (
                        <Row className="mt-2 ms-2">
                          <Col>
                            <div
                              onClick={handleAddDropdownOption}
                              className="customBtn p-1 px-2"
                            >
                              <i className="fal fa-plus me-2"></i>
                              <span className="pe-1 font-size-12">
                                Add an option
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </Panel>
                  ) : null}
                  <Panel
                    header={
                      <div className="font-size-15 font-weight-semibold">
                        Set Visibility
                      </div>
                    }
                    key="2"
                  >
                    <div>
                      <div className="font-weight-semibold">
                        Workspace and Space Visibility
                      </div>
                      <small classname="text-secondary">
                        Control Workspace and Space visibility of new custom
                        field
                      </small>
                    </div>
                    <div className="mt-3">
                      <Radio.Group
                        disabled={editMode}
                        value={visibility}
                        buttonStyle="solid"
                        onChange={handleVisibilityRadio}
                      >
                        <Radio value={1}>Workspace level field</Radio>
                        <Radio value={2}>Space level field</Radio>
                      </Radio.Group>
                    </div>
                    <small className="card-title-desc">
                      Once custom field is created, its level cannot be changed
                      at a later stage.
                    </small>

                    {visibility === 1 ? (
                      <div>
                        <div className="mt-4 mb-2">
                          <Checkbox
                            disabled={editMode}
                            checked={isAllWorkspaces}
                            onChange={() =>
                              setIsAllWorkspaces(!isAllWorkspaces)
                            }
                          >
                            Visible in all workspaces
                          </Checkbox>
                        </div>

                        <Row className="mt-2" gutter={26}>
                          <Col span={12}>
                            <Text className="font-size-13 text-black-50" strong>
                              Select Workspaces
                            </Text>
                            <Form.Item name="workspaceIDs">
                              <Select
                                mode="multiple"
                                disabled={isAllWorkspaces || editMode}
                                suffixIcon={
                                  <i className="fas fa-chevron-down"></i>
                                }
                                className="subtask-hover rounded-3 mt-1"
                                style={{
                                  height: "33px",
                                  border: "1px solid #d9d9d9",
                                  borderRadius: "4px",
                                  width: "100%",
                                }}
                                placeholder="Select one or more workspaces"
                                bordered={false}
                                dropdownStyle={{ borderRadius: "6px" }}
                              >
                                {workspaces?.map((workspace, index) => (
                                  <Select.Option
                                    value={workspace.id}
                                    key={index}
                                  >
                                    {workspace.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <div className="mt-4 mb-2">
                          <Checkbox
                            disabled={editMode}
                            checked={isAllSpaces}
                            onChange={() => setIsAllSpaces(!isAllSpaces)}
                          >
                            Visible in all spaces
                          </Checkbox>
                        </div>

                        <Row className="mt-2" gutter={26}>
                          <Col span={12}>
                            <Text className="font-size-13 text-black-50" strong>
                              Select Spaces
                            </Text>
                            <Form.Item name="spaceIDs">
                              <Select
                                mode="multiple"
                                disabled={isAllSpaces || editMode}
                                suffixIcon={
                                  <i className="fas fa-chevron-down"></i>
                                }
                                className="subtask-hover rounded-3 mt-1"
                                style={{
                                  height: "33px",
                                  border: "1px solid #d9d9d9",
                                  borderRadius: "4px",
                                  width: "100%",
                                }}
                                placeholder="Select one or more spaces"
                                bordered={false}
                                dropdownStyle={{ borderRadius: "6px" }}
                              >
                                {spaces?.map((space, index) => (
                                  <Select.Option value={space.id} key={index}>
                                    {space.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div
          onClick={handleClose}
          className="btn btn-outline-secondary rounded-3"
        >
          Cancel
        </div>
        <button
          className="btn btn-success rounded-3"
          type="submit"
          form="field-form"
        >
          {editMode ? "Update" : "Create"}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default FieldModal
