import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Row, Col, Form, Typography, Input, Select } from "antd"

const { Text } = Typography

const AllocationProfileModal = ({
  showModal,
  handleClose,
  editMode,
  workspaces,
  spaces,
  onSave,
  onUpdate,
  data,
  onWorkspaceChange,
}) => {
  const [mySpaces, setMySpaces] = useState(undefined)

  useEffect(() => {
    if (editMode && data) {
      onWorkspaceChange(data.workspace.id)
      setMySpaces(data.spaces)
    }
  }, [data])

  const handleValidSubmit = values => {
    if (editMode) {
      onUpdate(data.id, values)
    } else {
      onSave(values)
    }
  }

  const handleWorkspaceChange = value => {
    setMySpaces(undefined)
    onWorkspaceChange(value)
  }

  return (
    <Modal
      isOpen={showModal}
      centered={true}
      toggle={handleClose}
      backdrop="static"
    >
      <Form
        initialValues={{
          ["name"]: data ? data.name : "",
          ["workspaceID"]: data ? data.workspace.id : undefined,
          ["spaceIDs"]: data ? data.spaces.map(space => space.id) : undefined,
        }}
        onFinish={handleValidSubmit}
      >
        <ModalHeader tag="h4" toggle={handleClose}>
          {editMode ? "Update" : "New"} Allocation Profile
        </ModalHeader>
        <ModalBody>
          {/* Name */}
          <Row className="mb-1">
            <Col span={24}>
              <Text className="font-size-12 text-black-50" strong>
                Name <span className="text-danger">*</span>
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input
                  className="rounded-3"
                  size="middle"
                  placeholder="Enter name"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mb-1" gutter={26}>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Select Workspace <span className="text-danger">*</span>
              </Text>
            </Col>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Select Spaces <span className="text-danger">*</span>
              </Text>
            </Col>
          </Row>
          <Row gutter={26}>
            {/* Workspace */}
            <Col span={12}>
              <Form.Item
                // noStyle={true}
                style={{
                  borderRadius: "1rem",
                }}
                name="workspaceID"
                rules={[
                  { required: true, message: "Please select a wokspace" },
                ]}
              >
                <Select
                  className="rounded-3"
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  style={{
                    height: "33px",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select a wokspace"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                  onChange={handleWorkspaceChange}
                >
                  {workspaces
                    ? workspaces.map(workspace => {
                        return (
                          <Select.Option
                            key={workspace.id}
                            value={workspace.id}
                          >
                            {workspace.name}
                          </Select.Option>
                        )
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>

            {/* Spaces - multiple */}
            <Col span={12}>
              <Form.Item
                name="spaceIDs"
                rules={[
                  {
                    required: true,
                    message: "Please select one or more spaces",
                    type: "array",
                  },
                ]}
              >
                <Select
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  mode="multiple"
                  className="subtask-hover rounded-3"
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select one or more spaces"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                >
                  {spaces
                    ? spaces.map(space => {
                        return (
                          <Select.Option key={space.id} value={space.id}>
                            {space.name}
                          </Select.Option>
                        )
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div
            className="btn btn-outline-secondary rounded-3"
            onClick={handleClose}
          >
            Cancel
          </div>
          <button type="submit" className="btn btn-success rounded-3">
            {editMode ? "Update" : "Save"}
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default AllocationProfileModal
