import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Select, Row, Col, Divider, Form } from "antd"
import SingleFilter from "./singleFilter"
import toast from "react-hot-toast"
const { Option } = Select

const TaskFilterModal = ({
  showModal,
  handleCloseModal,
  customFields,
  handleAddTaskFilter,
  handleUpdateTaskFilter,
  handleDeleteTaskFilter,
  taskFilter,
  users,
}) => {
  const [fields, setFields] = useState(null)
  useEffect(() => {
    let userOptions = []
    users?.forEach(ele => {
      userOptions.push({
        id: ele.id,
        name: ele.fName,
      })
    })
    let defaultFields = [
      {
        id: "default_status_id",
        fieldType: "Dropdown",
        fieldName: "Status",
        options: [
          {
            color: "",
            name: "Completed",
          },
          {
            color: "",
            name: "Pending",
          },
          {
            color: "",
            name: "Delay",
          },
          {
            color: "",
            name: "In Progress",
          },
        ],
      },
      {
        id: "assignee_user_id",
        fieldType: "Dropdown Multi",
        fieldName: "Assignee",
        options: userOptions,
      },
      /* {
        id: "default_start_date",
        fieldType: "Date",
        fieldName: "Start Date",
      },
      {
        id: "default_end_date",
        fieldType: "Date",
        fieldName: "End Date",
      }, */

      // {
      //   id: "default_start_date_id",
      //   fieldType: "Date",
      //   fieldName: "Start Date",
      // },
    ]

    let dropdownFields = []
    customFields?.forEach(field => {
      if (field.fieldType.includes("Dropdown")) {
        dropdownFields.push(field)
      }
    })

    if (customFields) {
      setFields([...defaultFields, ...dropdownFields])
    } else {
      setFields([...dropdownFields])
    }
  }, [customFields])

  const [filters, setFilters] = useState(null)
  const [datas, setDatas] = useState(null)
  const [whereCondition, setWhereCondition] = useState("or")
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (taskFilter) {
      setFilters(taskFilter.filters)
      setDatas(taskFilter.filters)
      setWhereCondition(taskFilter.where)
      setEditMode(true)
    } else {
      setFilters(null)
      setDatas(null)
      setWhereCondition("or")
      setEditMode(false)
    }
  }, [taskFilter])
  useEffect(() => {
    if (!filters || !filters?.length) {
      let newFilter = {
        type: null,
        condition: null,
        options: null,
      }
      setFilters([newFilter])
      setDatas([newFilter])
    }
  }, [filters])

  const handleWhereConditionChange = value => {
    setWhereCondition(value)
  }

  const handleAddFilters = () => {
    if (filters?.length) {
      let lastFilter = filters[filters.length - 1]
      if (
        !lastFilter.type ||
        !lastFilter.condition ||
        !lastFilter.options?.length
      ) {
        toast.error("Please fill the empty filter to add new filters")
        return
      }
    }

    let newFilter = {
      type: null,
      condition: null,
      options: null,
    }
    if (filters) {
      setFilters([...filters, newFilter])
      setDatas([...filters, newFilter])
    } else {
      setFilters([newFilter])
      setDatas([newFilter])
    }
  }

  const handleDeleteFilter = index => {
    if (filters.length > 1) {
      filters.splice(index, 1)
      setFilters([...filters])
    } else {
      toast.error("Atleast one filter is required")
    }
  }

  const handleFilterTypeChange = (index, type) => {
    datas[index].type = type
    setDatas(datas)
  }
  const handleFilterConditionChange = (index, condition) => {
    datas[index].condition = condition
    setDatas(datas)
  }
  const handleFilterOptionsChange = (index, options) => {
    datas[index].options = options
    console.log("datas", datas)
    setDatas(datas)
  }
  const handleFilterDateChange = (index, isDateRange, startDate, endDate) => {
    datas[index].startDate = startDate
    if (isDateRange) {
      datas[index].endDate = endDate
    }
    console.log("datas", datas)
    setDatas(datas)
  }

  const handleSave = () => {
    let taskFilterInput = {
      where: whereCondition,
      filters: datas,
    }
    console.log("SUBMIT")
    if (editMode) {
      handleUpdateTaskFilter(taskFilterInput)
    } else {
      handleAddTaskFilter(taskFilterInput)
    }
  }

  return (
    <Modal isOpen={showModal} centered={true} backdrop="static" size="lg">
      <ModalHeader
        toggle={() => {
          setFilters(null)
          handleCloseModal()
        }}
        tag="h4"
      >
        Filters
      </ModalHeader>
      <ModalBody>
        {filters
          ? filters.map((filter, index) => {
              return (
                <SingleFilter
                  key={index}
                  customFields={fields}
                  filter={filter}
                  index={index}
                  whereCondition={whereCondition}
                  handleWhereConditionChange={handleWhereConditionChange}
                  handleDeleteFilter={handleDeleteFilter}
                  handleFilterTypeChange={handleFilterTypeChange}
                  handleFilterConditionChange={handleFilterConditionChange}
                  handleFilterOptionsChange={handleFilterOptionsChange}
                  handleFilterDateChange={handleFilterDateChange}
                />
              )
            })
          : null}

        {/* <Divider /> */}
        <div
          className="nobg-btn px-2 py-1 cursor-pointer rounded-3 mt-3"
          style={{ width: "fit-content" }}
          onClick={handleAddFilters}
        >
          <i className="fal fa-plus me-1 font-weight-semibold font-size-10"></i>
          <span className="font-weight-semibold">Add filter</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          className="btn btn-outline-secondary rounded-3"
          onClick={() => {
            setFilters(null)
            handleCloseModal()
          }}
        >
          Cancel
        </div>
        <div
          hidden={!editMode}
          className="btn btn-danger rounded-3"
          onClick={() => {
            handleDeleteTaskFilter(taskFilter.id)
          }}
        >
          Clear
        </div>
        <div onClick={handleSave} className="btn btn-success rounded-3">
          {editMode ? "Update" : "Save"}
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default TaskFilterModal
