import React, { useState, useEffect } from "react"
import { Input } from "antd"
const { TextArea } = Input

const TableTextAreaField = ({
  customField,
  record,
  value,
  updateCustomField,
}) => {
  const [textAreaValue, setTextAreaValue] = useState(undefined)

  useEffect(() => {
    if (value && value !== "") {
      setTextAreaValue(value)
    } else {
      setTextAreaValue(undefined)
    }
  }, [value])

  const handleInlineTextAreaFieldChange = event => {
    setTextAreaValue(event.target.value)
  }

  const handleInlineTextAreaFieldBlur = () => {
    if (
      textAreaValue !== undefined &&
      textAreaValue !== null &&
      textAreaValue !== value
    ) {
      let customText = `Updated ${customField.fieldName}`
      if (value !== "") {
        customText += ` from "${value}" to "${textAreaValue}"`
      } else {
        customText += ` to "${textAreaValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      updateCustomField({
        variables: {
          id: customField.id,
          taskID: record.id,
          value: textAreaValue,
          auditInput,
        },
      })
    }
  }

  return (
    <TextArea
      style={
        {
          // width: "150px",
        }
      }
      rows={2}
      className="textareadHover mb-2"
      value={textAreaValue ? textAreaValue : undefined}
      placeholder={customField.fieldName}
      onMouseLeave={handleInlineTextAreaFieldBlur}
      onChange={handleInlineTextAreaFieldChange}
      // onBlur={handleInlineTextAreaFieldBlur}
    />
  )
}

export default TableTextAreaField
