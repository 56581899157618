import React from "react"

// Ant design
import { Row, Col } from "antd"

// Material Icon
import AddIcon from "@material-ui/icons/Add"

import { ADD_SUB_TASK, ADD_AUDIT } from "../../../graphql/mutation"
import { useMutation } from "@apollo/client"

const AddSubTask = ({
  parentID,
  updateMainTaskList,
  handleChangeDrawerDatas,
  customFields,
  spaceID,
}) => {
  // SECTION APIS
  const [addSubTask] = useMutation(ADD_SUB_TASK, {
    onCompleted: data => {
      if (data && data.addSubTask) {
        let newSubtask = data.addSubTask
        if (newSubtask.isSubtask) {
          handleChangeDrawerDatas(data.addSubTask)
        } else {
          updateMainTaskList(data.addSubTask)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const handleAddSubtask = () => {
    let subTaskInput = {
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      status: "",
      isSubtask: true,
      parentID,
      spaceID,
      customFieldValues: [],
    }
    if (customFields && customFields.length) {
      customFields.forEach(customField => {
        subTaskInput.customFieldValues.push({
          custFieldID: customField.id,
          value: "",
        })
      })
    }
    addSubTask({
      variables: {
        input: subTaskInput,
      },
    })
  }

  return (
    <Row className="p-2 px-4 mb-3">
      <Col>
        <div onClick={handleAddSubtask} className="customBtn mb-2">
          <AddIcon className="pe-1" />
          <span className="pe-1">Add subtask</span>
        </div>
      </Col>
    </Row>
  )
}

export default AddSubTask
