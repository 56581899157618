import React, { useState, useEffect } from "react"
import { Select, Row, Col, Divider, Form, DatePicker } from "antd"
const { Option } = Select
const { RangePicker } = DatePicker
import moment from "moment"
import { Label } from "reactstrap"

const SingleFilter = ({
  whereCondition,
  handleWhereConditionChange,
  customFields,
  filter,
  index,
  handleDeleteFilter,
  handleFilterTypeChange,
  handleFilterConditionChange,
  handleFilterOptionsChange,
  handleFilterDateChange,
}) => {
  const [selectedField, setSelectedField] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [selectedCondition, setSelectedCondition] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [isDateField, setIsDateField] = useState(false)
  const [isDateRange, setIsDateRange] = useState(false)

  useEffect(() => {
    if (filter && filter.type) {
      console.log("filter", filter)
      setSelectedType(filter.type)
      setSelectedCondition(filter.condition)
      setSelectedOptions(filter.options?.map(String))

      let field = customFields?.find(field => field.id === filter.type)
      setSelectedField(field)
      setOptions(field?.options)
    } else {
      setSelectedType(null)
      setSelectedCondition(null)
      setSelectedOptions([])
    }
  }, [filter])

  const [options, setOptions] = useState(null)

  const handleTypeChange = value => {
    setSelectedType(value)
    handleFilterTypeChange(index, value)

    let field = customFields.find(field => field.id === value)
    setIsDateField(false)
    setSelectedField(field)
    if (field.fieldType.includes("Dropdown")) {
      setOptions(field.options)
    } else if (field.fieldType.includes("Date")) {
      setIsDateField(true)
    } else {
      setOptions(null)
    }
  }

  const handleOptionsChange = values => {
    let options = values
    if (selectedField.fieldType === "Dropdown") {
      options = [values]
    }
    console.log("options", options)
    setSelectedOptions(values)
    handleFilterOptionsChange(index, options)
  }

  const handleConditionChange = value => {
    setSelectedCondition(value)
    handleFilterConditionChange(index, value)
  }

  const handleDateChange = dates => {
    const startDateStr =
      dates && dates[0] !== "" ? moment.utc(dates[0]).format("DD-MM-YYYY") : ""
    const endDateStr =
      dates && dates[1] !== "" ? moment.utc(dates[1]).format("DD-MM-YYYY") : ""
    console.log("dates", startDateStr, endDateStr)
    handleFilterDateChange(index, isDateRange, startDateStr, endDateStr)
  }

  return (
    <Row
      align="middle"
      gutter={12}
      className="mb-3"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
      }}
    >
      {index === 0 ? (
        <Col>
          <div className="me-4 font-size-14">Where</div>
        </Col>
      ) : (
        <Col>
          <Select
            suffixIcon={<i className="fas fa-chevron-down"></i>}
            className="subtask-hover rounded-3"
            style={{
              height: "32px",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              width: "75px",
            }}
            disabled={index > 1}
            value={whereCondition}
            onChange={handleWhereConditionChange}
            bordered={false}
            dropdownStyle={{ borderRadius: "6px" }}
          >
            <Option value="and">And</Option>
            <Option value="or">Or</Option>
          </Select>
        </Col>
      )}

      <Col>
        <Select
          suffixIcon={<i className="fas fa-chevron-down"></i>}
          className="subtask-hover rounded-3"
          style={{
            height: "32px",
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            width: "150px",
            marginLeft: index === 0 && "6px",
          }}
          placeholder="Select filter"
          bordered={false}
          value={selectedType}
          dropdownStyle={{ borderRadius: "6px" }}
          onChange={handleTypeChange}
        >
          {customFields
            ? customFields.map(field => {
                return (
                  <Option key={field.id} value={field.id}>
                    {field.fieldName}
                  </Option>
                )
              })
            : null}
        </Select>
      </Col>
      <Col>
        <Select
          suffixIcon={<i className="fas fa-chevron-down"></i>}
          className="subtask-hover rounded-3"
          style={{
            height: "32px",
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            width: "150px",
          }}
          value={selectedCondition}
          onChange={handleConditionChange}
          bordered={false}
          dropdownStyle={{ borderRadius: "6px" }}
        >
          <Option value="is">Is</Option>
          <Option value="isNot">Is not</Option>
        </Select>
      </Col>

      <Col span={10}>
        {isDateField ? (
          <div>
            {isDateRange ? (
              <RangePicker
                style={{
                  borderRadius: "6px",
                }}
                ranges={{
                  "Last Month": [
                    moment().subtract(1, "months").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last Week": [
                    moment().subtract(1, "weeks").startOf("week"),
                    moment().subtract(1, "weeks").endOf("week"),
                  ],
                  "This Week": [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "This Year": [
                    moment().startOf("year"),
                    moment().endOf("year"),
                  ],
                }}
                format="MMM D, YYYY"
                // defaultValue={[moment().subtract(30, "days"), moment()]}
                // value={[
                //   startDate ? moment(startDate, "YYYY-MM-DD") : "",
                //   endDate ? moment(endDate, "YYYY-MM-DD") : "",
                // ]}
                onChange={handleDateChange}
              />
            ) : (
              <DatePicker
                style={{
                  borderRadius: "6px",
                  width: "100%",
                }}
                onChange={handleDateChange}
              />
            )}
            <Col>
              <input
                type="checkbox"
                style={{ cursor: "pointer" }}
                className="form-check-input"
                checked={isDateRange}
                onChange={() => setIsDateRange(!isDateRange)}
              />
              <Label className="form-check-label text-secondary font-size-12 ms-1">
                Date Range
              </Label>
            </Col>
          </div>
        ) : (
          <Select
            mode={
              selectedField?.fieldType.includes("Dropdown Multi")
                ? "multiple"
                : ""
            }
            className="subtask-hover rounded-3"
            style={{
              height: "32px",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              width: "100%",
            }}
            onChange={handleOptionsChange}
            value={selectedOptions}
            placeholder="Select option"
            bordered={false}
            dropdownStyle={{ borderRadius: "6px" }}
          >
            {options
              ? options.map((option, index) => {
                  let id = index.toString()
                  if (option.id) {
                    id = option.id
                  }
                  return (
                    <Option key={index} value={id}>
                      {option.name}
                    </Option>
                  )
                })
              : null}
          </Select>
        )}
      </Col>
      <Col>
        <div
          className="nobg-btn px-2 py-1 rounded-3 cursor-pointer"
          onClick={() => handleDeleteFilter(index)}
        >
          <i className="fal fa-times font-size-14"></i>
        </div>
      </Col>
    </Row>
  )
}

export default SingleFilter
