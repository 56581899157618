import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.png"
import { AvForm, AvField } from "availity-reactstrap-validation"

import firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/firestore"

const NewForgotPassword = () => {
  const [error, setError] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)

  const [loading, setLoading] = useState(null)

  const handleValidSubmit = values => {
    setLoading(true)
    console.log("values", values)

    firebase
      .auth()
      .sendPasswordResetEmail(values.email, {
        url: window.location.protocol + "//" + window.location.host + "/login",
      })
      .then(data => {
        setSuccessMsg(
          "Reset password link has been sent to your email address."
        )
        setLoading(false)
      })
      .catch(error => {
        console.log("error", error)
        setError(error.message)
        setLoading(false)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password | Skote - React Admin & Dashboard Template
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "99",
              textAlign: "center",
            }}
          >
            <Card hidden={!loading} className="shadow-sm">
              <CardBody>
                <p>Sending Email</p>
                <Spinner color="primary" />
              </CardBody>
            </Card>
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password</h5>
                        <p>Reset Password for Concize</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    ) : null}
                    {successMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {successMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                            disabled={loading}
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewForgotPassword
