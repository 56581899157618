import React, { useState, useEffect } from "react"
import { Select } from "antd"

// import { GET_CUSTOM_FIELD } from "../../../graphql/queries"
import { useLazyQuery, useMutation } from "@apollo/client"
import toast from "react-hot-toast"

const { Option } = Select

const Widgetfield = ({
  columns,
  visualData,
  handleUpdateFilter,
  filter,
  filters,
}) => {
  const [fieldID, setFieldID] = useState(undefined)
  const [type, setType] = useState(undefined)
  const [values, setValues] = useState(undefined)

  const [options, setOptions] = useState(undefined)

  useEffect(() => {
    if (filter) {
      setFieldID(filter.fieldID)
      setType(filter.type)
      setValues(filter.values)
      let options = []
      visualData?.forEach(data => {
        if (data.fieldID === filter.fieldID) {
          options.push(data)
        }
      })
      setOptions(options)
    } else {
      setFieldID(undefined)
      setType(undefined)
      setValues(undefined)
    }
  }, [filter])

  //   SECTION APIS
  /* const [getCustomField] = useLazyQuery(GET_CUSTOM_FIELD, {
    onCompleted: data => {
      if (data && data.getCustomField) {
        setOptions(data.getCustomField.options)
      }
    },
    onError: error => {
      console.log("error", error)
    },
  }) */

  function getValuesByID(fieldID) {
    if (fieldID === "default_status_id") {
      let arr = ["Completed", "Pending", "Delay"]
      setOptions(arr)
    } else {
      columns.forEach(col => {
        if (col.id === fieldID) {
          setOptions(col.options)
          return
        }
      })
      /* getCustomField({
        variables: {
          fieldID,
        },
      }) */
    }
  }

  const handlefieldChange = fieldID => {
    // filters.forEach(f => {
    //   if (f.fieldID === fieldID) {
    //     toast.error(
    //       "Filter for this field is already applied.\nPlease select a different field"
    //     )

    //   }
    // })

    if (fieldID) {
      setFieldID(fieldID)
      getValuesByID(fieldID)
      handleUpdateFilter(fieldID, type, values)
    } else {
      setFieldID(undefined)
      setOptions(undefined)
    }
  }

  const handleTypeChange = type => {
    setType(type)
    handleUpdateFilter(fieldID, type, values)
  }

  const handleValueChange = values => {
    setValues(values)
    handleUpdateFilter(fieldID, type, values)
  }

  return (
    <div className="d-flex justify-content-around">
      <Select
        showSearch
        allowClear
        placeholder="Select field"
        style={{
          height: "34px",
          border: "1px solid #d9d9d9",
          borderRadius: "6px",
          width: "100%",
        }}
        value={fieldID ? fieldID : null}
        className="me-3"
        onChange={handlefieldChange}
        bordered={false}
        dropdownStyle={{ borderRadius: "6px" }}
      >
        {columns &&
          columns.map((col, i) => {
            let duplicate = filters.some(f => f.fieldID === col.id)
            console.log("col---->", col)
            return (
              <Option key={i} value={col.id} disabled={duplicate}>
                {col.fieldName} {col.spaceName ? ` (${col.spaceName})` : ""}
              </Option>
            )
          })}
      </Select>
      <Select
        showSearch
        allowClear
        placeholder="Select Type"
        style={{
          height: "34px",
          border: "1px solid #d9d9d9",
          borderRadius: "6px",
          width: "100%",
        }}
        className="me-3"
        value={type ? type : null}
        onChange={handleTypeChange}
        bordered={false}
        dropdownStyle={{ borderRadius: "6px" }}
      >
        <Option key="contains" value="0">
          contains
        </Option>
        <Option key="notcontains" value="1">
          not contains
        </Option>
      </Select>
      <Select
        mode="multiple"
        showSearch
        allowClear
        placeholder="Select Value"
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "6px",
          width: "100%",
        }}
        className="me-3"
        value={values ? values : []}
        onChange={handleValueChange}
        bordered={false}
        dropdownStyle={{ borderRadius: "6px" }}
      >
        {options
          ? options.map((option, index) => {
              return (
                <Option key={index} value={String(index)}>
                  {option.name}
                </Option>
              )
            })
          : null}
      </Select>
    </div>
  )
}

export default Widgetfield
