import React, { useState, useEffect } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/annotations")(Highcharts)

const DonutChart = ({
  datas,
  width,
  height,
  onDashboard,
  handleChangeSelectedData,
}) => {
  const defaultOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    exporting: {
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "horizontal",
      alignColumns: false,
    },
    tooltip: {
      pointFormat: "<b>{point.y}</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y}",
        },
        showInLegend: true,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 800,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
              x: 0,
              alignColumns: true,
              labelFormat: "<b>{name}</b>({y})",
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
            plotOptions: {
              pie: {
                allowPointSelect: false,
                cursor: "pointer",
                dataLabels: {
                  enabled: false,
                },
                point: {
                  events: {
                    legendItemClick: function (e) {
                      if (onDashboard) {
                        return true
                      }
                      e.preventDefault()
                      handleChangeSelectedData(e.target.options)
                      // setSelectedData(e.target.options)
                      return false
                    },
                  },
                },
                showInLegend: true,
              },
            },
          },
        },
      ],
    },
  }

  const [options, setOptions] = useState(defaultOptions)

  useEffect(() => {
    if (datas) {
      let seriesData = []
      datas.forEach(ele => {
        seriesData.push({
          fieldID: ele.fieldID,
          name: ele.name,
          y: ele.count,
          color: ele.color,
        })
      })
      let series = [
        {
          colorByPoint: true,
          data: seriesData,
          innerSize: "50%",
        },
      ]
      let newOptions = {
        ...options,
        chart: {
          ...options.chart,
          width: width * 100,
          height: height * 100,
        },
        series,
      }
      setOptions(newOptions)
    }
  }, [datas, width, height])

  return (
    <div
      style={{
        margin: "0 auto",
        // overflow: "hidden",
      }}
      className="h-100 d-flex align-items-center"
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default DonutChart
