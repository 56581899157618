import React from "react"
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Col,
  Row,
} from "reactstrap"
import { CSVDownloader } from "react-papaparse"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

const CustomErrorHandlingModal = ({
  showCustomDialog,
  setShowCustomDialog,
  errorDataObjs,
  errorDataColumns,
  title,
  subText,
}) => {
  const pageOptions = {
    sizePerPage: 10,
    totalSize: errorDataObjs.length,
    custom: true,
  }

  return (
    <Modal
      style={{
        width: "100%",
      }}
      isOpen={showCustomDialog}
      size="xl"
      backdrop="static"
    >
      <ModalHeader tag="h4" toggle={() => setShowCustomDialog(false)}>
        {title}
      </ModalHeader>
      <ModalBody>
        <p className="w-50">
          <b>Note:</b> {subText}
        </p>

        <CSVDownloader
          data={errorDataObjs}
          type="button"
          filename={"unsuccessfull_user_data"}
          bom={true}
          className="btn btn-sm btn-outline-primary mb-3 rounded-3"
        >
          <i className="fas fa-download me-2"></i>
          Download
        </CSVDownloader>

        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          columns={errorDataColumns}
          data={errorDataObjs}
          keyField="fName"
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="fName"
              data={errorDataObjs}
              columns={errorDataColumns}
              bootstrap4
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          keyField="fName"
                          classes={
                            "table align-middle table-nowrap table-hover"
                          }
                          bordered={false}
                          striped={false}
                          responsive
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded justify-content-end mb-2">
                      <PaginationListStandalone {...paginationProps} />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </ModalBody>
      <ModalFooter>
        <div
          onClick={() => setShowCustomDialog(false)}
          className="btn btn-outline-secondary"
        >
          Close
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CustomErrorHandlingModal
