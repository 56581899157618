import React, { useState } from "react"
import "react-quill/dist/quill.snow.css"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import ReactQuill from "react-quill"

function NoteModal({ currentData, showNoteModal, handleClose, onNotesSave }) {
  const [note, setNote] = useState(undefined)

  const handleNoteChange = e => {
    setNote(e)
  }

  const handleSave = () => {
    let newObj = { ...currentData }
    newObj.notes = note
    onNotesSave(currentData.id, newObj)
  }

  return (
    <Modal isOpen={showNoteModal} toggle={handleClose} backdrop="static">
      <ModalHeader tag="h4" toggle={handleClose}>
        {currentData && currentData.workspace !== ""
          ? `Add notes to ${currentData.workspace}`
          : `Add notes to workspace`}
      </ModalHeader>
      <ModalBody>
        <ReactQuill
          defaultValue={
            currentData && currentData.notes ? currentData.notes : null
          }
          placeholder="Enter notes"
          onChange={handleNoteChange}
          theme="snow"
        />
      </ModalBody>
      <ModalFooter>
        <div onClick={handleClose} className="btn btn-outline-secondary">
          Cancel
        </div>
        <div onClick={handleSave} className="btn btn-success">
          Save
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default NoteModal
