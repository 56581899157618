import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row, Spinner, Badge } from "reactstrap"

import Breadcrumb from "../../components/Common/Breadcrumb"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import CampaignModal from "./components/campaignModal"

import {
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
} from "../../graphql/mutation"
import { CAMPAIGNS, WORKSPACES } from "../../graphql/queries"
import { useQuery, useMutation } from "@apollo/client"
import moment from "moment"
import ConfirmDeleteModal from "components/Dialog/confirmDeleteModal"

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([])
  const [campaignData, setCampaignData] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [workspaces, setWorkspaces] = useState(undefined)

  const {} = useQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.campaigns) {
        setCampaigns(data.campaigns)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const {} = useQuery(WORKSPACES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data) {
        setWorkspaces(data.workspaces)
      }
    },
  })

  const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
    onCompleted: data => {
      if (data && data.createCampaign) {
        if (campaigns && campaigns.length) {
          setCampaigns([...campaigns, data.createCampaign])
        } else {
          setCampaigns([data.createCampaign])
        }
      }
      setShowModal(false)
    },
    onError: err => {
      console.log("err", err)
      setShowModal(false)
    },
  })

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    onCompleted: data => {
      if (data && data.updateCampaign) {
        let index = campaigns.findIndex(campaign => {
          return campaign.id === data.updateCampaign.id
        })
        let newCampaigns = [
          ...campaigns.slice(0, index),
          { ...data.updateCampaign },
          ...campaigns.slice(index + 1),
        ]
        setCampaigns(newCampaigns)
      }
      setShowModal(false)
    },
    onError: err => {
      console.log("err", err)
      setShowModal(false)
    },
  })

  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
    onCompleted: data => {
      if (data && data.deleteCampaign) {
        let index = campaigns.findIndex(campaign => {
          return campaign.id === campaignData.id
        })
        let newCampaigns = [...campaigns]
        newCampaigns.splice(index, 1)
        setCampaigns(newCampaigns)
      }
      setShowDeleteModal(false)
    },
    onError: err => {
      console.log("err", err)
      setShowDeleteModal(false)
    },
  })

  function onSave(input) {
    createCampaign({
      variables: {
        input,
      },
    })
  }
  function onUpdate(id, input) {
    updateCampaign({
      variables: {
        id,
        input,
      },
    })
  }

  function onDelete() {
    if (campaignData.id) {
      deleteCampaign({
        variables: {
          id: campaignData.id,
        },
      })
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setEditMode(false)
    setCampaignData(undefined)
  }

  const handleUpdateCampaign = input => {
    console.log("input", input)
    setCampaignData(input)
    setEditMode(true)
    setShowModal(true)
  }

  const handleDeleteCampaign = campaign => {
    setCampaignData(campaign)
    setShowDeleteModal(true)
  }

  const campaignColumnList = [
    {
      text: "Campaign Id",
      dataField: "id",
      sort: true,
      formatter: (_, campaign) => {
        return <div>{campaign.id.slice(0, 5)}</div>
      },
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
    },
    {
      text: "Start Date",
      dataField: "startDate",
      sort: true,
      formatter: (_, campaign) => {
        if (campaign.startDate !== "") {
          return <div>{moment(campaign.startDate).format("MMMM Do YYYY")}</div>
        }
      },
    },
    {
      text: "End Date",
      dataField: "endDate",
      sort: true,
      formatter: (_, campaign) => {
        if (campaign.endDate === "") {
          return <div>No End Date</div>
        } else {
          return <div>{moment(campaign.endDate).format("MMMM Do YYYY")}</div>
        }
      },
    },
    {
      text: "Evergreen",
      dataField: "isEvergreen",
      sort: true,
      formatter: (_, campaign) => {
        if (campaign.isEvergreen) {
          return (
            <Badge pill className="badge-soft-success me-1 font-size-12">
              Active
            </Badge>
          )
        } else {
          return (
            <Badge pill className="badge-soft-secondary me-1 font-size-12">
              Inactive
            </Badge>
          )
        }
      },
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, campaign) => (
        <div className="d-flex gap-3">
          <div
            className="text-success"
            onClick={() => handleUpdateCampaign(campaign)}
            style={{ cursor: "pointer" }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </div>
          <div
            className="text-danger d-none"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteCampaign(campaign)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </div>
        </div>
      ),
    },
  ]
  const { SearchBar } = Search

  const pageOptions = {
    sizePerPage: 10,
    totalSize: campaigns.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          overflow: "scroll",
        }}
      >
        <MetaTags>
          <title>Concize - Campaigns</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            left="Campaigns"
            url="/settings"
            urlTitle="Settings"
            right="Campaigns"
          />
          <Row>
            <Col lg="12">
              <Card style={{ position: "relative" }}>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={campaigns}
                    columns={campaignColumnList}
                    keyField="name"
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="name"
                        data={campaigns}
                        columns={campaignColumnList}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end d-flex justify-content-end">
                                  <button
                                    className="font-16 btn-block btn btn-primary rounded-3 me-2"
                                    onClick={() => {
                                      setCampaignData(undefined)
                                      setEditMode(false)
                                      setShowModal(true)
                                    }}
                                  >
                                    <i className="fas fa-plus-circle me-2" />
                                    Create Campaign
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    keyField="name"
                                    // selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CampaignModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        editMode={editMode}
        onSave={onSave}
        onUpdate={onUpdate}
        data={campaignData}
        workspaces={workspaces}
      />

      <ConfirmDeleteModal
        showDialog={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
        title={`Delete Campaign`}
        subText={`Are you sure you want to delete the "${campaignData?.name}" campaign?`}
      ></ConfirmDeleteModal>
    </React.Fragment>
  )
}

export default Campaigns
