import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useContext } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useMutation, useLazyQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"
import { getFirebaseBackend } from "../../helpers/firebase_helper"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.png"

//Import config
import { facebook, google } from "../../config"

import { SIGN_IN } from "../../graphql/mutation"
import { ROLE } from "components/helpers/enums"
import { GET_AUDIT_NOMENCLATURE } from "../../graphql/queries"

const Login = props => {
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const [showError, setShowError] = useState(false)

  const { loading, error } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  const history = useHistory()

  const updateCache = (cache, { data }) => {
    let role = ""
    if (data && data.signIn) {
      localStorage.setItem("concizeUser", JSON.stringify(data.signIn))
      role = data.signIn.role
    }

    // console.log(data.signIn.token)
    /*console.log(data)
    let obj = JSON.parse(localStorage.getItem("authUser"))
    if (obj !== null && obj !== "null" && loading === false) {
      obj.stsTokenManager.accessToken = data.signIn.token
      localStorage.setItem("authUser", JSON.stringify(obj))
    }*/
    // Refresh the token to get the one with Custom Claims
    const fireBaseBackend = getFirebaseBackend()
    fireBaseBackend
      .loginWithCustomToken(data.signIn.token)
      .then(firebaseData => {
        if (data && data.signIn && data.signIn.lastWorkspace) {
          let lastWorkspace = data.signIn.lastWorkspace
          history.push(`${lastWorkspace.id}/my-tasks`)
        } else {
          history.push("/profile")
        }
        // console.log("data---->", data)
        // Connect to websocket
        // let ws = new WebSocket(
        //   `wss://abcm1nw004.execute-api.ap-south-1.amazonaws.com/dev?userID=${data.signIn.id}`
        // )

        /* fireBaseBackend.refreshToken(true).then(data => {
          console.log("Token force refreshed", data)
          history.push("/dashboard")
  
        });*/
      })
  }

  const [signInToConcizeDB, { signInData, signInloading, signErrerror }] =
    useMutation(SIGN_IN, {
      update: updateCache,
    })

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
    if (error) {
      console.log("error--->", error)
      setShowLoader(false)
      setShowError(true)
    }
  }

  const [getAuditNomenclature] = useLazyQuery(GET_AUDIT_NOMENCLATURE, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getAuditNomenclature) {
        let auditNomenclatureMap = new Map()
        for (const el of data.getAuditNomenclature) {
          auditNomenclatureMap.set(el.type, el)
        }
        localStorage.setItem(
          "auditNomenClatureData",
          JSON.stringify(Object.fromEntries(auditNomenclatureMap))
        )
      }
    },
  })

  useEffect(() => {
    let obj = JSON.parse(localStorage.getItem("authUser"))
    if (obj !== null && obj !== "null" && loading === false) {
      let user = {}
      getAuditNomenclature()
      signInToConcizeDB({
        variables: {
          input: user,
        },
      }).then(res => {})
    }
  }, [loading])

  useEffect(() => {
    if (error) {
      setShowLoader(false)
      setShowError(true)
    }
    return () => {
      setShowLoader(false)
    }
  }, [error])

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  const handleAlertClose = () => {
    setShowError(false)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Concize</title>
      </MetaTags>
      <div className="pt-5">
        <Container>
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "99",
              textAlign: "center",
            }}
          >
            <Card hidden={!showLoader}>
              <CardBody>
                <p>Logging In</p>
                <Spinner color="primary" />
              </CardBody>
            </Card>
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                style={{ opacity: showLoader ? "0.5" : "1" }}
                className="overflow-hidden"
              >
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Concize.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt="Concize logo"
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        setShowLoader(true)
                        handleValidSubmit(e, v)
                      }}
                    >
                      <Alert
                        isOpen={showError}
                        color="danger"
                        toggle={handleAlertClose}
                      >
                        {error}
                      </Alert>

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          <li className="list-inline-item">
                            <TwitterLogin
                              loginUrl={
                                "http://localhost:4000/api/v1/auth/twitter"
                              }
                              onSuccess={this.twitterResponse}
                              onFailure={this.onFailure}
                              requestTokenUrl={
                                "http://localhost:4000/api/v1/auth/twitter/revers"
                              }
                              showIcon={false}
                              tag={"div"}
                            >
                              <a
                                href=""
                                className="social-list-item bg-info text-white border-info"
                              >
                                <i className="mdi mdi-twitter" />
                              </a>
                            </TwitterLogin>
                          </li>
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul>
                      </div> */}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 d-flex justify-content-center">
                <p className="me-2">Don't have an account ?</p>
                <Link to="register" className="fw-medium text-primary">
                  Signup now
                </Link>
              </div>
              <p className="text-center">
                © {new Date().getFullYear()} Concize
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
