import React, { useState, useEffect } from "react"
import { Input, Col } from "antd"
import { useDebounce } from "use-debounce/lib"
import Tooltip from "@material-ui/core/Tooltip"

const TextField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [textValue, setTextValue] = useState(undefined)
  const [updatedTextValue] = useDebounce(textValue, 1000)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      setTextValue(taskCustomField.value)
    } else {
      setTextValue(undefined)
    }
  }, [taskCustomField])

  useEffect(() => {
    if (updatedTextValue && updatedTextValue != taskCustomField.value) {
      let customText = `Updated ${taskFieldObj.fieldName}`
      if (taskCustomField.value !== "") {
        customText += ` from "${taskCustomField.value}" to "${updatedTextValue}"`
      } else {
        customText += ` to "${updatedTextValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      let updateObj = {
        id: taskFieldObj.id,
        taskID,
        value: updatedTextValue,
        auditInput,
      }

      handleUpdateCustomField(updateObj)
    }
  }, [updatedTextValue])

  const handleTextFieldChange = event => {
    setTextValue(event.target.value)
  }

  const handleTextFieldBlur = () => {
    if (textValue && textValue !== taskCustomField.value) {
      let customText = `Updated ${taskFieldObj.fieldName}`
      if (taskCustomField.value !== "") {
        customText += ` from "${taskCustomField.value}" to "${textValue}"`
      } else {
        customText += ` to "${textValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      let updateObj = {
        id: taskFieldObj.id,
        taskID,
        value: textValue,
        auditInput,
      }

      // handleUpdateCustomField(updateObj)
    }
  }

  return (
    <React.Fragment>
      <Col span={6}>
        {taskFieldObj.description ? (
          <Tooltip arrow placement="top" title={taskFieldObj.description}>
            <span>{taskFieldObj.fieldName}</span>
          </Tooltip>
        ) : (
          taskFieldObj.fieldName
        )}
      </Col>
      <Col span={12} className="ms-1">
        <Input
          placeholder={taskFieldObj.fieldName}
          className="textareadHover"
          value={textValue ? textValue : undefined}
          onChange={handleTextFieldChange}
          onBlur={handleTextFieldBlur}
        />
      </Col>
    </React.Fragment>
  )
}

export default TextField
