import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const DeletePostModal = ({
  showDeleteModal,
  handleCloseDeleteModal,
  handleDeleteConfirm,
}) => {
  return (
    <Modal isOpen={showDeleteModal} toggle={handleCloseDeleteModal}>
      <ModalHeader toggle={handleCloseDeleteModal} tag="h4">
        Delete Post
      </ModalHeader>
      <ModalBody>
        <h5>Are you sure you want to delete this post?</h5>
      </ModalBody>
      <ModalFooter>
        <div
          onClick={handleCloseDeleteModal}
          className="btn btn-outline-secondary rounded-3"
        >
          Cancel
        </div>
        <div onClick={handleDeleteConfirm} className="btn btn-danger rounded-3">
          Confirm
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DeletePostModal
