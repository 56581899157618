import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"

import Breadcrumb from "../../components/Common/Breadcrumb"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import WorkspaceModal from "./components/workspaceModal"

import { useQuery, useMutation } from "@apollo/client"
import { WORKSPACES } from "../../graphql/queries"
import {
  CREATE_WORKSPACE,
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
} from "../../graphql/mutation"
import ConfirmDeleteModal from "../../components/Dialog/confirmDeleteModal"
import CustomAlert from "../../components/Dialog/customAlert"
import { ROLE } from "components/helpers/enums"
import NotAuthorizedPage from "components/Common/NotAuthorizedPage"
import { GET_AUDIT_NOMENCLATURE } from "../../graphql/queries"

const Workspace = () => {
  const obj = JSON.parse(localStorage.getItem("concizeUser"))
  let role = obj.role

  const [workspaces, setWorkspaces] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentWorkspace, setCurrentWorkspace] = useState(undefined)
  const [editMode, setEditMode] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [showError, setShowError] = useState(false)
  const {} = useQuery(WORKSPACES, {
    // fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.workspaces) {
        setWorkspaces(data.workspaces)
      }
    },
  })

  const {} = useQuery(GET_AUDIT_NOMENCLATURE, {
    // fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getAuditNomenclature) {
        let auditNomenclatureMap = new Map()
        for (const el of data.getAuditNomenclature) {
          auditNomenclatureMap.set(el.type, el)
        }
        localStorage.setItem(
          "auditNomenClatureData",
          JSON.stringify(Object.fromEntries(auditNomenclatureMap))
        )
      }
    },
  })

  const [createWorkspace] = useMutation(CREATE_WORKSPACE, {
    onCompleted: data => {
      if (data && data.createWorkspace) {
        if (workspaces && workspaces.length) {
          setWorkspaces([...workspaces, data.createWorkspace])
        } else {
          setWorkspaces([data.createWorkspace])
        }
      }
      setLoading(false)
      setShowModal(false)
    },
    onError(err) {
      setErrorMessage(err.message)
      setShowError(true)
    },
  })

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE, {
    onCompleted: data => {
      if (data && data.updateWorkspace) {
        let index = workspaces.findIndex(workspace => {
          return workspace.id === data.updateWorkspace.id
        })
        let newWorkspaces = [
          ...workspaces.slice(0, index),
          { ...data.updateWorkspace },
          ...workspaces.slice(index + 1),
        ]
        setWorkspaces(newWorkspaces)
      }
      setShowModal(false)
    },
    onError(err) {
      setErrorMessage(err.message)
      setShowError(true)
    },
  })

  const [deleteWorkspace] = useMutation(DELETE_WORKSPACE, {
    onCompleted: data => {
      if (data && data.deleteWorkspace) {
        let index = workspaces.findIndex(workspace => {
          return workspace.id === currentWorkspace.id
        })
        let newWorkspaces = [...workspaces]
        newWorkspaces.splice(index, 1)
        setWorkspaces(newWorkspaces)
      }
      setLoading(false)
    },
    onError(err) {
      setErrorMessage(err.message)
      setShowError(true)
    },
  })

  const workspaceColumnList = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, workspace) => (
        <div className="d-flex gap-3">
          <div
            className="text-success"
            onClick={() => handleUpdateWorkspace(workspace)}
            style={{ cursor: "pointer" }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </div>
          <div
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteWorkspace(workspace)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </div>
        </div>
      ),
    },
  ]
  const { SearchBar } = Search

  const pageOptions = {
    sizePerPage: 10,
    totalSize: workspaces.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]
  const selectRow = {
    mode: "checkbox",
    style: { backgroundColor: "#fafafa" },
  }

  const handleCreateWorkspace = () => {
    setEditMode(false)
    setCurrentWorkspace(undefined)
    setShowModal(true)
  }
  const handleUpdateWorkspace = workspace => {
    setEditMode(true)
    setCurrentWorkspace(workspace)
    setShowModal(true)
  }
  const handleDeleteWorkspace = workspace => {
    setCurrentWorkspace(workspace)
    setShowConfirmDeleteModal(true)
  }
  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleDeletModalClose = () => {
    setShowConfirmDeleteModal(false)
  }

  const handleAlertClose = () => {
    setShowError(false)
  }

  function onUpdate(id, name) {
    updateWorkspace({
      variables: {
        id,
        name,
      },
    })
  }

  function onSave(input) {
    createWorkspace({
      variables: {
        input: input,
      },
    })
  }

  function onDelete() {
    deleteWorkspace({
      variables: {
        id: currentWorkspace.id,
      },
    })
    setShowConfirmDeleteModal(false)
  }

  return role === ROLE.spaceAdmin || role === ROLE.spaceUser ? (
    <NotAuthorizedPage />
  ) : (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          overflow: "scroll",
        }}
      >
        <MetaTags>
          <title>Concize - Workspace</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            left="Workspace"
            url="/settings"
            urlTitle="Settings"
            right="Workspace"
          />

          {/* Modal */}
          <WorkspaceModal
            showModal={showModal}
            handleClose={handleModalClose}
            editMode={editMode}
            onSave={onSave}
            onUpdate={onUpdate}
            data={currentWorkspace}
          />

          <ConfirmDeleteModal
            showDialog={showConfirmDeleteModal}
            handleClose={handleDeletModalClose}
            onConfirm={onDelete}
            title={`Delete Workspace`}
            subText={`Are you sure you want to delete the workspace "${currentWorkspace?.name}"?`}
          />

          {/* TODO */}
          <CustomAlert
            showError={showError}
            title="HTTP 401"
            errorMessage={errorMessage}
            handleClose={handleAlertClose}
          />

          <Row>
            <Col lg="12">
              <Card style={{ position: "relative" }}>
                <CardBody>
                  <Card
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      left: `calc(50% - 100px)`,
                      top: "30%",
                      width: "150px",
                    }}
                    className="shadow-lg text-center"
                  >
                    <CardBody hidden={!loading}>
                      <p>Please wait</p>
                      <Spinner color="primary" hidden={!loading} />
                    </CardBody>
                  </Card>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={workspaces}
                    columns={workspaceColumnList}
                    keyField="name"
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="name"
                        data={workspaces}
                        columns={workspaceColumnList}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end d-flex justify-content-end">
                                  <button
                                    className="font-16 btn-block btn btn-primary rounded-3 me-2"
                                    onClick={handleCreateWorkspace}
                                  >
                                    <i className="fas fa-plus-circle me-2" />
                                    Create Workspace
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    keyField="name"
                                    // selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Workspace
