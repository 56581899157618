import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Select,
  Switch,
  Checkbox,
  DatePicker,
  Cascader,
  InputNumber,
} from "antd"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import moment from "moment"
import Moment from "moment"
import { extendMoment } from "moment-range"

const { Text } = Typography
const { Option } = Select

const TrackTimeModal = ({
  showModal,
  handleCloseModal,
  tasks,
  weekRange,
  weekData,
}) => {
  const momentRange = extendMoment(Moment)
  const [weekCol, setWeekCol] = useState(undefined)

  const weekFormat = "YYYY-MM-DD"
  const customWeekStartEndFormat = value =>
    `${moment(value).startOf("week").format(weekFormat)} - ${moment(value)
      .endOf("week")
      .format(weekFormat)}`

  return (
    <Modal size="lg" isOpen={showModal} backdrop="static">
      <Form
        initialValues={{
          ["weekdate"]: weekRange ? weekRange : "",
        }}
      >
        <ModalHeader tag="h4" toggle={handleCloseModal}>
          Create new track time
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col span={24}>
              <Text className="font-size-12 text-black-50" strong>
                Select task <span className="text-danger">*</span>
              </Text>
              <Form.Item
                className="mt-1"
                name="taskName"
                rules={[{ required: true, message: "Please enter task name" }]}
              >
                <Select
                  showSearch
                  className="rounded-3"
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  style={{
                    height: "33px",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select a user type"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                >
                  {tasks
                    ? tasks.map(ele => {
                        return (
                          <Option key={ele.id} value={ele.id}>
                            {ele.title}
                          </Option>
                        )
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Type <span className="text-danger">*</span>
              </Text>
              <Form.Item
                className="mt-1"
                name="type"
                rules={[{ required: true, message: "Please choose a type" }]}
              >
                <Select
                  className="rounded-3"
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  style={{
                    height: "33px",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  placeholder="Select a user type"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="font-size-12 text-black-50" strong>
                Weekdate <span className="text-danger">*</span>
              </Text>
              <Form.Item name="weekdate" className="mt-1">
                <Input disabled className="rounded-3" size="middle" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-around" className="mt-2">
            {weekData
              ? weekData.map(ele => {
                  return (
                    <Col className="text-center">
                      <div>{ele.date}</div>
                      <InputNumber
                        placeholder={ele.date}
                        className="rounded-3"
                        size="middle"
                      />
                    </Col>
                  )
                })
              : null}
            <Col></Col>
          </Row>
        </ModalBody>
      </Form>
    </Modal>
  )
}

export default TrackTimeModal
