import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Row, Col, Form } from "antd"
import Select from "react-select"

const DashboardModal = ({
  showModal,
  handleClose,
  editMode,
  onSave,
  onUpdate,
  data,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    if (showModal) {
      setButtonDisabled(false)
    }
  }, [showModal])

  const selectStyles = {
    control: styles => ({
      ...styles,
      borderRadius: "6px",
    }),
  }

  const handleSubmit = value => {
    setButtonDisabled(true)
    if (editMode) {
      onUpdate(value)
    } else {
      onSave(value)
    }
    handleClose()
  }

  return (
    <Modal
      isOpen={showModal}
      centered={true}
      backdrop="static"
      toggle={handleClose}
    >
      <Form
        initialValues={{
          ["name"]: data?.name ? data.name : "",
        }}
        onFinish={handleSubmit}
      >
        <ModalHeader toggle={handleClose} tag="h4">
          {editMode ? "Update" : "Create"} Dashboard
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col span={24}>
              <label className="text-secondary">
                Dashboard Name <span className="text-danger">*</span>
              </label>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter dashboard name",
                    style: { fontSize: "6px" },
                  },
                ]}
              >
                <input
                  placeholder="Enter Dashboard Name"
                  className="form-control rounded-3"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row hidden={true}>
            <Col span={24}>
              <label className="text-secondary">Dashboard Template</label>
              <Form.Item name="template">
                <Select
                  isClearable={true}
                  isSearchable={true}
                  name="template"
                  styles={selectStyles}
                  placeholder="Select a dashboard template"
                  classNamePrefix="select2-selection"
                  options={[
                    {
                      label: "Project Management",
                      value: "Project Management",
                    },
                    { label: "Time Management", value: "Time Management" },
                    { label: "Task Analytics", value: "Task Analytics" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row hidden={true}>
            <Col span={24}>
              <label className="text-secondary">
                Tags <span className="text-danger">*</span>
              </label>
              <Form.Item name="tags">
                <Select
                  isClearable={true}
                  isSearchable={true}
                  name="tag"
                  styles={selectStyles}
                  placeholder="Select or Input Tags"
                  classNamePrefix="select2-selection"
                  options={[
                    {
                      label: "Project Management",
                      value: "Project Management",
                    },
                    { label: "Time Management", value: "Time Management" },
                    { label: "Task Analytics", value: "Task Analytics" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div
            onClick={handleClose}
            className="btn btn-outline-secondary rounded-3"
          >
            Cancel
          </div>
          <button
            disabled={buttonDisabled}
            className="btn btn-success rounded-3"
            type="submit"
          >
            {editMode ? "Update" : "Create"}
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default DashboardModal
