import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#555",
    color: "#fff",
    lineHeight: "18px",
    maxWidth: 200,
    padding: "12px",
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip)

const defaultProps = {
  style: { width: "100%", height: "4px", marginBottom: "8px" },
}

const CustomToolTip = ({ text, heading, subText }) => {
  return (
    <HtmlTooltip
      // TransitionComponent={Zoom}
      enterDelay={300}
      arrow
      placement="top"
      title={
        <React.Fragment>
          <div className="d-flex">
            {text} {heading}
          </div>
          <Box borderBottom={1} {...defaultProps} />
          {subText}
        </React.Fragment>
      }
    >
      <span>{text}</span>
    </HtmlTooltip>
  )
}

export default CustomToolTip
