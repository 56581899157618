import { ROLE, ROLES_ARRAY } from "../../../components/helpers/enums"

const re =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function isString(x) {
  return typeof x === "string"
}

function isEmailValid(email) {
  return re.test(email)
}

function hasAllocationProfile(role, data) {
  if (role === ROLE.spaceUser && data !== "") {
    return true
  }
  return false
}

function hasWorkspace(role, data) {
  if (role === ROLE.spaceAdmin && data !== "") {
    return true
  }
  return false
}

function isAdmin(role) {
  if (role === ROLE.systemAdmin || role === ROLE.globalAdmin) {
    return true
  }
  return false
}

function isEmpty(data) {
  if (data.length < 1) {
    return true
  }
  return false
}

export const errorDataColumns = [
  {
    text: "First Name",
    dataField: "First_Name",
    sort: true,
    formatter: (cellContent, user) => (
      <span className={isString(user.First_Name) ? "" : "text-danger"}>
        {user.First_Name}
      </span>
    ),
  },
  {
    text: "Last Name",
    dataField: "Last_Name",
    sort: true,
    formatter: (cellContent, user) => (
      <span className={isString(user.Last_Name) ? "" : "text-danger"}>
        {user.Last_Name}
      </span>
    ),
  },
  {
    text: "Email",
    dataField: "Email",
    sort: true,
    formatter: (cellContent, user) => (
      <span className={isEmailValid(user.Email) ? "" : "text-danger"}>
        {user.Email}
      </span>
    ),
  },
  {
    text: "Mobile",
    dataField: "Mobile",
    sort: true,
    formatter: (cellContent, user) => (
      <span className={/^\d+$/.test(user.Mobile) ? "" : "text-danger"}>
        {user.Mobile !== "" ? user.Mobile : "*is required*"}
      </span>
    ),
  },
  {
    text: "Role",
    dataField: "role",
    sort: true,
    formatter: (cellContent, user) => (
      <span className={ROLES_ARRAY.includes(user.Role) ? "" : "text-danger"}>
        {user.Role}
      </span>
    ),
  },
  {
    text: "Allocation Profiles",
    dataField: "Allocation_Profiles",
    sort: true,
    formatter: (cellContent, user) => (
      <span
        className={
          user.Role === ROLE.spaceUser
            ? hasAllocationProfile(user.Role, user.Allocation_Profiles)
              ? ""
              : "text-danger"
            : user.Allocation_Profiles !== ""
            ? "text-danger"
            : ""
        }
      >
        {user.Role === ROLE.spaceUser
          ? isEmpty(user.Allocation_Profiles) &&
            hasAllocationProfile(user.Role, user.Allocation_Profiles)
            ? user.Allocation_Profiles
            : "Either data is missing or incorrect data"
          : user.Allocation_Profiles !== ""
          ? "* not required"
          : user.Allocation_Profiles}
      </span>
    ),
  },
  {
    text: "Workspaces",
    dataField: "Workspaces",
    sort: true,
    formatter: (cellContent, user) => (
      <span
        className={
          user.Role === ROLE.spaceAdmin
            ? hasWorkspace(user.Role, user.Workspaces)
              ? ""
              : "text-danger"
            : user.Workspaces !== ""
            ? "text-danger"
            : ""
        }
      >
        {user.Role === ROLE.spaceAdmin
          ? hasWorkspace(user.Role, user.Workspaces)
            ? user.Workspaces
            : "Either data is missing or incorrect data"
          : user.Workspaces !== ""
          ? "* not required"
          : user.Workspaces}
      </span>
    ),
  },
  {
    text: "Designation",
    dataField: "Designation",
    sort: true,
    formatter: (cellContent, user) => (
      <span className={isString(user.Designation) ? "" : "text-danger"}>
        {user.Designation}
      </span>
    ),
  },
  {
    text: "Department",
    dataField: "Department",
    sort: true,
    formatter: (cellContent, user) => (
      <span className={isString(user.Department) ? "" : "text-danger"}>
        {user.Department}
      </span>
    ),
  },
]
