import React, { useState, useEffect } from "react"

const TableCheckboxField = ({
  customField,
  record,
  value,
  updateCustomField,
}) => {
  const [checkboxValue, setCheckboxValue] = useState(undefined)

  useEffect(() => {
    if (value && value !== "") {
      setCheckboxValue(value)
    } else {
      setCheckboxValue(undefined)
    }
  }, [value])

  const handleInlineCheckboxChange = updatedValue => {
    setCheckboxValue(updatedValue)

    let customText = `Updated ${customField.fieldName}`
    if (value !== "") {
      customText += ` from "${value}" to "${updatedValue}"`
    } else {
      customText += ` to "${updatedValue}"`
    }

    let auditInput = {
      auditType: -1,
      customText,
    }
    updateCustomField({
      variables: {
        id: customField.id,
        taskID: record.id,
        value: updatedValue,
        auditInput,
      },
    })
  }

  return (
    <div className="text-center">
      <input
        checked={checkboxValue && checkboxValue === "on" ? true : false}
        onChange={() => {
          let value = checkboxValue === "on" ? "off" : "on"
          handleInlineCheckboxChange(value)
        }}
        type="checkbox"
        style={{ cursor: "pointer" }}
        className="form-check-input"
      />
    </div>
  )
}

export default TableCheckboxField
