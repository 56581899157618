import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Row, Col } from "antd"
import UserInfo from "./components/user-info"

const Profile = () => {
  const [activeTab, setactiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          overflow: "scroll",
        }}
      >
        <MetaTags>
          <title>Profile | Concize </title>
        </MetaTags>
        <Container fluid>
          <Row gutter={16}>
            {/* User Info */}
            <Col span={12}>
              <UserInfo className="position-relative" />
            </Col>

            <Col span={16}>
              <Card className="d-none">
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-user me-2"></i>About
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-tasks me-2"></i>Task
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggle("3")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-envelope me-2"></i>Messages
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <h1>About</h1>
                    </TabPane>
                    <TabPane tabId="2">
                      <h1>Task</h1>
                    </TabPane>
                    <TabPane tabId="3">
                      <h1>Messages</h1>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile
