import React, { useState, useEffect } from "react"
import { Col, Input } from "antd"
import Tooltip from "@material-ui/core/Tooltip"
const { TextArea } = Input

const TextAreaField = ({
  taskFieldObj,
  taskCustomField,
  handleUpdateCustomField,
  taskID,
}) => {
  const [textAreaValue, setTextAreaValue] = useState(undefined)

  useEffect(() => {
    if (taskCustomField && taskCustomField.value !== "") {
      setTextAreaValue(taskCustomField.value)
    } else {
      setTextAreaValue(undefined)
    }
  }, [taskCustomField])

  const handleTextAreaFieldChange = event => {
    setTextAreaValue(event.target.value)
  }

  const handleUpdateField = () => {
    if (textAreaValue && textAreaValue !== taskCustomField.value) {
      let customText = `Updated ${taskFieldObj.fieldName}`
      if (taskCustomField.value !== "") {
        customText += ` from "${taskCustomField.value}" to "${textAreaValue}"`
      } else {
        customText += ` to "${textAreaValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      let updateObj = {
        id: taskFieldObj.id,
        taskID,
        value: textAreaValue,
        auditInput,
      }
      handleUpdateCustomField(updateObj)
    }
  }

  return (
    <React.Fragment>
      <Col span={6}>
        {taskFieldObj.description ? (
          <Tooltip arrow placement="top" title={taskFieldObj.description}>
            <span>{taskFieldObj.fieldName}</span>
          </Tooltip>
        ) : (
          taskFieldObj.fieldName
        )}
      </Col>
      <Col span={16} className="ms-1">
        <div>
          <TextArea
            placeholder={taskFieldObj.fieldName}
            value={textAreaValue ? textAreaValue : undefined}
            onChange={handleTextAreaFieldChange}
            onBlur={handleUpdateField}
            onMouseLeave={handleUpdateField}
            autoSize={{ minRows: 3, maxRows: 5 }}
            className="textareadHover"
          />
        </div>
      </Col>
    </React.Fragment>
  )
}

export default TextAreaField
