import React, { useState, useEffect } from "react"
import { DatePicker } from "antd"
import moment from "moment"

const DefaultDatePicker = ({ name, data, handleChange }) => {
  const [date, setDate] = useState(undefined)

  useEffect(() => {
    if (data) {
      if (name === "Start Date" && data.startDate !== "") {
        setDate(data.startDate)
      } else if (name === "End Date" && data.endDate !== "") {
        setDate(data.endDate)
      } else {
        setDate(undefined)
      }
    }
  }, [data])

  const handleStartDateChange = dateStr => {
    setDate(dateStr)
    if (dateStr !== data.startDate) {
      handleChange(data, dateStr)
    }
  }

  return (
    <div className="dueDateHover" style={{ width: "140px" }}>
      <DatePicker
        style={{ cursor: "pointer" }}
        placeholder={name}
        bordered={false}
        value={date && date !== "" ? moment(date, "DD-MM-YYYY") : ""}
        size="middle"
        onChange={(_, dateString) => {
          handleStartDateChange(dateString)
        }}
        allowClear={true}
        format="DD-MM-YYYY"
      />
    </div>
  )
}

export default DefaultDatePicker
