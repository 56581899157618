import React, { useState, useEffect } from "react"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, convertFromRaw } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const TextWidget = ({ handleTextChange, text, textData, editMode }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if (text) {
      let parsedData = JSON.parse(text)
      console.log(parsedData)
      let fromRawData = convertFromRaw(parsedData)
      setEditorState(EditorState.createWithContent(fromRawData))
    } else if (textData) {
      let parsedData = JSON.parse(textData)
      let fromRawData = convertFromRaw(parsedData)
      setEditorState(EditorState.createWithContent(fromRawData))
    }
  }, [text, textData, editMode])

  const onEditorStateChange = es => {
    setEditorState(es)
  }

  useEffect(() => {
    if (editorState && handleTextChange) {
      const contentState = editorState.getCurrentContent()
      const currentStateData = convertToRaw(contentState)
      const richStringifyValue = JSON.stringify(currentStateData)
      handleTextChange(richStringifyValue)
    }
  }, [editorState])

  return (
    <Editor
      readOnly={text ? true : false}
      toolbarClassName={text ? "d-none" : ""}
      editorClassName={
        text ? "text-widget-editor-overflow-hidden" : "text-widget-editor"
      }
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      placeholder="Enter text here.."
    />
  )
}

export default TextWidget
