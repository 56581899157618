import React, { useState, useEffect } from "react"
import { Input } from "antd"

const TableNumberField = ({
  customField,
  record,
  value,
  updateCustomField,
}) => {
  const [numberValue, setNumberValue] = useState(undefined)

  useEffect(() => {
    if (value && value !== "") {
      setNumberValue(value)
    } else {
      setNumberValue(undefined)
    }
  }, [value])

  const handleInlineNumberFieldChange = event => {
    setNumberValue(event.target.value)
  }

  const handleInlineNumberFieldBlur = () => {
    if (numberValue && numberValue !== value) {
      let customText = `Updated ${customField.fieldName}`
      if (value !== "") {
        customText += ` from "${value}" to "${numberValue}"`
      } else {
        customText += ` to "${numberValue}"`
      }

      let auditInput = {
        auditType: -1,
        customText,
      }

      updateCustomField({
        variables: {
          id: customField.id,
          taskID: record.id,
          value: numberValue,
          auditInput,
        },
      })
    }
  }

  return (
    <Input
      type="number"
      style={{
        width: "100px",
      }}
      value={numberValue ? numberValue : undefined}
      onMouseLeave={() => {
        handleInlineNumberFieldBlur
      }}
      placeholder={customField.fieldName}
      className="textareadHover mb-2"
      onChange={handleInlineNumberFieldChange}
      onBlur={handleInlineNumberFieldBlur}
    />
  )
}

export default TableNumberField
