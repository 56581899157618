import React, { useState, useEffect } from "react"
import { Badge } from "reactstrap"
import { Select } from "antd"

const { Option } = Select

const TableDropdownField = ({
  customField,
  record,
  value,
  updateCustomField,
}) => {
  const [dropdownValue, setDropdownValue] = useState(undefined)

  useEffect(() => {
    if (value && value !== "") {
      setDropdownValue(value)
    } else {
      setDropdownValue(undefined)
    }
  }, [value])

  const handleInlineCustomDropdownChange = option => {
    setDropdownValue(option.value)
    let optionIndex = parseInt(option.key)
    let valueIndex = parseInt(option.value)
    if (isNaN(valueIndex) || isNaN(optionIndex)) {
      console.log("valueIndex or optionIndex Is NaN")
      return
    }
    console.log("valueIndex", valueIndex, customField, option)

    let customText = `Updated ${customField.fieldName}`
    if (value !== "") {
      customText += ` from "${customField.options[valueIndex].name}" to "${customField.options[optionIndex].name}"`
    } else {
      customText += ` to "${customField.options[optionIndex].name}"`
    }

    let auditInput = {
      auditType: -1,
      customText,
    }

    updateCustomField({
      variables: {
        id: customField.id,
        taskID: record.id,
        value: option.value,
        auditInput,
      },
    })
  }

  return (
    <Select
      className="statusHover"
      onChange={(_, option) => {
        handleInlineCustomDropdownChange(option)
      }}
      style={{
        minWidth: "150px",
      }}
      size="middle"
      bordered={false}
      value={dropdownValue && dropdownValue !== "" ? dropdownValue : undefined}
      placeholder={customField.fieldName}
      dropdownRender={menu => <div className="d-flex flex-column">{menu}</div>}
    >
      {customField?.options?.map((item, index) => {
        return (
          <Option value={String(index)} key={index}>
            <Badge
              style={{
                background: item.color,
                padding: "6px 4px",
                minWidth: "100px",
              }}
              className="font-size-12"
            >
              {item.name}
            </Badge>
          </Option>
        )
      })}
    </Select>
  )
}

export default TableDropdownField
