import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"

const CustomAlert = ({ title, errorMessage, showError, handleClose }) => {
  return (
    <SweetAlert
      show={showError}
      customButtons={
        <button onClick={handleClose} className="btn btn-primary">
          Ok
        </button>
      }
      onConfirm={handleClose}
      title={title}
      warning
    >
      {errorMessage ? errorMessage : ""}
    </SweetAlert>
  )
}

export default CustomAlert
