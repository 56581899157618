import React, { useState, useEffect } from "react"

import { Select } from "antd"
import { Badge } from "reactstrap"

const { Option } = Select

const TableDropdownMultiField = ({
  customField,
  record,
  value,
  updateCustomField,
}) => {
  const [dropdownMultiValue, setDropdownMultiValue] = useState(undefined)

  useEffect(() => {
    if (value && value !== "") {
      let values = value.split(", ")
      setDropdownMultiValue(values)
    } else {
      setDropdownMultiValue(undefined)
    }
  }, [value])

  const handleInlineDropdownMultiFieldChange = options => {
    console.log("options", options)
    let newValuesStr = ""
    let newValuesArr = []

    if (options && options.length) {
      options.forEach((ele, index) => {
        newValuesArr.push(ele.value)
        if (index === options.length - 1) {
          newValuesStr = newValuesStr.concat(ele.value)
        } else {
          newValuesStr = newValuesStr.concat(ele.value, ", ")
        }
      })
    }

    setDropdownMultiValue(newValuesArr)

    let customText = `Updated "${customField.fieldName}" custom field`

    let auditInput = {
      auditType: -1,
      customText,
    }
    updateCustomField({
      variables: {
        id: customField.id,
        taskID: record.id,
        value: newValuesStr,
        auditInput,
      },
    })
  }

  return (
    <Select
      mode="multiple"
      className="statusHover"
      size="middle"
      bordered={false}
      placeholder={customField.fieldName}
      style={{ minWidth: "150px" }}
      value={dropdownMultiValue ? dropdownMultiValue : undefined}
      onChange={(_, options) => {
        handleInlineDropdownMultiFieldChange(options)
      }}
      dropdownRender={menu => <div className="d-flex flex-column ">{menu}</div>}
    >
      {customField?.options?.map((item, index) => {
        return (
          <Option value={String(index)} key={index}>
            <Badge
              style={{
                background: item.color,
                padding: "6px 4px",
                minWidth: "100px",
              }}
              className="font-size-12"
            >
              {item.name}
            </Badge>
          </Option>
        )
      })}
    </Select>
  )
}

export default TableDropdownMultiField
