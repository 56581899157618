import React from "react"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../../assets/images/error-img.png"

const NotAuthorizedPage = () => {
  let history = useHistory()
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>Concize - Not Authorized</title>
        </MetaTags>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />1
                </h1>
                <h4 className="text-uppercase">Forbidden</h4>
                <p>Sorry, you are not authorized to view this page.</p>
                <div className="mt-2 text-center">
                  <div
                    onClick={() => history.goBack()}
                    className="btn btn-primary rounded-3"
                  >
                    Go back
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NotAuthorizedPage
