import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Row, Col } from "antd"
import { SketchPicker } from "react-color"

const SettingModal = ({
  showModal,
  handleCloseModal,
  datas,
  selectedData,
  handleChangeSelectedData,
  handleChangeDatas,
}) => {
  const [color, setColor] = useState("#fff")

  const handleColorChange = e => {
    setColor(e.hex)
    handleChangeDatas(selectedData, e.hex)
  }

  const handleDataClick = data => {
    let newSelectedData = {
      name: data.name,
      fieldID: data.fieldID,
      y: data.count,
      color: data.color,
    }
    setColor(data.color)
    handleChangeSelectedData(newSelectedData)
  }

  return (
    <Modal isOpen={showModal} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal} tag="h4">
        Change Color
      </ModalHeader>
      <ModalBody>
        <Row gutter={24}>
          <Col
            className="ms-1"
            span={10}
            style={{
              background: "#f7f6f9",
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            {datas
              ? datas.map((data, index) => {
                  let isSelected =
                    selectedData?.name === data.name &&
                    selectedData?.fieldID === data.fieldID &&
                    (selectedData?.y === data.count ||
                      selectedData?.data.includes(data.count))
                  return (
                    <div
                      key={index}
                      onClick={() => handleDataClick(data)}
                      className="py-3 ps-2 mb-1 cursor-pointer rounded-1"
                      style={{
                        borderLeft: "4px solid",
                        borderColor: data.color,
                        backgroundColor: isSelected ? "white" : "#f7f6f9",
                      }}
                    >
                      <h6>{data.name}</h6>
                    </div>
                  )
                })
              : null}
          </Col>
          <Col span={13}>
            <SketchPicker
              styles={{
                position: "relative",
              }}
              color={color}
              onChange={handleColorChange}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  )
}

export default SettingModal
