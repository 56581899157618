import React, { useEffect, useState } from "react"
import { Table } from "antd"

const TableChart = ({ tableData }) => {
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  useEffect(() => {
    if (tableData) {
      console.log("tableData", tableData)
      let cols = []
      let data = []
      tableData.forEach(ele => {
        cols.push({
          title: ele.fieldName + `(${ele.spaceName})`,
          dataIndex: ele.fieldName + `(${ele.spaceName})`,
        })
      })
      setColumns(cols)

      tableData.forEach(ele => {
        ele.values?.forEach((value, index) => {
          data[index] = {
            ...data[index],
            [ele.fieldName + `(${ele.spaceName})`]: value.data,
          }
        })
      })

      setData(data)
    }
  }, [tableData])

  return (
    <div
      style={{
        margin: "1rem",
      }}
    >
      <Table
        pagination={{
          pageSize: 5,
        }}
        className="table-chart-table"
        columns={columns}
        dataSource={data}
      />
    </div>
  )
}

export default TableChart
