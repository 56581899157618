import React, { useState, useEffect } from "react"
import { Select } from "antd"
import { Badge } from "reactstrap"
const { Option } = Select

const DefaultDropdown = ({ data, handleInlineStatusChange }) => {
  const [status, setStatus] = useState(undefined)

  useEffect(() => {
    if (data && data.status && data.status !== "") {
      setStatus(data.status)
    } else {
      setStatus(undefined)
    }
  }, [data])

  const statusFormatter = status => {
    let element = ""
    let className = "bg-secondary"
    switch (status) {
      case "Completed":
        className = "bg-success"
        break
      case "Pending":
        className = "bg-warning"
        break
      case "Delay":
        className = "bg-danger"
        break
      case "In Progress":
        className = "bg-orange"
        break
      default:
        className = "bg-secondary"
        break
    }
    element = (
      <Badge
        style={{
          padding: "6px 4px",
          width: "100%",
        }}
        key={status}
        className={className + " font-size-12"}
      >
        {status}
      </Badge>
    )
    return element
  }

  return (
    <Select
      className="statusHover"
      placeholder="Status"
      size="middle"
      bordered={false}
      value={status}
      style={{ width: "120px" }}
      onChange={value => {
        setStatus(value)
        handleInlineStatusChange(data, value)
      }}
      dropdownRender={menu => <div className="d-flex flex-column ">{menu}</div>}
    >
      {["Completed", "Pending", "Delay", "In Progress"].map((item, index) => {
        return (
          <Option value={item} key={index}>
            {statusFormatter(item)}
          </Option>
        )
      })}
    </Select>
  )
}

export default DefaultDropdown
