import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

// Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"

import { withRouter, useParams, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import LeftFolderStructure from "./leftFolderStructure"
import SpaceModal from "./spaceModal"
import DeleteModal from "./DeleteModal"
import { ROLE } from "components/helpers/enums"

const LeftSidebarContent = ({
  props,
  lastWorkspace,
  spaces,
  handleUpdateSpaces,
}) => {
  const params = useParams()
  const spaceID = params.id
  const ref = useRef()

  const userObj = JSON.parse(localStorage.getItem("concizeUser"))

  const [space, setSpace] = useState(undefined)
  const [deleteSpace, setDeleteSpace] = useState(undefined)
  const [showSpaceModal, setShowSpaceModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [count, setCount] = useState(0)

  const initMenu = pathName => {
    if (count === 0) {
      new MetisMenu("#side-menu")
    }
    setCount(count + 1)

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }

  useEffect(() => {
    if (spaces) {
      const pathName = props.location.pathname
      initMenu(pathName)
    }
  }, [props.location.pathname, lastWorkspace, spaces])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  /*   const [getSpaces] = useLazyQuery(SPACES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.spaces) {
        setSpaces(data.spaces)
      } else {
        setSpaces(null)
      }
    },
    onError: error => {
      console.log("error", error)
    },
  })

  useEffect(() => {
    if (lastWorkspace) {
      getSpaces({
        variables: {
          workspaceID: lastWorkspace.id,
        },
      })
    }
  }, [lastWorkspace]) */

  const handleNewSpace = () => {
    setSpace(undefined)
    setShowSpaceModal(true)
  }

  const handleSetSpace = space => {
    setSpace(space)
  }

  const handleShowSpaceModal = () => {
    setShowSpaceModal(true)
  }

  const handleCloseSpaceModal = () => {
    setShowSpaceModal(false)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleSetDeleteSpace = space => {
    setDeleteSpace(space)
  }

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true)
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{
          maxHeight: "100%",
          paddingBottom: "6rem",
          marginTop: "5rem",
        }}
        ref={ref}
      >
        {lastWorkspace && lastWorkspace.id ? (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")} </li>

              {/* NOTE Dashboard */}
              <li>
                <Link to={`/${lastWorkspace?.id}/dashboards`}>
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Dashboards")}</span>
                </Link>
              </li>

              <li>
                <Link to="/profile">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Profile")}</span>
                </Link>
              </li>

              {/* NOTE My Tasks */}
              <li>
                <Link to={`/${lastWorkspace?.id}/my-tasks`}>
                  <i className="bx bx-task"></i>
                  <span>{props.t("My Tasks")}</span>
                </Link>
              </li>

              {/* NOTE Track time */}
              <li>
                <Link to={`/${lastWorkspace?.id}/track-time`}>
                  <i className="bx bx-time"></i>
                  <span>{props.t("Tracktime")}</span>
                </Link>
              </li>

              {/* NOTE Calendar */}
              <li>
                <Link to={`/${lastWorkspace?.id}/calendar`}>
                  <i className="bx bx-calendar"></i>
                  <span>{props.t("Publishing Calendar")}</span>
                </Link>
              </li>

              {/* NOTE Spaces */}
              <li className="menu-title">{props.t("Spaces")} </li>

              <li>
                <Link to="#" className="has-arrow">
                  <i className="bx bx-donate-blood"></i>
                  <span>{props.t("Spaces")}</span>
                </Link>

                <ul className="sub-menu">
                  <li
                    className={userObj?.role === ROLE.spaceUser ? "d-none" : ""}
                  >
                    <Link to="#">
                      <div onClick={handleNewSpace}>
                        <i className="font-size-22 font-weight-semibold bx bx-plus"></i>
                        <span>{props.t("New Space")}</span>
                      </div>
                    </Link>
                  </li>

                  {spaces && spaces !== null && spaces.length
                    ? spaces.map(space => {
                        return (
                          <LeftFolderStructure
                            key={space.id}
                            props={props}
                            mainSpace={space}
                            workspaceID={lastWorkspace?.id}
                            spaceID={spaceID}
                            handleSetSpace={handleSetSpace}
                            handleShowSpaceModal={handleShowSpaceModal}
                            handleSetDeleteSpace={handleSetDeleteSpace}
                            handleShowDeleteModal={handleShowDeleteModal}
                          />
                        )
                      })
                    : null}
                </ul>
              </li>

              <li className="menu-title">{props.t("Settings")} </li>
              <li>
                <Link to="/settings">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
              </li>
              <li>
                <Link to="/logout">
                  <i className="bx bx-log-out"></i>
                  <span>{props.t("Logout")}</span>
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/profile">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Profile")}</span>
                </Link>
              </li>
              <li>
                <Link to="/settings">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
              </li>
              <li>
                <Link to="/logout">
                  <i className="bx bx-log-out"></i>
                  <span>{props.t("Logout")}</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </SimpleBar>

      {/* NOTE Space Modal */}
      <SpaceModal
        showSpaceModal={showSpaceModal}
        handleCloseSpaceModal={handleCloseSpaceModal}
        workspaceID={lastWorkspace?.id}
        handleUpdateSpaces={handleUpdateSpaces}
        space={space}
      />

      {/* NOTE Delete Modal */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        space={deleteSpace}
        workspaceID={lastWorkspace?.id}
        handleUpdateSpaces={handleUpdateSpaces}
      />
    </React.Fragment>
  )
}

LeftSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(LeftSidebarContent))
