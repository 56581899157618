import React, { useState, useEffect } from "react"

const CounterChart = ({ datas, height, width }) => {
  const [counterDatas, setCounterDatas] = useState(undefined)
  console.log("height", height, width)
  useEffect(() => {
    if (datas) {
      setCounterDatas(datas)
    } else {
      setCounterDatas(undefined)
    }
  }, [datas])
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
        // alignContent: "center",
      }}
    >
      {counterDatas &&
        counterDatas.map((data, index) => {
          return (
            <div
              key={index}
              style={{
                // width: "calc(50% - 2rem)",
                // position: "relative",
                width: `${width + height}vw`,

                padding: `${height * 1.5}vh`,
                flex: "1 1 auto",
                border: "2px solid #f0f1f3",
              }}
              className="m-2 text-center rounded-3 d-flex justify-content-center align-items-center"
            >
              {height <= 2 ? (
                <div>
                  <h5>{data.name}</h5>
                  <div style={{ fontSize: "1rem", color: "#db226e" }}>
                    {data.count}
                  </div>
                </div>
              ) : (
                <div>
                  <h4>{data.name}</h4>
                  <h1 style={{ fontSize: "3rem", color: "#db226e" }}>
                    {data.count}
                  </h1>
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default CounterChart
