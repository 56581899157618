import React, { useState, useEffect } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/annotations")(Highcharts)

const LineChart = ({ datas, width, xAxis, yAxis }) => {
  const defaultOptions = {
    chart: {
      type: "line",
    }, // type of the chart
    title: {
      text: null,
    }, // title of the chart
    subtitle: {
      text: null,
    }, // subtitle of the chart
    yAxis: {
      title: {
        text: "Date",
      }, // the title of the Y Axis
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      title: {
        text: "Users",
      }, // the title of the X Axis
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:13px;font-weight:bold;">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    }, // tooltip appears when hovering over a point
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Japan",
        data: [10, 14, 18, 61, 65, 72, 74, 79, 87, 89, 92, 93],
      },
      {
        name: "Germany",
        data: [3, 9, 13, 20, 25, 38, 40, 53, 55, 69, 70, 78],
      },
      {
        name: "London",
        data: [9, 15, 31, 50, 56, 60, 64, 67, 79, 82, 95, 97],
      },
      {
        name: "Canada",
        data: [4, 12, 22, 36, 42, 46, 58, 63, 71, 82, 84, 86],
      },
    ], // set of the data
  }

  const [options, setOptions] = useState(defaultOptions)

  /* useEffect(() => {
    if (datas) {
      let seriesData = []
      datas.forEach(ele => {
        seriesData.push({
          name: ele.name,
          data: [ele.count],
        })
      })

      let newOptions = {
        ...options,
        chart: {
          ...options.chart,
          width: width * 90,
        },
        series: seriesData,
      }
      setOptions(newOptions)
    }
  }, [datas, width]) */

  return (
    <div
      style={{
        margin: "0 auto",
        overflow: "hidden",
      }}
      className="h-100 d-flex align-items-center"
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default LineChart
