import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

const ConfirmDeleteModal = ({
  showDialog,
  handleClose,
  onConfirm,
  title,
  subText,
}) => {
  return (
    <Modal isOpen={showDialog} className="px-4">
      <ModalHeader toggle={handleClose} tag="h4">
        {title}
      </ModalHeader>
      <ModalBody>
        <h5 style={{ lineHeight: "1.5rem" }}>{subText}</h5>
      </ModalBody>
      <ModalFooter>
        <Button
          className="ms-2 rounded-3"
          color="secondary"
          outline
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button className="mx-2 rounded-3" color="danger" onClick={onConfirm}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmDeleteModal
